import React, { Component } from "react"
import axios from "axios";
import Container from '@mui/material/Container';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import celebration from '../icons/celebration.png';
import ClearIcon from '@mui/icons-material/Clear';
import CustomLoader from "./CustomLoader";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
export default class ValueCheckResponse extends Component {
  state = { 
    responseReceived: false,
    requestSent: false,
    valueCheckDetail: [],
    appData: [],
    estValue: "",
    estMinRange: "",
    estMaxRange: "",
  };

  componentDidMount(){
    // todo: check if already filled out and if so, then mark request sent = true
    this.getValueCheckResults();
    this.getValueCheckDetails();
    this.getAppraiserData();
  }

  async getValueCheckResults() {
    var aprID = this.props.match.params.id.split("-")[0];
    var vcReq = this.props.match.params.id.split("-")[1];

    const response = axios.get("../getvaluecheckresults", {
      params: { pValueCheckID: vcReq } 
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      for(var i=0; i<resultData.length; i++) {
        if(resultData[i].appraiserUserName  === aprID) {
          this.setState({ 
            responseReceived: true,
            requestSent: resultData[i].responded === "True",
            estValue: resultData[i].value,
            estMinRange: resultData[i].minRange,
            estMaxRange: resultData[i].maxRange,
          });
          break;
        }
      }
      this.setState({responseReceived: true});
    }
  }

  async getValueCheckDetails() {
    var vcReq = this.props.match.params.id.split("-")[1];
    const response = axios.get("../getvaluecheckdata");

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      for(var i=0; i<resultData.length; i++) {
        if(resultData[i].requestID  === vcReq) {
          this.setState({ 
            valueCheckDetail: resultData[i]
          });
          break;
        }
      }
    }
  }

  async getAppraiserData() {
    var userName = this.props.match.params.id.split("-")[0];
    const response = axios.get("../getappraiserdata", { 
      params: { 
        pAppraiser: userName, 
      } 
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      this.setState({ appData: resultData })
    }
  }

  captureInput = e => {
    this.setState(prevState => ({[e.target.name]: e.target.value }));
  };

  submitRequest = async () => {
    const { estValue, estMinRange, estMaxRange } = this.state;
    const valOrRng = estValue ? 'val' : 'rng';
    if(estValue){
      if(isNaN(estValue)) {
        this.setState({errorMsg: "Amount is not a number"});
        return;
      }
    } else {
      if(isNaN(estMinRange) || isNaN(estMaxRange)) {
        this.setState({errorMsg: "Range must include a Min and Max amount"});
        return;
      }
      if(estMaxRange < estMinRange) {
        this.setState({errorMsg: "Max value must be greater than Min value"});
        return;
      }
    }
    const response = axios.get("../valuechecksubmit", {
      params: {
        pValueCheckID: this.props.match.params.id.split("-")[1],
        pAppraiser: this.props.match.params.id.split("-")[0],
        pValOrRng: valOrRng,
        pValue: estValue,
        pMinRange: estMinRange,
        pMaxRange: estMaxRange,
      }
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData !== "Success") {
      this.setState({ successMsg: "", errorMsg: resultData });
    }
    else {
      this.setState({ successMsg: "Success", errorMsg: "", requestSent: true });
    }
  }

  _clearInput = field => {
    if(field === 'range'){
      this.setState({ estMinRange: '', estMaxRange: '' });
    } else {
      this.setState({ [field]: '' });
    }
  }

  _handleCopyToClipboard = () => {
    const { valueCheckDetail } = this.state;
    const address = valueCheckDetail.addressLine1 
    + " " 
    + valueCheckDetail.addressLine2 
    + " " 
    + valueCheckDetail.addressLine3;
    navigator.clipboard.writeText(address);
  }

  render() {
    const { 
      valueCheckDetail, 
      responseReceived,
      requestSent, 
      errorMsg,
      estValue,
      estMinRange,
      estMaxRange,
    } = this.state;
    if(!valueCheckDetail || !responseReceived) {
      return <CustomLoader >Value Check Response</CustomLoader>
    }
    return (
      <Container>
        <Paper sx={{ mt: 4, p: 2, width: '60ch', mx: 'auto', textAlign: "center" }}>
          <Typography variant="h5" mb={2}>Value Check Reponse</Typography>
          <Typography variant="caption">Property Address:</Typography>
          <Stack  mb={2} direction="row" justifyContent="center" alignItems="center">
            <Typography variant="body1" sx={{ flex: 'grow', mb: 0, mr: 1 }}>
              {valueCheckDetail.addressLine1}&nbsp;
              {valueCheckDetail.addressLine2}&nbsp;
              {valueCheckDetail.addressLine3}
            </Typography>
            <IconButton variant="primary" onClick={this._handleCopyToClipboard}>
              <ContentCopyIcon />
            </IconButton>
          </Stack>
          {requestSent ? (
            <Box sx={{ mx: 'auto', textAlign: 'center' }}>
              <Box my={8} >
                <img src={celebration} alt="" width="100"/>
              </Box>
              <Typography variant="h6">Value Check Submitted!</Typography>
              <Typography variant="body1" color="grey.600">Your price estimate has been sent.</Typography>
              <Typography variant="body1" color="grey.600" mb={2}>
                {estValue ? estValue : (estMinRange + " - " + estMaxRange)}
              </Typography>
              <Button onClick={() => this.props.history.push("/")}>Go Home</Button>
            </Box>
          ) : (
            <>
            <Stack spacing={2} sx={{ my: 4 }}>
              {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
              <TextField
                label="Amount"
                fullWidth
                // size="small"
                name="estValue"
                value={estValue}
                disabled={(estMinRange || estMaxRange) ? true : false}
                onChange={this.captureInput}
                InputProps={{ 
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="clear field"
                    onClick={() => this._clearInput('estValue')}
                    edge="end"
                    disabled={!estValue}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
                }}
              />
              <Divider>OR</Divider>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  label="Range Start"
                  type="number"
                  fullWidth
                  // size="small"
                  name="estMinRange"
                  value={estMinRange}
                  disabled={estValue ? true : false}
                  onChange={this.captureInput}
                  InputProps={{ 
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
                <p>to</p>
                <TextField
                  label="Range End"
                  type="number"
                  fullWidth
                  // size="small"
                  name="estMaxRange"
                  value={estMaxRange}
                  disabled={estValue ? true : false}
                  onChange={this.captureInput}
                  InputProps={{ 
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
                <IconButton
                  aria-label="clear field"
                  onClick={() => this._clearInput('range')}
                  edge="end"
                  disabled={!estMaxRange && !estMinRange}
                >
                  <ClearIcon />
                </IconButton>
              </Stack>
            </Stack>
            <Button
              fullWidth
              variant="contained"
              disabled={estValue ? false : (estMinRange && estMaxRange) ? false : true}
              onClick={this.submitRequest}
            >Submit</Button>
          </>
          )}
        </Paper>
      </Container>
    )}
}
