import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

const statusCodes = {
  Ordered: 'error',
  Submitted: 'error',
  Canceled: 'error',
  Completed: 'success',
  Delivered: 'success',
}

const columns = [
  {
    field: 'orderID',
    headerName: 'Order ID',
    width: 100,
    valueGetter: ({ row }) => row.orderID || '-',

  },
  {
    field: 'lenderFileID',
    headerName: 'Lender ID',
    width: 100,
    valueGetter: ({ row }) => row.lenderFileID || '-',

  },
  {
    field: 'orderType',
    headerName: 'Order Type',
    width: 150,
    valueGetter: ({ row }) => row.orderType || '-',

  },
  {
    field: 'orderedByDisplay',
    headerName: 'Ordered By',
    width: 200,
    valueGetter: ({ row }) => row.orderedByDisplay || '-',

  },
  {
    field: 'borrower',
    headerName: 'Borrower',
    width: 200,
    valueGetter: ({ row }) => row.borrower || '-',

  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: ({ row }) => <Chip label={row.status} variant="outlined" color={statusCodes[row.status] || 'info'} />,

  },
  {
    field: 'assignee',
    headerName: 'Assginee',
    width: 150,
    valueGetter: ({ row }) => row.assignee || '-',

  },
  {
    field: 'address',
    headerName: 'Address',
    width: 250,
    valueGetter: ({ row }) => row.address ? row.address.split('\n')[0] : '-',

  },
  {
    field: 'files',
    headerName: 'File Count',
    width: 50,
    valueGetter: ({ row }) => row.files || '-',

  },
  {
    field: 'paid',
    headerName: 'Paid',
    width: 100,
    valueGetter: ({ row }) => row.paid,

  }
];

function CustomNoRowsOverlay() {
  return (
    <Box sx={{ mt: 4, textAlign: 'center' }}>
      <Typography variant="body1">No appraisals</Typography>
    </Box>
  );
}

export default function DataTable({ files }) {

  const history = useHistory();

  if (!files) return (
    <Box sx={{ display: 'flex', textAlign: 'center' }}>
      <CircularProgress />
    </Box>
  )

  const navigateToOrder = (id) => {
    history.push(`/order/${id}`);
  }

  return (
    <div style={{ width: '100%', backgroundColor: 'white' }}>
      <DataGrid
        autoHeight
        rows={files}
        columns={columns}
        getRowId={(row) => row.orderID}
        disableColumnFilter
        disableColumnMenu
        onRowClick={(row) => navigateToOrder(row.id)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </div>
  );
}