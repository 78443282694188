import React, { useState } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import { Table, TableRow, TableHead, TableBody, TableCell } from '@mui/material';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import { grey } from '@mui/material/colors';
import CollapsibleCard from './CollapsibleCard';
import LoginState from '../components/LoginState';
import moment from 'moment';

export default function OrderSchedule({ order, afterCompleted }){
    const [drawer, setDrawer] = useState(false);
    const [current, setCurrent] = useState({ appt: order.inspectionDate, report: order.estDeliveryDate });
    const [temp, setTemp] = useState({ appt: order.inspectionDate, report: order.estDeliveryDate });
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState(null);
    const [error, setError] = useState(null);

    const _toggleDrawer = (open) => (e) => {
        if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
          return;
        }
        setDrawer(open || false);
        setError(null);
        setTemp({ appt: null , report: null });
      };

    const _setAppraisalDate = async () => {
        if(drawer === 'EstDeliveryDate' && new Date(temp.report) < new Date(current.appt || temp.appt)){
            setError('Report delivery date should be equal or later than appraisal meeting date');
            setLoading(false);
            return;
        }
        setLoading(true);
        const response = await axios.get('/setappraisaldate', {
            params: {
                pOrderID: order.orderID,
                pDateType: drawer,
                pDateValue: drawer === 'InspectionDate' ? temp.appt : temp.report,
            }
        })
        if(response.data && response.data === "Success") {
            setInfo(`${drawer === 'InspectionDate' ? 'Inspection' : 'Report delivery'} date has been ${current[drawer === 'EstDeliveryDate' ? 'report' : 'appt'] ? 'updated' : 'scheduled'}`);
            setDrawer(null);
            setLoading(false);
            setCurrent({...current, [drawer === 'EstDeliveryDate' ? 'report' : 'appt']: drawer === 'EstDeliveryDate' ? temp.report : temp.appt});
            afterCompleted();
        }
    }

    const contactName = order.contactName;
    const rawPhone = order.contactPhone;
    const contactPhone = rawPhone ? `(${rawPhone.slice(0,3)}) ${rawPhone.slice(3,6)} ${rawPhone.slice(6,10)}` : '-';
    const contactEmail = order.contactEmail;

    return (
        <CollapsibleCard title="schedule">
            {info && <Alert severity="info" sx={{ mb: 2}} onClose={() => setInfo(null)}>{info}</Alert>}
            <Tooltip title="Contact Info" placement="top">
                <Box sx={{ backgroundColor: 'grey.100', padding: 2, borderRadius: 1, display: 'flex', gapX: 4 }}>
                    <Box sx={{ display: 'flex' }}><PersonIcon sx={{ color: grey[500] }} /><Typography variant="body1" ml={1} mr={4} >{contactName}</Typography></Box>
                    <Box sx={{ display: 'flex' }}><PhoneIphoneIcon sx={{ color: grey[500] }} /><Typography variant="body1" ml={1} mr={4} >{contactPhone}</Typography></Box>
                    <Box sx={{ display: 'flex' }}><EmailIcon sx={{ color: grey[500] }} /><Typography variant="body1" ml={1} >{contactEmail}</Typography></Box>
                </Box>
            </Tooltip>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold'}}>Event</TableCell>
                        <TableCell sx={{ fontWeight: 'bold'}}>Date</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Inspection Meeting</TableCell>
                        <TableCell>{(current && current.appt) ? current.appt.toString() : 'Not Scheduled'}</TableCell>
                        {/* TODO only show button below if data is future date */}
                        <TableCell align="right">
                            {LoginState.userRole !== 'Portal'
                            && LoginState.userRole !== 'Admin'
                            && <Button
                                variant={current.appt ? "text" : "contained"}
                                size="small"
                                onClick={_toggleDrawer('InspectionDate')}>
                                    {current.appt ? "Change" : "Schedule" }
                            </Button>}
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>Report Delivery</TableCell>
                        <TableCell>{(current && current.appt) ? current.report.toString() : 'Not Scheduled'}</TableCell>
                        {/* TODO only show button below if data is future date */}
                        <TableCell align="right">
                            {LoginState.userRole !== 'Portal'
                            && LoginState.userRole !== 'Admin'
                            && <Button
                                variant={current.report ? "text" : "contained"}
                                size="small"
                                onClick={_toggleDrawer('EstDeliveryDate')}>
                                    {current.report ? "Change" : "Schedule" }
                            </Button>}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Drawer
                anchor="right"
                open={drawer ? true : false}
                onClose={_toggleDrawer()}
            >
                <Box
                    sx={{ width: 320, p: 2 }}
                    role="presentation"
                    onKeyDown={_toggleDrawer()}
                >
                    <Typography variant="h6" mb={2}>Select {drawer === 'InspectionDate' ? 'inspection' : 'report delivery'} date</Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label={drawer ? drawer === 'InspectionDate' ? 'Inspection Date' : 'Report Date' : ''}
                            value={drawer === 'InspectionDate' ? moment(temp.appt) : moment(temp.report)}
                            disabled={loading}
                            // error={error}
                            onChange={val => {
                                const formated = moment(val).format('MM/DD/YYYY');
                                setTemp({ ...temp, [drawer === 'InspectionDate' ? 'appt' : 'report']: formated });
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
                        />
                    </LocalizationProvider>
                    {error && <Alert onClose={() => setError(null)} severity="error" sx={{ mb : 2 }}>{error}</Alert>}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="text" sx={{ mr: 2 }} onClick={_toggleDrawer()}>Cancel</Button>
                        <LoadingButton variant="contained" loading={loading} onClick={_setAppraisalDate}>Submit</LoadingButton>
                    </Box>
                </Box>
            </Drawer>
        </CollapsibleCard>
    )
}