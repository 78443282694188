import React, { Component } from 'react'
import axios from 'axios';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import CustomLoader from './CustomLoader';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress, ListItemButton } from '@mui/material';
import celebration from '../icons/celebration.png';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';

export default class AppraiserFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appData: {},
      uploadedFiles: [],
      submitting: false,
      pageReady: false,
      messageSent: false,
      comment: ''
    };
  }

  componentDidMount() {
    this.getAppraiserData();
    this.getUploadData(this.props.match.params.id);
  }

  async getAppraiserData() {
    const response = axios.get("../getappraiserdata", { 
      params: { pAppraiser: this.props.match.params.id } 
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      this.setState({ appData: resultData });
    }
  }

  async getUploadData(user) {
    axios.get('/getAppraiserRegistrationFiles', {
      params: { pAppraiser: user }
    }).then(res => {
      if(res && res.data) {
        this.setState({
          uploadedFiles: res.data.files,
          pageReady: true,
          accessAllowed: true,
          dataFound: true
        });
      }
    }).catch(this.setState({ pageReady: true, dataFound: false }));
  }

  async downloadFile(fileName) {
    var response = axios.get('/getfiledata', { params: { pOrderID: this.props.match.params.id, pFileName: fileName } });
    var resultData = null;
    try {
      resultData = (await response).data;
      this.setState({ retrievedFile: resultData });
    } 
    catch {
      this.setState({ retrievedFile: '' });
    }

    var dlFN = fileName;
    if(fileName[fileName.length-1] === ')') {
      dlFN = "";
      var splitFN = fileName.split(' ');
      for(var i=0; i<splitFN.length-1; i++) {
        dlFN = dlFN + splitFN[i];
      }
    }

    var binary = atob(resultData);
    var array = [];
    for (var j=0; j<binary.length; j++) {
      array.push(binary.charCodeAt(j));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = dlFN;
    a.click();
    URL.revokeObjectURL(url);
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [`${e.target.name}Error`]: null } });
  };

  _submitReview() {
    this.setState({ submitting: true, messageSent: false });
    axios.get('/requestAdditional', {
      params: { pAppraiser: this.props.match.params.id, pComments: this.state.comment }
    }).then(res => {
      if(res && res.data) {
        this.setState({ submitting: false, messageSent: true });
      }
    });
  }

  _handleApproval () {
    this.setState({ submitting: true, messageSent: false });
    axios.get('/approveAppraiser', {
      params: { pAppraiser: this.props.match.params.id }
    }).then(res => {
      if(res && res.data) {
        let app = this.state.appData;
        app.approved = "True";
        this.setState({ appData: app, submitting: false });
      }
    });
  }

  render() {
    const { appData, submitting, comment, messageSent } = this.state;
    if (!this.state.pageReady) return <CustomLoader>Appraiser Files</CustomLoader>
    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', m: 4, textAlign: 'center' }}>Appraiser Files</Typography>
        {this.state.successMsg && <Alert severity="success" sx={{ my: 2 }}>{this.state.successMsg}</Alert>}
        {this.state.errorMsg && <Alert severity="error" sx={{ mb: 2 }}>{this.state.errorMsg}</Alert>}
        
        {this.state.uploadedFiles.length > 0 && (
          <Paper elevation={0} sx={{ maxWidth: '500px', mx: 'auto', border: 0, p: 3, textAlign: 'left' }}>
            <List>
            {this.state.uploadedFiles.map((file) => (
              <>
              <ListItem disablePadding key={file.name} sx={{ borderBottom: '1px' }}>
                <ListItemButton onClick={() => this.downloadFile(file.name)}>
                  <ListItemIcon sx={{ minWidth: '36px' }}>
                    <DownloadIcon />
                  </ListItemIcon>
                  <ListItemText primary={file.name} />
                </ListItemButton>
              </ListItem>
              {/* <Divider /> */}
            </>
            ))}
            </List>

            {appData.approved === "True" ? (
              <Box sx={{ mx: 'auto', textAlign: 'center', mt: 8 }}>
                <img src={celebration} alt="" width="100" />
                <Typography variant="h4" color="primary" sx={{ fontWeight: 'medium', mb: 2, mt: 4 }}>Appraiser Approved</Typography>
                <Typography variant="h5">Appraiser has been given access for receiving orders.</Typography>
              </Box>
            ) : (
              <Box>
                <Divider sx={{ mb: 2 }} />
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  id="order-review-comment"
                  name="comment"
                  label="Review Comment"
                  multiline
                  rows={2}
                  placeholder="Enter..."
                  value={comment}
                  onChange={this.captureInput}
                  disabled={submitting}
                />
                <Box sx={{ display: 'flex' }}>
                  <Button disableElevation disabled={submitting || !comment} variant="outlined" color="error" sx={{ mr: 2 }} onClick={() => this._submitReview(false)}>Request Documents</Button>
                  <Button disableElevation disabled={submitting} variant="contained" onClick={() => this._handleApproval()}>Approve Appraiser</Button>
                </Box>

                {messageSent && (
                  <Box sx={{ mx: 'auto', textAlign: 'center', mt: 8 }}>
                    <Typography variant="h4" color="primary" sx={{ fontWeight: 'medium', mb: 2, mt: 4 }}>Additional Documents Requested</Typography>
                    <Typography variant="h5">An email has been sent to the appraiser with your comments, to request additional documentation for approval.</Typography>
                  </Box>
                )}
              </Box>
            )}
          </Paper>
        )}
        {this.state.submitting && (
          <Stack justifyContent="center" alignItems="center" p={4}>
            <CircularProgress />
          </Stack>
        )}
      </Container>
    )
  }
}