import React, { useState } from 'react';
import axios from 'axios';
import { Base64 } from 'js-base64';
import LoginState from './LoginState';
import { JWT_SECRET_KEY } from '../helpers/constants';
import jwt from 'jsonwebtoken';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

export default function Login(props) {
    const [userID, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setError] = useState('');

    // Login user into system
    const loginUser = async () => {
        axios.get('login', {
            params: {
                pUserID: userID,
                pPassword: Base64.encode(password),
            }
        }).then(res => {
            if (res.data) {
                var userInfo = res.data;
                LoginState.loggedIn = true;
                LoginState.displayName = userInfo.displayName;
                LoginState.userRole = userInfo.userRole;
                LoginState.portalRole = userInfo.portalRole;
                LoginState.orgCode = userInfo.orgCode;
                LoginState.userName = userInfo.userName;
                LoginState.company = userInfo.company;
                const token = jwt.sign({ LoginState }, JWT_SECRET_KEY, {
                    algorithm: 'HS256',
                    expiresIn: "6h",
                });
                localStorage.setItem('user', JSON.stringify(LoginState));
                localStorage.setItem('token', token);
                localStorage.setItem('lastTimeStamp', Date.now());
                setError('');
                props.history.push("/");
            }
            else {
                setError("Invalid Login Credentials Supplied");
            }
        }).catch(e => console.log(e, 'Error signing in'));
    }

    const checkEnter = e => {
      if (e.charCode === 13) {
        loginUser();
        return;
      }
    }
    const handleInput = e => {
      if(e.target.name === 'userID'){
        setUserId(e.target.value)
      } else {
        setPassword(e.target.value)
      }
    }

    return (
        <Container>
            <Paper sx={{ p: 4, width: '60ch', mx: 'auto', mt: 8 }} elevation={0}>
                <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, textAlign: 'center' }}>Login</Typography>
                <Box component="form">
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            sx={{ mb: 2 }}
                            variant="outlined"
                            name='userID'
                            label='User'
                            value={userID}
                            onKeyPress={checkEnter}
                            onChange={handleInput}
                        />
                        <TextField
                            sx={{ mb: 2 }}
                            variant="outlined"
                            name='password'
                            type='password'
                            label='Password'
                            value={password}
                            onKeyPress={checkEnter}
                            onChange={handleInput}
                        />
                        <Button disableElevation variant="contained" size="large" onClick={loginUser}>Log In</Button>
                        {errorMsg && <Alert severity="error" sx={{ mt: 2 }} onClose={() => setError('')} >{errorMsg}</Alert>}
                    </Box>
                </Box>
            </Paper>
        </Container>
    )
}