import React, { Component } from 'react'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

export default class OrderSubmitted extends Component {
  render() {
    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Appraisal Order Submitted</Typography>

        <Stack justifyContent="center" alignItems="center">
          <h4>Order #: {this.props.match.params.id} Has Been Successfully Submitted</h4>
        </Stack>
      </Container>
    );
  }
}