import React, { Component } from 'react'
import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CustomLoader from './CustomLoader';
import LoginState from './LoginState';
import { grey, red, amber, lightGreen } from '@mui/material/colors';
import celebration from '../icons/celebration.png';
import { Link } from 'react-router-dom';

export default class AppraiserDashboard extends Component {

  constructor(props) {
    super(props);
 
    this.state = {
      file: [],
      bidFiles: [],
      valueCheckData: [],
      pageReady: false
    };

    this.getFiles();
  }

  async getFiles() {
    var response = axios.get('/getappraiserfiles', {
      params: { pUserName: LoginState.userName }
    });
    var resultData = (await response).data;
    if(resultData) {
      this.setState({ files: resultData });
    }
    else {
      this.setState({ errorMsg: 'Error Retreiving Order Data!', pageReady: true });
      return;
    }

    response = axios.get('../getbidfiles', {
      params: { pUserName: LoginState.userName }
    });
    resultData = (await response).data;
    if(resultData) {
      this.setState({ bidFiles: resultData });
    }
    else {
      this.setState({ errorMsg: 'Error Retrieving Bid Files!', pageReady: true });
      return;
    }

    // also get the value check data
    response = axios.get('../getappraiservaluechecks', {
      params: { pUserName: LoginState.userName }
    });
    resultData = (await response).data;
    if(resultData) {
      this.setState({ valueCheckData: resultData, pageReady: true });
    }
    else {
      this.setState({ errorMsg: 'Error Retreiving Value Check Data', pageReady: true });
    }
  }

  render() {
    if(!this.state.pageReady) return <CustomLoader>Appraiser Dashboard</CustomLoader>

    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Appraisal Dashboard</Typography>

        {((!this.state.files || this.state.files.length === 0) && 
          (!this.state.bidFiles || this.state.bidFiles.length === 0) &&
          (!this.state.valueCheckData || this.state.valueCheckData.length === 0)) && (
          <Box sx={{ mx: 'auto', textAlign: 'center', mt: 8 }}>
            <img src={celebration} width="100" alt="celebration-icon" />
            <Typography variant="h4" color="primary" sx={{ fontWeight: 'medium', mb: 2, mt: 4 }}>Data Not Available!</Typography>
            <Typography variant="h5">No Orders, Bid Requests, or Value Check Requests Found Under Your Account</Typography>
          </Box>
        )}

        {(this.state.files && this.state.files.length > 0) && (<>
          <Typography variant="h6">Appraisal Orders</Typography>
          <br/>
          <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Lender ID</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Order Type</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell align="center">Order Date</TableCell>
                  <TableCell align="center">Ordered By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.files.map((file) => {
                  let color = lightGreen[500];
                  if(file.status === 'Assigned')
                    color = amber[500];
                  if(file.status === 'Completed') 
                    color = grey[500];
                  return (
                    <TableRow
                      hover
                      key={file.requestID}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => this.props.history.push(`/order/${file.orderID}`)}
                    >
                      <TableCell>{file.orderID}</TableCell>
                      <TableCell>{file.lenderFileID}</TableCell>
                      <TableCell align="center" style={{ color }}>{file.status}</TableCell>
                      <TableCell align="center">{file.orderType}</TableCell>
                      <TableCell>{file.address}</TableCell>
                      <TableCell align="center">{file.orderDate}</TableCell>
                      <TableCell align="center">{file.orderedByDisplay}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <br/>
          <br/>
        </>)}

        {(this.state.bidFiles && this.state.bidFiles.length > 0) && (<>
          <Typography variant="h6">Bid Requests</Typography>
          <br/>
          <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell align="center">Order Date</TableCell>
                  <TableCell align="center">Your Bid</TableCell>
                  <TableCell align="center">Est Complete</TableCell>
                  <TableCell align="center">Submitted</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.bidFiles.map((bidFile) => {
                  return (
                    <TableRow
                      hover
                      key={bidFile.requestID}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => this.props.history.push(`/order-bid/${LoginState.userName}-${bidFile.orderID}`)}
                    >
                      <TableCell>{bidFile.orderID}</TableCell>
                      <TableCell>{bidFile.address}</TableCell>
                      <TableCell align="center">{bidFile.orderDate}</TableCell>
                      <TableCell align="center">{bidFile.userBidAmount}</TableCell>
                      <TableCell align="center">{bidFile.userBidEstComplete}</TableCell>
                      <TableCell align="center">{bidFile.userBidTimestamp}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <br/>
          <br/>
        </>)}

        {(this.state.valueCheckData && this.state.valueCheckData.length > 0) && (<>
        <Typography variant="h6">Value Check Requests</Typography>
        <br/>
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Address</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Request Date</TableCell>
                <TableCell align="center">Estimated Value</TableCell>
                <TableCell align="center">Your Response</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {this.state.valueCheckData.map((check) => {
              let color = check.status === 'Assigned' ? red[500] : grey[500];
              return (
              <TableRow 
                hover
                key={check.requestID} 
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => this.props.history.push(`/vc-response/${LoginState.userName}-${check.requestID}`)}
              >
                <TableCell>{check.requestID}</TableCell>
                <TableCell>{check.addressLine1} {check.addressLine3}</TableCell>
                <TableCell style={{ color }} align="center">{check.status}</TableCell>
                <TableCell align="center">{check.requestDate}</TableCell>
                <TableCell align="center">{check.marketValue}</TableCell>
                <TableCell align="center">
                  {check.responded
                    ? check.value
                      ? check.value 
                      : `${check.rangeMin} - ${check.rangeMax}` 
                    : <Button component={Link} to={`/vc-response/${LoginState.userName}-${check.requestID}`}>Respond</Button>
                  }
                </TableCell>
              </TableRow>
            )})}
            </TableBody>
          </Table>
        </TableContainer>
        </>)}

      </Container>
    );
  }
}