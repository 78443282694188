import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { Table, TableRow, TableHead, TableBody, TableCell } from '@mui/material';
import { grey } from '@mui/material/colors';
import LoginState from './LoginState';
import CollapsibleCard from './CollapsibleCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { shorten } from '../utils';

export default function OrderAppraiser({ order, afterCompleted, deskReviewNotReady }){
    const [edit, setEdit] = useState(false);
    const [selected, setSelected] = useState(null);
    const [candidate, setCandidate] = useState(null);
    const [appraisers,setAppraisers] = useState(null);
    const [error,setError] = useState(null);
    const [info,setInfo] = useState(null);
    const [loading,setLoading] = useState(false);


    const _assignAppraiser = async () => {
        setLoading(true);
        let response = await axios.get('/assignappraiser', {
            params: {
                pRequester: LoginState.userName,
                pOrderID: order.orderID,
                pAppraiser: candidate.userName,
                pPrevious: selected ? selected.userName : ''
            }
        });
        setLoading(false);
        if(response.data) {
            setSelected({...candidate});
            setEdit(false);
            setInfo(`${candidate.name} has been assigned as appraiser`);
            setCandidate(null);
            afterCompleted();
        } else {
            setError('Something went wrong, please try again');
        }
    }

    useEffect(() => {
        const _getAppraisersAndValueCheck = async () => {

            const response = axios.get("../getappraisers", {
              params: {
                pOrderID: order.orderID
              }
            });
            let appraisers = (await response).data;
            if(appraisers) {
                setAppraisers(appraisers);
                if(order.assignee){
                    const assignee = appraisers.find(app => app.userName === order.assignee);
                    setSelected(assignee);
                }
            } else {
                setError('Something went wrong fetching appraisers and Value Check');
            }
            let vcData = await axios.get('/getvaluecheckdata').data; // { params: { address: 'order.address'}})
            if(vcData){
                vcData.forEach(vc => {
                    if(order.address.includes(vc.addressLine1)){
                        setAppraisers(appraisers.map(app => {
                            if(app.email === vc.email){
                                app.valueCheck = vc.valuecheck
                            }
                            return app;
                        }))
                    }
                })
            }

        }
        if(!appraisers && !error && order){
            _getAppraisersAndValueCheck();
        }
        // if(!loan && !error){
        //     _getLoanDetails();
        // }
    },[appraisers,error,order])


    if(!order){
        return (
            <Box sx={{ border: 1, borderRadius: 1, borderColor: 'grey.300', padding: 2, mb: 2 }}>
                <Skeleton vartiant="rectangular" width="100%" height={58} />
            </Box>
        )
    }



    return (
        <CollapsibleCard title="appraiser">
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            {info && <Alert severity="info" sx={{ mb: 2 }}>{info}</Alert>}
            {(selected && !edit) ? (
                <Box textAlign="center" color={grey[800]}>
                    <Typography variant="h6" mr={2}>{selected.name} - {selected.email}</Typography>
                    {(selected && !edit && LoginState.userRole === 'Admin') && <Button type="text" size="small" sx={{ px: 2 }} onClick={() => setEdit(true)}>Change</Button>}
                </Box>
            ) : (
                <>
                    {order.orderNotes !== "---" && (
                      <Box px={1}>
                        <Typography variant="body2" mb={1}>Notes from Broker</Typography>
                        <Typography paragraph style={{whiteSpace: 'pre-wrap', fontSize: '13px', color: 'darkblue', marginLeft: '15px'}}>
                            {order.orderNotes}
                        </Typography>
                      </Box>
                    )}
                    {appraisers ? (
                      <Table>
                          <TableHead>
                              <TableRow>
                                  <TableCell>Select</TableCell>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Email</TableCell>
                                  {order.valueChecks && <TableCell>Value Check</TableCell>}
                                  <TableCell>Bid</TableCell>
                                  <TableCell>Est. Complete</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {appraisers.map(appraiser => {
                                  const selected = order.valueChecks && order.valueChecks.find(el => el.appraiserName.includes(appraiser.name));
                                  const value = selected
                                      ? (selected.value && selected.value !== "---")
                                          ? shorten(selected.value)
                                          : `${shorten(selected.minRange)} - ${shorten(selected.maxRange)}`
                                      : '-';
                                  if(appraiser.active === "True")
                                    return (
                                      <TableRow key={appraiser.userName}>
                                          <TableCell component="th" scope="row" align="center" padding="checkbox">
                                              <Radio
                                                  checked={candidate ? candidate.userName === appraiser.userName : selected  ? selected.userName === appraiser.userName : false}
                                                  onChange={() => setCandidate(appraiser)}
                                                  value={appraiser}
                                                  name="radio-buttons"
                                                  inputProps={{ "aria-label": appraiser }}
                                              />
                                          </TableCell>
                                          <TableCell>{appraiser.name}</TableCell>
                                          <TableCell>{appraiser.email}</TableCell>
                                          {order.valueChecks && <TableCell sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                              {value} {value !== "-" && <Tooltip title="Selected Value"><CheckCircleIcon fontSize="small" color="success" /></Tooltip>}
                                          </TableCell>}
                                          <TableCell>
                                            {appraiser.bidAmount ? (
                                              <Tooltip title={<>{appraiser.timestamp}<br/>{appraiser.notes}</>}>
                                                <div>{appraiser.bidAmount}</div>
                                              </Tooltip>
                                            ) : (
                                              <>---</>
                                            )}
                                          </TableCell>
                                          <TableCell>{appraiser.bidComplete || '---'}</TableCell>
                                      </TableRow>
                                    )
                                  })}
                          </TableBody>
                      </Table>
                    ): (
                      <Stack justifyContent="center" alignItems="center" p={4}>
                        <CircularProgress />
                      </Stack>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, gap: 2 }}>
                        {deskReviewNotReady && (
                            <span style={{color:'red', fontSize:'13px', position:'relative', top:'5px'}}>
                                (Please Upload a Desk Review File Before Assigning Appraiser)
                            </span>
                        )}
                        {selected && <Button variant="text" onClick={() => {
                            setEdit(false);
                            setCandidate(null);
                        }}>Cancel</Button>}
                        <LoadingButton variant="contained" disabled={!candidate || deskReviewNotReady} loading={loading} onClick={_assignAppraiser}>Save</LoadingButton>
                    </Box>
                </>
            )}
        </CollapsibleCard>
    )
}