import React, { Component } from 'react';
import axios from 'axios';
import LoginState from './LoginState';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { blue } from '@mui/material/colors';

export default class Header extends Component {
  constructor(props) {
    super(props);

    // check for timeout
    const lastTS = localStorage.getItem('lastTimeStamp');
    if (!lastTS) {
      // no timestamp found (logout)
      LoginState.loggedIn = false;
    } else {
      const elapsedSeconds = (Date.now() - lastTS) / 1000;
      if (elapsedSeconds > 60 * 60 * 1) { // 1 hour
        // time exceeded (logout)
        LoginState.loggedIn = false;
      }
    }

    this.state = {
      anchorEl: null,
      open: false,
      menuOpen: false,
      loggedIn: LoginState.loggedIn,
      notificationCount: 0
    }
  }

  componentDidUpdate() {
    if (this.state.loggedIn !== LoginState.loggedIn)
      this.setState({ loggedIn: LoginState.loggedIn });
  }

  async getNotificationCount() {
    // todo: setup code to fetch later on
    var response = axios.get('/getnotificationcount', { params: { pUserName: LoginState.userName } });
    try {
      var resultData = (await response).data;
      this.setState({ notificationCount: resultData });
    }
    catch {
      this.setState({ notificationCount: '--' });
    }
  }

  logoutUser() {
    LoginState.loggedIn = false;
    LoginState.displayName = '';
    LoginState.userRole = '';
    LoginState.userName = '';

    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('lastTimeStamp');
    this.setState({ anchorRef: null, open: false, loggedIn: false });
    this.props.history.push("/login");
  }

  handleAvatarClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };
  handleAvatarClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  _toggleDrawer = () => {
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }))
  }

  render() {
    return (
      <AppBar position="fixed" elevation={0}>
        <Toolbar >
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, p: 1 }}>
            <img src="images/1StopAmcLogoLight.png" width="150px" height="auto" alt="company logo" />
          </Box>
          <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
            {LoginState.loggedIn && LoginState.userRole === 'Portal' && (
              <>
                <Button variant="primary" to="/pipeline" component={Link}>Order Pipeline</Button>
                <Button variant="primary" to="/value-check-form" component={Link}>VC Request</Button>
              </>
            )}
            {LoginState.loggedIn && LoginState.userRole === 'Appraiser' && (
              <>
                <Button
                  sx={{ mr: 2 }}
                  variant="primary"
                  onClick={() => this.props.history.push("/appraiser-dashboard")}
                >
                  Dashboard
                </Button>
              </>
            )}
            {LoginState.loggedIn && LoginState.userRole === 'Admin' && (
              <>
                <Button
                  variant="primary"
                  sx={{ mr: 2 }}
                  onClick={() => this.props.history.push("/pipeline")}
                >
                  Order Pipeline
                </Button>
                <Button
                  variant="primary"
                  sx={{ mr: 2 }}
                  onClick={() => this.props.history.push("/request/new")}
                >
                  Order Appraisal
                </Button>
                <Button
                  variant="primary"
                  sx={{ mr: 2 }}
                  onClick={() => this.props.history.push("/value-check")}
                >
                  VC Pipeline
                </Button>
                <Button
                  variant="primary"
                  sx={{ mr: 2 }}
                  onClick={() => this.props.history.push("/value-check-form")}
                >
                  VC Request
                </Button>
                <Button
                  variant="primary"
                  sx={{ mr: 2 }}
                  onClick={() => this.props.history.push("/manage-users")}
                >
                  Appraisers
                </Button>
              </>
            )}
            {!this.state.loggedIn ? (
              <Button
                color="inherit"
                onClick={() => this.props.history.push("/login")}>
                <ExitToAppIcon color="primary" />&nbsp;Login
              </Button>
            ) : (
              <>
                <IconButton size="small" sx={{ ml: 2 }} onClick={this.handleAvatarClick}>
                  <Avatar sx={{ bgcolor: blue[700] }} >{LoginState.displayName && `${LoginState.displayName.split(" ")[0][0]}${LoginState.displayName.split(" ")[1][0]}` }</Avatar>
                </IconButton>
                <Menu
                  anchorEl={this.state.anchorEl}
                  open={this.state.open}
                  onClose={this.handleAvatarClose}
                  onClick={this.handleAvatarClose}
                  // onMouseEnter={() => this.getNotificationCount()}
                  MenuListProps={{
                    onMouseEnter: this.enterMenu,
                    onMouseLeave: this.leaveMenu,
                  }}>
                  <MenuItem disabled={true}>{LoginState.displayName} - {LoginState.userRole.toUpperCase()}</MenuItem>
                  <Divider />
                  {LoginState.userRole !== 'Portal' && <MenuItem onClick={() => this.props.history.push("/settings")}>Settings</MenuItem>}
                  <MenuItem onClick={() => this.logoutUser()}>Logout</MenuItem>
                </Menu>
              </>
            )}
          </Stack>
          {this.state.loggedIn && <Stack sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton onClick={this._toggleDrawer}>
              <MenuIcon />
            </IconButton>
          </Stack>}
          <SwipeableDrawer
            anchor="right"
            open={this.state.menuOpen}
            onClose={this._toggleDrawer}
            onOpen={this._toggleDrawer}
          >
            <Box
              sx={{ width: 'auto' }}
              role="presentation"
              onClick={this._toggleDrawer}
              onKeyDown={this._toggleDrawer}
            >
              {LoginState.loggedIn && LoginState.userRole === 'Portal' && (
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => this.props.history.push("/pipeline")} >
                    <ListItemIcon>
                      <MoveToInboxOutlinedIcon />
                    </ListItemIcon>
                      <ListItemText primary="Order Pipeline" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => this.props.history.push("/value-check-form")} >
                    <ListItemIcon>
                      <MoveToInboxOutlinedIcon />
                    </ListItemIcon>
                      <ListItemText primary="VC Request" />
                    </ListItemButton>
                  </ListItem>
                </List>
              )}
              {LoginState.loggedIn && LoginState.userRole === 'Appraiser' && (
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => this.props.history.push("/appraiser-dashboard")} >
                    <ListItemIcon>
                      <DashboardOutlinedIcon />
                    </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItemButton>
                  </ListItem>
                </List>
              )}
              {LoginState.loggedIn && LoginState.userRole === 'Admin' && (
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => this.props.history.push("/pipeline")} >
                    <ListItemIcon>
                      <MoveToInboxOutlinedIcon />
                    </ListItemIcon>
                      <ListItemText primary="Order Pipeline" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => this.props.history.push("/request/new")} >
                    <ListItemIcon>
                      <AddBusinessOutlinedIcon />
                    </ListItemIcon>
                      <ListItemText primary="Order Appraisal" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => this.props.history.push("/value-check")} >
                    <ListItemIcon>
                      <PriceChangeOutlinedIcon />
                    </ListItemIcon>
                      <ListItemText primary="VC Pipeline" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => this.props.history.push("/value-check-form")} >
                    <ListItemIcon>
                      <AddCircleOutlineOutlinedIcon />
                    </ListItemIcon>
                      <ListItemText primary="VC Request" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => this.props.history.push("/manage-users")} >
                      <ListItemIcon>
                        <GroupOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Appraisers" />
                    </ListItemButton>
                  </ListItem>
                </List>
              )}
              <Divider />
              <List>
                {(LoginState.loggedIn && LoginState.userRole !== 'Portal') && (<ListItem disablePadding>
                  <ListItemButton onClick={() => this.props.history.push("/settings")} >
                    <ListItemIcon>
                      <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </ListItemButton>
                </ListItem>)}
                <ListItem disablePadding>
                  <ListItemButton onClick={() => this.logoutUser()} >
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Log Out" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </SwipeableDrawer>
        </Toolbar>
      </AppBar>
    );
  }
}