import React, { Component } from 'react'
import axios from 'axios';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomDialog from './CustomDialog';
import CustomLoader from './CustomLoader';
export default class ValueCheckStatus extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      requestSent: false,
      valueCheckDetail: [],
      appraisers: [],
      selected: false,
      confirmationID: '',
      confirmationMsg: '',
      pageReady: false
    };

    this.getValueCheckDetails();
  }

  async getValueCheckDetails() {
    var i=0;
    var response = axios.get('../getvaluecheckdata');

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      for(i=0; i<resultData.length; i++) {
        if(resultData[i].requestID  === this.props.match.params.id) {
          this.setState({ 
            valueCheckDetail: resultData[i], 
            requestSent: (resultData[i].status !== 'Requested')
          });
          break;
        }
      }
    }

    response = axios.get('../getvaluecheckappraisers', 
      { params: { pValueCheckID: this.props.match.params.id } });
    
    // the response.data will contain the values returned
    resultData = (await response).data;
    var selected = false;
    if(resultData) {
      for(i=0; i<resultData.length; i++) {
        if(resultData[i].selected === 'True') {
          selected = true;
          break;
        }
      }
      this.setState({ appraisers: resultData, selected: selected, pageReady: true });
    }
  }

  navigateBack() {
    try {
      this.props.history.back();
    }
    catch {
      this.props.history.push('/value-check');
    }
  }

  async selectValue(userName) {
    const response = axios.get('../selectvaluecheck', { 
      params: { 
        pValueCheckID: this.props.match.params.id,
        pUserName: userName 
      } 
    });
    
    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData === "Success") {
      for(var i=0; i<this.state.appraisers.length; i++) {
        if(this.state.appraisers[i].userName === userName) {
          this.state.appraisers[i].selected = 'True';
        }
        else {
          this.state.appraisers[i].selected = 'False';
        }
      }
      this.setState({ selected: true, confirmationID: '' });
    }
  }

  render() {
    if(!this.state.pageReady) return <CustomLoader>Value Check Status</CustomLoader>

    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Value Check Status</Typography>
        <Button variant="text" size="large" sx={{ mb: 2 }} onClick={() => this.navigateBack()} startIcon={<ArrowBackIcon />}>Back</Button>
        {this.state.valueCheckDetail.requestID ? (
          <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ backgroundColor: green[50], p: 2, borderRadius: 1, mb: 2 }}>
                <Typography variant="subtitle1" color={green[700]}>Request Date</Typography>
                <Typography variant="h5">{this.state.valueCheckDetail.requestDate}</Typography>
              </Box>
              <Box sx={{ backgroundColor: green[50], p: 2, borderRadius: 1, mb: 2 }}>
                <Typography variant="subtitle1" color={green[700]}>First Reply</Typography>
                <Typography variant="h5">{this.state.valueCheckDetail.requestDate}</Typography>
              </Box>
              <Box sx={{ backgroundColor: green[50], p: 2, borderRadius: 1, mb: 4 }}>
                <Typography variant="subtitle1" color={green[700]}>Assigned</Typography>
                <Typography variant="h5">{this.state.valueCheckDetail.requestDate}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ pl: 2, mb: 4 }}>
                <Typography variant="caption">Request By (Email):</Typography>
                <Typography variant="h5" sx={{ mb: 2 }}>{this.state.valueCheckDetail.email}</Typography>
                <Typography variant="caption">Address:</Typography>
                <Typography variant="h5">{this.state.valueCheckDetail.addressLine1}</Typography>
                {this.state.valueCheckDetail.addressLine2 && (
                  <Typography variant="h5" sx={{ mb: 2 }}>{this.state.valueCheckDetail.addressLine2}</Typography>
                )}
                <Typography variant="h5" sx={{ mb: 2 }}>{this.state.valueCheckDetail.addressLine3}</Typography>
                <Typography variant="caption">Market Value:</Typography>
                <Typography variant="h5">{this.state.valueCheckDetail.marketValue}</Typography>
              </Box>
            </Grid>
          </Grid>

          {(this.state.appraisers.length > 0) && (
            <>
              <TableContainer component={Paper} elevation={0} sx={{ width: 'auto', mb: 4 }}>
                <Table aria-label="appraiser table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Appraiser</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Min Range</TableCell>
                      <TableCell>Max Range</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.appraisers.map((apr) => (
                      <TableRow key={apr.userName} 
                        style={{backgroundColor: (apr.selected === 'True' ? 'aliceblue' : 'white')}}>
                        <TableCell>
                          {apr.responded === 'True' ? (
                            <CheckCircleIcon style={{color:'green'}} />
                          ) : (
                            <QueryBuilderIcon style={{color:'deepOrange'}} />
                          )}
                        </TableCell>
                        <TableCell>{apr.name}</TableCell>
                        <TableCell>{apr.company}</TableCell>
                        <TableCell>{apr.email}</TableCell>
                        <TableCell>{apr.value}</TableCell>
                        <TableCell>{apr.rangeMin}</TableCell>
                        <TableCell>{apr.rangeMax}</TableCell>
                        {!this.state.selected ? (
                          <TableCell>
                            {apr.responded === 'True' && (
                              <Button size="small" variant="contained"
                                onClick={() => this.setState({
                                  confirmationID: apr.userName,
                                  confirmationMsg: apr.name + '\n' + 
                                    (apr.value === '---' ? 
                                      apr.rangeMin + ' to ' + apr.rangeMax : 
                                      apr.value
                                    )
                                })}
                              >Select</Button> 
                            )}
                          </TableCell>
                        ) : (
                          <TableCell style={{textAlign: 'center'}}>
                            {apr.responded === 'True' && apr.selected !== 'True' && (
                              <Button size="small" variant="outlined"
                                onClick={() => this.setState({
                                  confirmationID: apr.userName,
                                  confirmationMsg: 'Change Selected Value To:\n' +
                                    apr.name + '\n' + 
                                    (apr.value === '---' ? 
                                      apr.rangeMin + ' to ' + apr.rangeMax : 
                                      apr.value
                                    )
                                })}
                              >Change</Button>
                            )}
                            {apr.responded === 'True' && apr.selected === 'True' && (
                              <Button size="small" disabled variant="outlined">Selected</Button>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
            <CustomDialog
              open={this.state.confirmationID}
              title='Confirm'
              showConfirm={true}
              confirmMsg='Accept'
              onConfirm={() => this.selectValue(this.state.confirmationID)}
              showClose={true}
              closeMsg='Cancel'
              onClose={() => this.setState({ confirmationID: '' })}
            >{this.state.confirmationMsg}</CustomDialog>
          </>
        ) : (
          <CustomDialog
            open={this.state.errorMsg}
            title='Error'
            onClose={() => this.setState({ errorMsg: '' })}
          >{this.state.errorMsg}</CustomDialog>
        )}
      </Container>
    );
  }
}

