import React, { Component } from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoginState from './LoginState';
import Alert from '@mui/material/Alert';

export default class Settings extends Component {

  constructor(props) {
    super(props); 
    this.state = {
      submitting: false,
      errors: {}
    };
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [`${e.target.name}Error`]: null } });
  };

  async changePW() {
    const errors = {};
    const requiredFields = ['currentPass', 'newPass1', 'newPass2'];
    requiredFields.forEach(field => {
      if (!this.state[field] || this.state[field].length < 3) {
        errors[`${field}Error`] = 'Password Needs To Be At Least 3 Characters Long';
      }
    });

    if (this.state.newPass1 !== this.state.newPass2) {
      errors.newPass1Error = 'Passwords Do Not Match';
      errors.newPass2Error = 'Passwords Do Not Match';
    }

    if (Object.values(errors).length > 0) {
      this.setState({ errors, submitting: false, errorMsg: '', successMsg: '' });
    } 
    else {
      this.setState({ errors, submitting: true, errorMsg: '', successMsg: '' });
      var response = axios.get('../changepassword', {
        params: { 
          pUserName: LoginState.userName,
          pOld: this.state.currentPass,
          pNew: this.state.newPass1,
        }
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;
      if(resultData === "Success") {
        this.setState({ 
          successMsg: "Password Successfully Updated!", errorMsg: "", 
          submitting: false, currentPass: '', newPass1: '', newPass2: '' 
        });
      }
      else {
        this.setState({ successMsg: "", errorMsg: resultData, submitting: false });
      }
    }
  }

  render() {
    const errors = this.state.errors;
    return (
      <Container>
        <Box sx={{ mx: 'auto' }}>
          <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Change Password</Typography>
          <Box component="form">
            <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '450px', mx: 'auto' }}>
              <TextField
                required
                type="password"
                error={errors.currentPassError ? true : false}
                helperText={errors.currentPassError}
                sx={{ mb: 2 }}
                variant="outlined"
                name='currentPass'
                label='Current Password'
                value={this.state.currentPass}
                onChange={this.captureInput}
              />
              <TextField
                required
                type="password"
                error={errors.newPass1Error ? true : false}
                helperText={errors.newPass1Error}
                sx={{ mb: 2 }}
                variant="outlined"
                name='newPass1'
                label='New Password'
                value={this.state.newPass1}
                onChange={this.captureInput}
              />
              <TextField
                required
                type="password"
                error={errors.newPass2Error ? true : false}
                helperText={errors.newPass2Error}
                sx={{ mb: 2 }}
                variant="outlined"
                name='newPass2'
                label='Confirm Password'
                value={this.state.newPass2}
                onChange={this.captureInput}
              />
              <Box sx={{ display: 'flex', mb: '15px' }}>
                <Button
                  size="large"
                  variant="outlined"
                  sx={{ mr: 2, flex: 1 }}
                  disabled={this.state.submitting}
                  onClick={() => this.props.history.push("/")}>Back</Button>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ flex: 1 }}
                  disabled={this.state.submitting}
                  onClick={() => this.changePW()}>Change PW</Button>
              </Box>
              {this.state.errorMsg && (
                <Alert severity="error">{this.state.errorMsg}</Alert>
              )}
              {this.state.successMsg && (
                <Alert severity="success">{this.state.successMsg}</Alert>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
};
