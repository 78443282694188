
import React, { Component } from 'react'
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CustomLoader from './CustomLoader';

export default class PaymentSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderID: this.props.match.params.id,
      paymentMade: false,
      pageReady: false
    };
    if(!this.props.match.params.id) {
      this.props.history.push("/");
      return;
    }

    this.setPaid();
  }

  async setPaid() {
    const response = axios.get('/markpaid', {
      params: { pOrderID: this.state.orderID }
    });
    
    var resultData = (await response).data;
    if(resultData === "Success") {
      this.setState({ pageReady: true, paymentMade: true });

      // also update parent page
      window.opener.markPaid();
    }
    else
      this.setState({ pageReady: true, paymentMade: false });
  }

  render() {
    if(!this.state.pageReady) return <CustomLoader>Payment Success</CustomLoader>
    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Payment Success</Typography>
        {this.state.paymentMade ? (
          <Box sx={{ maxWidth: '450px', mx: 'auto', textAlign: 'center' }}>
            Payment Has Been Successfully Made For
            <br/>
            Order #: {this.state.orderID}
            <br/>
            <br/>
            <Button variant="contained" size="large"
              onClick={() => window.close()}>Close Page</Button>
          </Box>
        ) : (
          <Box sx={{ maxWidth: '450px', mx: 'auto', textAlign: 'center' }}>
            Payment Status Could Not Be Verified For
            <br/>
            Order #: {this.state.orderID}
            <br/>
            <br/>
            <Button variant="contained" size="large"
              onClick={() => window.close()}>Close Page</Button>
          </Box>
        )}
      </Container>
    )
  }
}