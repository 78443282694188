import React from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalCard = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  z-index: 9999;
  width: ${p => (p.width ? p.width : '350px')};
  min-height: ${p => (p.height ? p.height : '100px')};
  max-height: 90vh;
  background-color: white;
  margin: 0 auto;
  align-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const ModalBody = styled.div`
  margin: 0;
  padding: 1rem;
  margin-top: 1rem;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export default function ModalConfirm(props) {
  return props.show ? (
    <ModalWrapper>
      <ModalCard width={props.width} height={props.height}>
        <ModalBody>
          {props.children}
          <br/>
          <button
            type="button"
            className="btn btn-secondary py-0"
            onClick={props.onDecline}
          >
            Close
          </button>  
        </ModalBody>
      </ModalCard>
    </ModalWrapper>
  ) : (
    <></>
  );
}
