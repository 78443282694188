import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { base64ArrayBuffer } from '../helpers/utils';
import CustomLoader from './CustomLoader';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import LoginState from './LoginState';
import { useParams } from 'react-router-dom';
import CollapsibleCard from './CollapsibleCard';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import ModalConfirm from './ModalConfirm';

export default function OrderDeskReview({ order, afterCompleted }) {
  const [deskReviewFile, setDeskReviewFile] = useState();
  const [newFiles, setNewFiles] = useState([]);
  const [isSubmitting, setSubmitting] = useState(null);
  const [alert, setAlert] = useState();
  const [message, setMessage] = useState();
  const params = useParams();

  const _getUploadData = async () => {
    let response = await axios.get('/getdeskreviewfile', {
      params: { pOrderID: params.id },
    });
    if (response.data) {
      setDeskReviewFile(response.data);
    }
  };

  useEffect(() => {
    if (!deskReviewFile) {
      _getUploadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deskReviewFile);

  const _InputContent = () => {
    if (isSubmitting) return <CustomLoader>Submitting File(s)...</CustomLoader>
    return (
      <Box sx={{ textAlign: 'center' }}>
          <CloudUploadIcon color="disabled" sx={{ fontSize: 40 }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', textAlign: 'center' }}>
              Drag &amp; Drop or <u>browse.</u> (PDF)
          </Typography>
      </Box>
    );
  }

  const MAX_FILE_SIZE = 25000000;

  const _combineFiles = (file1, file2) => {
    return file1 + '$$$$$' + file2;
  };

  const _uploadToServer = async () => {
    if(newFiles.length === 0) {
      return
    }
    // check to see that combined file size is not over limit
    let totalSize = 0;
    for (let i = 0; i < newFiles.length; i++) {
      totalSize += newFiles[i].size;
    }
    if (totalSize >= MAX_FILE_SIZE) {
      setAlert('Combined File Size Exceeds 25Mb!');
      setMessage('Combined File Size Exceeds 25Mb!');
      return;
    }
    setSubmitting(true);

    // first combine all the files into one.
    let combinedFiles = null;
    for (let j = 0; j < newFiles.length; j++) {
      if (j === 0) combinedFiles = newFiles[j].fileUIntArr;
      else
        combinedFiles = _combineFiles(combinedFiles, newFiles[j].fileUIntArr);
    }
    // also combine the file names
    let combinedFileNames = null;
    for (let k = 0; k < newFiles.length; k++) {
      if (k === 0) combinedFileNames = newFiles[k].fileName;
      else
        combinedFileNames = _combineFiles(
          combinedFileNames,
          newFiles[k].fileName
        );
    }

    // then submit to server
    const params = {
      orderID: order.orderID,
      fileNames: combinedFileNames,
      fileContent: combinedFiles,
      userName: LoginState.userName,
    };

    fetch('/FileUploadDeskReview', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.text())
      .then((data) => {
        // error check
        if (!data.startsWith('Success')) {
          // show error message
          setSubmitting(false);
          setAlert('Error Uploading File(s)!');
          setMessage('Error Uploading File(s)!');
        } else {
          // notify for successful upload
          setSubmitting(false);
          setNewFiles([]);
          setAlert('File(s) Successfully Uploaded!');
          setMessage('File(s) Successfully Uploaded!');
          _getUploadData();
          afterCompleted();
        }
      });
  };

  const _downloadFile = async (fileName) => {
    var response = axios.get('/getfiledata', {
      params: { pOrderID: order.orderID, pFileName: fileName },
    });
    var resultData = null;
    try {
      resultData = (await response).data;
    } catch {
      setAlert('Error Downloading File!');
      setMessage('Error Downloading File!');
    }

    var dlFN = fileName;
    if (fileName[fileName.length - 1] === ')') {
      dlFN = '';
      var splitFN = fileName.split(' ');
      for (var i = 0; i < splitFN.length - 1; i++) {
        dlFN = dlFN + splitFN[i];
      }
    }

    var binary = atob(resultData);
    var array = [];
    for (var j = 0; j < binary.length; j++) {
      array.push(binary.charCodeAt(j));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = dlFN;
    a.click();
    URL.revokeObjectURL(url);
  };

  const _handleChangeStatus = ({ file, remove }, status) => {
    if (file && file.size > MAX_FILE_SIZE) {
      setAlert('File Size Cannot Exceed 25 Mb!');
      setMessage('File Size Cannot Exceed 25 Mb!');
    }
    if (status === 'preparing') {
      setSubmitting(true);
    } else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        fileToUpload = e.target.result;
      };
      reader.onloadend = () => {
        var uintarr = base64ArrayBuffer(fileToUpload);
        setNewFiles(
          [
            {
              fileUIntArr: uintarr,
              fileName: file.name,
              size: file.size,
            },
          ]
        );
        remove();
        setTimeout(() => setSubmitting(false), 2000);
      };
    }
  };

  const _deleteFile = (fileName) => {
    let newArr = [...newFiles];
    for (let i = 0; i < newArr.length; i++) {
      if (newArr[i].fileName === fileName) {
        newArr.splice(i, 1);
        setNewFiles(newArr);
        break;
      }
    }
  };

  const _handleInputChange = (e) => {
    e.preventDefault();
  };

  return (
    <CollapsibleCard title="Desk Review File">
      {alert && (
        <Alert severity="info" sx={{ mb: 2 }}>
          {alert}
        </Alert>
      )}

      {LoginState.userRole === 'Admin' && (<>
        <Typography variant="subtitle1" mb={2}>Upload File For Appraiser Review</Typography>
        <Dropzone
          maxFiles="1"
          maxSizeBytes="25000000" // 25 Mb
          accept=".pdf"
          onChangeStatus={_handleChangeStatus}
          inputContent={_InputContent}
          styles={{
            dropzone: {
              overflow: 'hidden',
              border: '2px dashed lightgray',
              borderRadius: '0.25rem',
            },
            dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
            inputLabel: (_, extra) => extra.reject ? { color: 'red' } : {}
          }}
        />
        {newFiles.length > 0 && (
          newFiles.map(file => (
            <Box sx={{ backgroundColor: 'grey.100', p:1, pl: 2 , borderRadius: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Typography variant="body1">
                {file.fileName} <Chip size="small" variant="outlined" label="New" color="success" />
              </Typography>
              <IconButton sx={{ m: 0 }}>
                <DeleteIcon onClick={() => _deleteFile(file.fileName)} />
              </IconButton>
            </Box>
          ))
        )}
      </>)}

      {deskReviewFile && (
        <Box
          sx={{
            backgroundColor: 'grey.100',
            p: 1,
            pl: 2,
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '15px 0px'
          }}
        >
          <Typography variant="body1">{deskReviewFile}</Typography>
          <IconButton sx={{ m: 0 }}>
            <DownloadIcon onClick={() => _downloadFile(deskReviewFile)} />
          </IconButton>
        </Box>
      )}

      {LoginState.userRole === 'Admin' && (<>
        <Box>
          {isSubmitting ? (
            <Stack justifyContent="center" alignItems="center" p={4}>
              <CircularProgress />
            </Stack>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <Box>
                <Button
                  disableElevation
                  disabled={isSubmitting || newFiles.length === 0}
                  variant="contained"
                  onClick={_uploadToServer}
                >
                  {deskReviewFile ? 'Replace File' : 'Submit File'}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </>)}
    </CollapsibleCard>
  );
}