import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import { lightBlue, green, red } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Card, CardContent } from '@mui/material';
import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import Receipt from './Receipt.js';
import LoginState from './LoginState';
import CustomDialog from './CustomDialog';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingButton } from '@mui/lab';
import { getPropertyType } from '../helpers/utils.js';

export default function OrderDetails({ order, orderFiles, afterCompleted }) {
  const [copied, setCopied] = useState(false);
  const [isDownloading, setIsDownloading] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [complete, setComplete] = useState(false);

  const history = useHistory();

  const GetPropertyType = (loan) => {
    var unitCount = parseInt(loan.numberOfUnits);
    if (unitCount > 1) { return unitCount + " Unit"; }
    return getPropertyType(loan.propertyType);
  }

    const _handleCopyToClipboard = () => {
        navigator.clipboard.writeText(order.address);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000)
    }

    const _downloadInvoice = async (fileName) => {
      var response = axios.get('/getfiledata', { params: { pOrderID: order.orderID, pFileName: fileName } });
      try {
        var resultData = (await response).data;
        var dlFN = fileName;
        if(fileName[fileName.length-1] === ')') {
          dlFN = "";
          var splitFN = fileName.split(' ');
          for(var i=0; i<splitFN.length-1; i++) {
            dlFN = dlFN + splitFN[i];
          }
        }
        var binary = atob(resultData);
        var array = [];
        for (var j=0; j<binary.length; j++) {
          array.push(binary.charCodeAt(j));
        }
        var file = new Blob([new Uint8Array(array)]);

        // this code below will download the file we selected
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = dlFN;
        a.click();
        URL.revokeObjectURL(url);
      }
      catch {}
  }

  const _downloadFile = async (docID) => {
    setIsDownloading(docID);
    var response = axios.get('/getdocument', { params: { pDocID: docID } });
    var resultData = null;
    try {
      resultData = (await response).data;
    } catch {}

    var binary = atob(resultData.documentData);
    var array = [];
    for (var j = 0; j < binary.length; j++) {
      array.push(binary.charCodeAt(j));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = resultData.documentName + '.' + resultData.documentExension;
    a.click();
    URL.revokeObjectURL(url);
    setIsDownloading(null);
  };

  const cancelOrder = async (approved) => {
    if(!approved){
      setConfirm(true);
      return;
    }
    setConfirm(false);
    setIsProcessing(true);
    let response = await axios.get('/cancelorder', {
      params: { pOrderID: order.orderID }
    });
    if(response.data) {
      history.push('/pipeline')
    }
    setIsProcessing(false);
  }

  const completeOrder = async (approved) => {
    if(!approved){
      setComplete(true);
      return;
    }
    setComplete(false);
    setIsProcessing(true);
    let response = await axios.get('/completeorder', {
      params: { pOrderID: order.orderID }
    });
    if(response.data) {
      history.push('/pipeline')
    }
    setIsProcessing(false);
  }

  const getOccupancyType = (type) => {
    switch(type) {
      case "1":
        return "Primary";
      case "2":
        return "Second Home";
      case "3":
        return "Investment";
      default:
        return "---";
    }
  }

  if (!order) {
    return (
      <Card elevation={0}>
        <CardContent>
          <Skeleton vartiant="rectangular" width="100%" height={58} />
          <Skeleton vartiant="rectangular" width="75%" height={58} />
          <Skeleton vartiant="rectangular" width="75%" height={58} />
          <Skeleton vartiant="rectangular" width="100%" height={58} />
          <Divider />
          <Skeleton vartiant="rectangular" width="75%" height={58} />
          <Skeleton vartiant="rectangular" width="75%" height={58} />
          <Divider />
          <Skeleton vartiant="rectangular" width="100%" height={58} />
          <Skeleton vartiant="rectangular" width="75%" height={58} />
          <Skeleton vartiant="rectangular" width="75%" height={58} />
          <Divider />
          <Skeleton vartiant="rectangular" width="100%" height={58} />
          <Divider />
          <Skeleton vartiant="rectangular" width="100%" height={58} />
        </CardContent>
      </Card>
    );
  }
  if (!order.orderID) {
    return (
      <Card elevation={0}>
        <CardContent>
          <Alert severity="error">Error fetching order information.</Alert>
        </CardContent>
      </Card>
    );
  }
  const isPaid = order.paid !== 'Pending' && order.paid !== 'At Closing' && order.paid !== '---';
  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h6" color={lightBlue[800]}>
          ORDER DETAILS
        </Typography>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ width: '50%' }}>
            <Typography variant="body2" color="grey.600" mt={1}>
              Order Status
            </Typography>
            <Typography variant="body1">
              {order.status}
            </Typography>

            <Typography variant="body2" color="grey.600" mt={1}>
              Last Updated
            </Typography>
            <Typography variant="body1">
              {order.lastUpdated || 'Error'}
            </Typography>
          </Box>
          <Box sx={{ width: '50%' }}>
            <Typography variant="body2" color="grey.600" mt={1}>
              Order ID
            </Typography>
            <Typography variant="body1">
              {order.orderID || 'Error'}
            </Typography>

            {LoginState.userRole === 'Admin' && (<>
              <Typography variant="body2" color="grey.600" mt={1}>
                Payment Status
              </Typography>
              <Typography variant="body1" color={order.paid && order.paid !== 'Pending' ? green[500] : red[500]}>
                {isPaid ? 'Paid' : order.paid} {isPaid && <Receipt orderID={order.orderID} />}
              </Typography>
            </>)}
          </Box>
        </Box>
        
        <Divider sx={{ mt: 2 }} />

        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 3 }}>
          <Box>
            <Typography variant="body2" color="grey.600" mt={1}>
              Request Type
            </Typography>
            <Typography variant="body1">
              {order.orderType}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="grey.600" mt={1}>
              Occupancy Type
            </Typography>
            <Typography variant="body1">
              {getOccupancyType(order.occupancyType)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="grey.600" mt={1}>
              Loan Purpose
            </Typography>
            <Typography variant="body1">
              {order.loanPurpose || 'Error'}
            </Typography>
          </Box>
          {order.occupancyStatus && <Box>
            <Typography variant="body2" color="grey.600" mt={1}>
              Occupancy Status
            </Typography>
            <Typography variant="body1">
              {order.occupancyStatus}
            </Typography>
          </Box>}
          {order.rentAmount && <Box>
            <Typography variant="body2" color="grey.600" mt={1}>
              Rent Amount
            </Typography>
            <Typography variant="body1">
              ${order.rentAmount}
            </Typography>
          </Box>}
        </Box>
        <Divider sx={{ mt: 2 }} />
        <Typography variant="body1" fontWeight="bold" mt={2}>
          Property
        </Typography>
        <Typography variant="body2" color="grey.600" mt={1}>
          Address
        </Typography>
        <Stack direction="row" alignItems="start">
          <Typography variant="body1" style={{whiteSpace: 'pre-wrap'}}>{order.address || 'Error'}</Typography>
          <IconButton
            style={{marginLeft: '15px'}}
            disabled={!order || copied}
            onClick={_handleCopyToClipboard}
          >
            {copied ? <CheckIcon color="success" /> : <ContentCopyIcon />}
          </IconButton>
        </Stack>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ width: '50%' }}>
            <Typography variant="body2" color="grey.600" mt={1}>
              Property Type
            </Typography>
            <Typography variant="body1">
              {order.propertyType
                ? GetPropertyType(order)
                : 'Error'}
            </Typography>
          </Box>
          <Box sx={{ width: '50%' }}>
            <Typography variant="body2" color="grey.600" mt={1}>
              Sales Price / Est. Value
            </Typography>
            <Typography variant="body1">
              {order.salePrice || 'Error'}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mt: 2 }} />
        <Typography variant="body1" fontWeight="bold" mt={2}>
          Borrower
        </Typography>
        <Typography variant="body2" color="grey.600" mt={1}>
          Name
        </Typography>
        <Typography variant="body1">{order.borrower}</Typography>

        {(orderFiles && order.lenderFileID !== "0") && (<>
          <Divider sx={{ mt: 2 }} />
          <Typography variant="body1" fontWeight="bold" mt={2}>Files</Typography>
          <Box sx={{ mt: 2, backgroundColor: 'grey.100', p: 1, borderRadius: 2, display: 'flex', alignItems: 'center' }}>
            {orderFiles.purchaseAgreement ? (
              <IconButton sx={{ mr: 2 }} onClick={() => _downloadFile(orderFiles.purchaseAgreement)}>
                {isDownloading === orderFiles.purchaseAgreement ? <CircularProgress size="1.5rem" /> : <FileDownloadIcon />}
              </IconButton>
            ) : (
              <IconButton sx={{ mr: 2 }} disabled>
                <FileDownloadOffIcon />
              </IconButton>
            )}
            <Typography variant="body2">
              Purchase Agreement
              {!orderFiles.purchaseAgreement && (<span style={{color:'red'}}> (Not Found)</span>)}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, backgroundColor: 'grey.100', p: 1, borderRadius: 2, display: 'flex', alignItems: 'center' }}>
            {orderFiles.title ? (
              <IconButton sx={{ mr: 2 }} onClick={() => _downloadFile(orderFiles.title)}>
                {isDownloading === orderFiles.title ? <CircularProgress size="1.5rem" /> : <FileDownloadIcon />}
              </IconButton>
            ) : (
              <IconButton sx={{ mr: 2 }} disabled>
                <FileDownloadOffIcon />
              </IconButton>
            )}
            <Typography variant="body2">
              Preliminary Title
              {!orderFiles.title && (<span style={{color:'red'}}> (Not Found)</span>)}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, backgroundColor: 'grey.100', p: 1, borderRadius: 2, display: 'flex', alignItems: 'center' }}>
            {orderFiles.addendum ? (
              <IconButton sx={{ mr: 2 }} onClick={() => _downloadFile(orderFiles.addendum)}>
                {isDownloading === orderFiles.addendum ? <CircularProgress size="1.5rem" /> : <FileDownloadIcon />}
              </IconButton>
            ) : (
              <IconButton sx={{ mr: 2 }} disabled>
                <FileDownloadOffIcon />
              </IconButton>
            )}
            <Typography variant="body2">
              Addendum
              {!orderFiles.addendum && (<span style={{color:'red'}}> (Not Found)</span>)}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, backgroundColor: 'grey.100', p: 1, borderRadius: 2, display: 'flex', alignItems: 'center' }}>
            {orderFiles.propertyProfile ? (
              <IconButton sx={{ mr: 2 }} onClick={() => _downloadFile(orderFiles.propertyProfile)}>
                {isDownloading === orderFiles.propertyProfile ? <CircularProgress size="1.5rem" /> : <FileDownloadIcon />}
              </IconButton>
            ) : (
              <IconButton sx={{ mr: 2 }} disabled>
                <FileDownloadOffIcon />
              </IconButton>
            )}
            <Typography variant="body2">
              Property Profile
              {!orderFiles.propertyProfile && (<span style={{color:'red'}}> (Not Found)</span>)}
            </Typography>
          </Box>
          {/* <Box sx={{ mt: 2, backgroundColor: 'grey.100', p: 1, borderRadius: 2, display: 'flex', alignItems: 'center' }}>
            {order.invoiceFile !== "---" ? (
              <IconButton sx={{ mr: 2 }} onClick={() => _downloadInvoice(order.invoiceFile)}>
                {isDownloading === order.invoiceFile ? <CircularProgress size="1.5rem" /> : <FileDownloadIcon />}
              </IconButton>
            ) : (
              <IconButton sx={{ mr: 2 }} disabled>
                <FileDownloadOffIcon />
              </IconButton>
            )}
            <Typography variant="body2">
              Appraiser Invoice
              {order.invoiceFile === "---" && (<span style={{color:'red'}}> (Not Found)</span>)}
            </Typography>
          </Box> */}
        </>)}
        {(LoginState.userRole === 'Admin' && order.status !== "Completed" && order.status !== "Canceled") && (<>
          <Divider sx={{ mt: 2 }} />
          <Typography variant="body1" fontWeight="bold" mt={2}>
            Admin Functions
          </Typography>
          
          <LoadingButton
            sx={{ mt: 2 }}
            loading={isProcessing}
            variant="outlined"
            color="error"
            onClick={() => cancelOrder(false)}
          >
            Cancel Order
          </LoadingButton>

          <LoadingButton
            sx={{ mt: 2, ml: 2 }}
            loading={isProcessing}
            variant="outlined"
            color="error"
            onClick={() => completeOrder(false)}
            >
            Mark Complete
          </LoadingButton>
        </>)}
        <CustomDialog
          open={confirm}
          title='Confirm'
          showConfirm={true}
          confirmMsg='Accept'
          onConfirm={() => cancelOrder(true)}
          showClose={true}
          closeMsg='Cancel'
          onClose={() => setConfirm(false)}>
          {"Are You Sure You Want To Cancel This Appraisal Order?"}
        </CustomDialog>
        <CustomDialog
          open={complete}
          title='Confirm'
          showConfirm={true}
          confirmMsg='Accept'
          onConfirm={() => completeOrder(true)}
          showClose={true}
          closeMsg='Cancel'
          onClose={() => setComplete(false)}>
          {"Are You Sure You Want To Mark This Appraisal Order As Complete?"}
        </CustomDialog>
      </CardContent>
    </Card>
  )
}
