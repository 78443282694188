import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import AppraiserModal from './AppraiserModal';
import { CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { shorten } from '../utils';

export default function ValueCheck(props) {
    const [order, setOrder] = useState({});
    const [open, setOpen] = useState(false);
    const [appraisers, setAppraisers] = useState(null);
    // const [selection, setSelection] = useState([]);
    const [selected, setSelected] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const params = useParams();

    const _getVCOrderDetails = async () => {
        // TODO update endpoint below to return only one object
        const order = await axios.get('../getvaluecheckdata');
        if (order.data) {
            order.data.forEach(row => {
                if (row.requestID.toString() === params.id.toString()) {
                    setOrder({ ...row, requestSent: row.status !== 'Requested' });
                }
            });
        }
        const orderAppraisers = await axios.get('../getvaluecheckappraisers', { params: { pValueCheckID: params.id } });
        if (orderAppraisers.data) {
            setSelected(orderAppraisers.data);
        }
        setOpen(false);
    }

    
    useEffect(() => {
        const _getAppraisers = async () => {
            const response = axios.get('../getappraisers');
    
            // the response.data will contain the values returned
            var resultData = (await response).data;
            if (resultData) {
                const activeAprs = resultData.filter(app => app.active === "True");
                setAppraisers(activeAprs);
            }
        }
        if (!appraisers) _getAppraisers();
        if (!order.requestDate && appraisers) _getVCOrderDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appraisers, order]);

    

    const _sendRequests = async (selection) => {    
        setOpen(false);
        setShowSpinner(true);
        const test = selection.map(el => el.userName).join(",");
        const resultData = await axios.get('../requestValueCheck', { 
            params: { 
            pValueCheckID: params.id, 
            pAppraisers: test
            } 
        });
        // the response.data will contain the values returned
        if(resultData.data.startsWith("Success")) {
            _getVCOrderDetails();
            setShowSpinner(false);
        }
        else {
            console.log("Error", resultData);
        }
    }

    const _selectValue = async (userName) => {
        setShowSpinner(true);
        const response = await axios.get('../selectvaluecheck', { 
            params: { 
            pValueCheckID: params.id,
            pUserName: userName 
            } 
        })
        if(response.data.startsWith("Success")){
            _getVCOrderDetails();
            setShowSpinner(false);
        }
    }

    return (
        <Container>
            <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4 }}>Value Check Details</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Card sx={{ p: 2, mb: 4 }} elevation={0}>
                        <Typography variant="h6">ORDER INFORMATION</Typography>
                        {order.email ? (
                            <>
                            <Typography variant="body1" mt={1}>Request Date:</Typography>
                            <Typography variant="h6">{order.requestDate || 'Error'}</Typography>
                            <Typography variant="body1" mt={1}>Request By (Email):</Typography>
                            <Typography variant="h6">{order.email || 'Error'}</Typography>
                            <Typography variant="body1" mt={1}>Address:</Typography>
                            <Typography variant="h6">{order.addressLine1 ? `${order.addressLine1} ${order.addressLine2} ${order.addressLine3}` : 'Error'}</Typography>
                            <Typography variant="body1" mt={1}>Market Value:</Typography>
                            <Typography variant="h6">{order.marketValue || 'Error'}</Typography>
                        </>
                        ) : (
                            <Stack justifyContent="center" alignItems="center" p={4}>
                                <CircularProgress />
                            </Stack>
                        )}
                    </Card>
                    <Card sx={{ p: 2 }} elevation={0}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h5" mb={2}>Appraisers</Typography>
                            <Button type="text" sx={{ px: 2 }} disabled={!appraisers} onClick={() => setOpen(true)}>Assign</Button>
                        </Box>
                        <TableContainer>
                          {showSpinner ? (
                            <Table aria-label="VC appraisers list">
                              <Stack justifyContent="center" alignItems="center" p={4}>
                                <CircularProgress />
                              </Stack>
                            </Table>
                          ) : (
                            <Table aria-label="VC appraisers list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Appraiser</TableCell>
                                        {/* <TableCell>Company</TableCell> */}
                                        <TableCell>Email</TableCell>
                                        <TableCell>Estimation</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {selected 
                                    ? selected.length > 0 ? selected.map((appraiser) => {
                                        const estimation = (appraiser.responded === "True")
                                            ? appraiser.value !== "---" 
                                                ? shorten(appraiser.value)
                                                : `${shorten(appraiser.rangeMin)} - ${shorten(appraiser.rangeMax)}`
                                            : null
                                        return (
                                            <TableRow key={appraiser.userName} sx={{ '&:last-child td, &:last-child th': { border: 0 }}}>
                                                <TableCell>{appraiser.selected === "True" && <CheckCircleIcon color="success" />}</TableCell>
                                                <TableCell>{appraiser.name}</TableCell>
                                                {/* <TableCell>{appraiser.company}</TableCell> */}
                                                <TableCell>{appraiser.email}</TableCell>
                                                <TableCell>{estimation || '-'}</TableCell>
                                                <TableCell>{estimation && <Button disabled={appraiser.selected === "True"} onClick={() => _selectValue(appraiser.userName)}>Select</Button>}</TableCell>
                                            </TableRow>
                                        )}) : <TableRow>
                                            <TableCell colSpan="5" align="center">
                                                <Typography color="grey.500">No appraisers selected</Typography>
                                            </TableCell>
                                        </TableRow> 
                                    : <TableRow>
                                        <TableCell colSpan="5" align="center">
                                            <CircularProgress/>
                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{ p: 2 }} elevation={0}>
                        <Typography variant="h5">Timeline</Typography>
                        <Typography color="error">V2 feature</Typography>
                        <Timeline position="left">
                            <TimelineItem>
                                <TimelineOppositeContent color="text.secondary">
                                    {order.requestDate}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="primary" variant="outlined" />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ lineHeight: '1.25rem' }}>Ordered</TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent color="text.secondary">
                                    {order.responded || 'Not completed'}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="primary" variant="outlined" />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ lineHeight: '1.25rem' }}>Assigned</TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent color="text.secondary">
                                    {order.returned || 'Not completed'}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="primary" variant="outlined" />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ lineHeight: '1.25rem' }}>Adam Appraiser</TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent color="text.secondary">
                                    {order.returned || 'Not completed'}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="primary" variant="outlined" />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ lineHeight: '1.25rem' }}>Alex Appraiser</TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent color="text.secondary">
                                    {order.returned || 'Not completed'}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="primary" variant="outlined" />
                                </TimelineSeparator>
                                <TimelineContent sx={{ lineHeight: '1.25rem' }}>Selection</TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </Card>
                </Grid>
            </Grid>
            <AppraiserModal
                appraisers={appraisers}
                selected={selected}
                open={open}
                onClose={() => setOpen(false)}
                onSubmit={_sendRequests}
            />
        </Container>
    );
}