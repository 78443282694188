import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from '@mui/material/Stack';
import LoginState from './LoginState';
import CollapsibleCard from './CollapsibleCard';
import Radio from '@mui/material/Radio';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CircularProgress } from '@mui/material';
import Receipt from './Receipt.js';
import moment from 'moment';
import PaymentIcon from '@mui/icons-material/Payment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

export default function OrderPayment({ order, afterCompleted }) {

  const [paymentDetails, setPaymentDetails] = useState();
  const [fee, setFee] = useState(null);
  const [originalFee, setOriginalFee] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (!paymentDetails) {
      const _getPaymentDetails = async () => {
        let response = await axios.get('/getpaymentdetails', {
          params: {
            pOrderID: order.orderID,
            pUserName: LoginState.userName
          }
        });
        if (response.data) {
          setPaymentDetails(response.data);
          if(response.data.invoiceDate !== "---")
            setInvoiceDate(response.data.invoiceDate);
          if(response.data.paymentLinkFee) {
            setFee(response.data.paymentLinkFee);
            setOriginalFee(response.data.paymentLinkFee);
          }
        } else {
          setError('Something went wrong fetching payment data')
        }
      }

      _getPaymentDetails();
    }
  }, [paymentDetails, order]);

  const updateInvoiceDate = async (formattedDate) => {
    await axios.get('/setinvoicedate', {
      params: {
        pOrderID: order.orderID,
        pDateValue: formattedDate
      }
    });
  }

  const captureInput = e => {
    var feeInput = e.target.value;
    setFee(feeInput);

    if (isNaN(feeInput))
      setError('Fee is not a valid number');
    else if (parseFloat(feeInput) <= 0)
      setError('Fee needs to be a positive number');
    else if (parseFloat(feeInput) >= 10000)
      setError('Fee is excessively high');
    else
      setError('');
  };

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  window.markPaid = function () {
    // the payment success page will call us to refresh
    // the page and show the updated payment status
    window.location.reload();
  }

  const markPaymentReceived = async () => {
    await axios.get('/markpaymentreceived', {
      params: { pOrderID: order.orderID }
    });
    window.markPaid();
  }

  const submitPaymentRequest = async () => {
    // call code that will send the stripe payment request email for the set fee amount.
    setLoading(true);
    const response = axios.get('/paymentlink', {
      params: {
        pFee: !fee ? paymentDetails.fee : fee,
        pOrderID: order.orderID,
        pUserName: LoginState.userName,
      }
    });
    var resultData = (await response).data;
    if (resultData) {
      // if I don't delay, sometimes the link isn't ready yet and we get an error
      await delay(3000);
      window.open(resultData);
    }
    setLoading(false);
  }

  const setPayNow = async () => {
    const response = axios.get('/setPaymentType', {
      params: {
        pOrderID: order.orderID,
        pFee: !fee ? paymentDetails.fee : fee,
        pPaymentType: "Pay Now",
      }
    });
    var resultData = (await response).data;
    if (resultData) { afterCompleted(); }
  }

  const updateFee = async () => {
    const response = axios.get('/setFee', {
      params: {
        pOrderID: order.orderID,
        pFee: fee,
      }
    });
    var resultData = (await response).data;
    if (resultData) { setOriginalFee(fee); }
  }

  const setPayAtClosing = async () => {
    const response = axios.get('/setPaymentType', {
      params: {
        pOrderID: order.orderID,
        pFee: !fee ? paymentDetails.fee : fee,
        pPaymentType: "Pay At Closing",
      }
    });
    var resultData = (await response).data;
    if (resultData) { afterCompleted(); }
  }

  const downloadAuthForm = async () => {
    var fileName = 'CCAuthForm.pdf';
    var response = axios.get('/getfiledata', { params: { pOrderID: order.orderID, pFileName: fileName } });
    var resultData = null;
    try {
      resultData = (await response).data;
      
      var binary = atob(resultData);
      var array = [];
      for (var j=0; j<binary.length; j++) {
        array.push(binary.charCodeAt(j));
      }
      var file = new Blob([new Uint8Array(array)]);

      // this code below will download the file we selected
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    } 
    catch { setError('Failed to Download!'); }
  }

  if (!paymentDetails) {
    return <Stack justifyContent="center" alignItems="center" p={4}>
      <CircularProgress />
    </Stack>
  }
  else if ((paymentDetails.paid !== 'Paid') && LoginState.userRole === 'Admin') {
    return (
      <CollapsibleCard title="payment">
        {(paymentDetails.ccAuthForm && LoginState.userRole === 'Admin') && (
          <span style={{ cursor:'pointer', position:'relative', top:'-15px' }} onClick={ downloadAuthForm }>
            <PaymentIcon style={{marginRight:'10px'}} /> 
            <span style={{ position:'relative', top:'-6px', color:'blue', fontSize:'12px' }}><u>Uploaded CC Auth Form</u></span>
          </span>
        )}
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={'Invoice Date'}
              value={moment(invoiceDate)}
              onChange={val => {
                const formatted = moment(val).format('MM/DD/YYYY');
                setInvoiceDate(formatted);
                updateInvoiceDate(formatted);
              }}
              renderInput={(params) => <TextField {...params} size="small"/>}
            />
          </LocalizationProvider>
          <Typography variant="body1" style={{ textDecoration: ((!fee || error) ? 'none' : 'line-through') }}>
            Default Fee:<br/>{paymentDetails.fee || "---"}
          </Typography>
          <TextField
            size="small"
            label="Custom Fee"
            type="number"
            name="fee"
            value={fee}
            onChange={captureInput}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              endAdornment: <InputAdornment position="end"></InputAdornment>
            }}
          />
          <Button variant="contained" size="small" style={{marginRight:'5px'}}
            disabled={originalFee === fee}
            onClick={()=>updateFee()}>
            Update
          </Button>
        </Stack>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <Stack direction="row" spacing={2} p={2} justifyContent="center" alignItems="center" >
          <Radio
            checked={order.payOption === "Pay Now"}
            /* onChange={setPayNow} */
            onChange={() => setShowConfirmModal(true)}
            value={"Now"}
            name="radio-buttons"
          />Pay Now
          <Radio
            checked={order.payOption === "Pay At Closing"}
            /* onChange={setPayAtClosing} */
            onChange={() => setShowConfirmModal(true)}
            value={"Closing"}
            name="radio-buttons"
          /> Pay At Closing
        </Stack>
        {paymentDetails.intentToProceedDate === "---" && (
          <Alert severity="warning" sx={{mb: 2}}>Payments Disabled Until Loan File's "Intent to Proceed" Date Is Set</Alert>
        )}
        {order.payOption === "Pay At Closing" ? (
            <Stack direction="row" justifyContent="center" p={2}>
              <Receipt orderID={order.orderID} fee={originalFee} />
              {paymentDetails.paymentDate !== "---" ? (
                <Typography variant="button" style={{position:'relative', top:'6px', left:'10px'}}>Payment Received</Typography>
              ) : (
                <Button
                  disabled={(paymentDetails.fee === "---" && !fee) || error || loading || paymentDetails.intentToProceedDate === "---"}
                  onClick={markPaymentReceived}
                >Mark as Paid</Button>
              )}
            </Stack>
          ) : (
          <Button
            disabled={(paymentDetails.fee === "---" && !fee) || error || loading ||
              paymentDetails.intentToProceedDate === "---"}
            variant="contained"
            onClick={submitPaymentRequest}
          >Open Payment Page</Button>
        )}
        {/* CONFIRMATION MODAL */}
        <Dialog
          open={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Payment Already Made Or Payment Option Set
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm that you intend to change the payment method.<br/>
              (Any payment already charged will have to be manually refunded)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConfirmModal(false)} >Cancel</Button>
            <Button onClick={() => {
              order.payOption === "Pay Now" ? setPayAtClosing() : setPayNow();
              setShowConfirmModal(false);
            }} autoFocus>Change</Button>
          </DialogActions>
        </Dialog>
      </CollapsibleCard>
    )
  }
  const paid = order.paid !== "Pending" && order.paid !== "At Closing" && order.paid !== "---";
  return (
    <CollapsibleCard title="payment">
      {error
        ? <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
        : <Alert severity={paymentDetails.paid && paymentDetails.paid !== "Pending" ? 'success' : 'warning'} sx={{ mb: 2 }} action={paid && <Receipt orderID={order.orderID} fee={originalFee} />}>
            <strong>{paid ? "Paid" : "Not Paid"}</strong> {(paid && paymentDetails.paymentDate !== "---") && paymentDetails.paymentDate}
          </Alert>}
    </CollapsibleCard>
  )
}