import { useState } from 'react';

export const useFormFields = initialState => {
  const [fields, setValues] = useState(initialState);
  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
};

//
export const capitalize = str => {
  if (!str) return;
  const regex = /(\b[a-z](?!\s))/g;
  return str.toLowerCase().replace(regex, x => x.toUpperCase());
};

// FORMAT MONEY
/**
 * Format number to currency
 */
export const formatCurrency = (amount, edit) => {
  // check to see that it's a valid number
  if (Number(amount) === Number.Nan) {
    return edit ? '' : '-';
  }
  const number = typeof amount === 'number' ? amount : Number(amount);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(number);
};

// removes middle name from full name
export const getFormattedName = name => {
  if (!name) return 'loading...';
  const temp = name
    .toLowerCase()
    .split(' ')
    .filter(el => el !== '');
  return temp.length > 2
    ? `${temp[0]} ${temp[temp.length - 1]}`
    : name.toLowerCase();
};

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '---';
};

export const checkRouteForString = (term, pathname) => pathname.includes(term);

export const base64ArrayBuffer = (arrayBuffer) => {
  var base64    = '';
  var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  var bytes         = new Uint8Array(arrayBuffer);
  var byteLength    = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength    = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048)   >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032)     >>  6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63;               // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder === 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3)   << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008)  >>  4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15)    <<  2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }
  
  return base64;
};

export const getPropertyType = typeNum => {
  switch (typeNum.toString()) {
    case '1':
    case '2':
      return 'SFR';
    case '3':
    case '4':
      return 'Condo (Attached)';
    case '5':
      return 'Condo (Detached)';
    case '6':
      return 'PUD';
    case '7':
      return 'Cooperative';
    case '8':
    case '9':
    case '10':
    case '11':
    case '12':
      return 'Manufactured';
    case '13':
      return 'Vacant Land';
    default:
      return '---';
  }
};