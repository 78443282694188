import React, { Component } from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LoginState from './LoginState';

export default class AppraisalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'CA',
      requestType: '1',
      loanPurpose: '1',
      propertyType: '1',
      occupancyType: '1',
      marketValue: '',
      name: '',
      email: '',
      feeAmount: '',
      errors: {},
      submitted: false,
      submitting: false,
    };
  }

  captureInput = (e) => {
    this.setState({
      [e.target.name]: (e.target.type === 'checkbox') ? e.target.checked : e.target.value,
      errors: { ...this.state.errors, [`${e.target.name}Error`]: null },
    });

    // update fee if certain fields were updated
    if (
      e.target.name === 'requestType' ||
      e.target.name === 'occupancyType' ||
      e.target.name === 'propertyType' ||
      e.target.name === 'marketValue'
    ) {
      const requestType = parseInt(
        e.target.name === 'requestType'
          ? e.target.value
          : this.state.requestType
      );
      const occupancyType = parseInt(
        e.target.name === 'occupancyType'
          ? e.target.value
          : this.state.occupancyType
      );
      const propertyType = parseInt(
        e.target.name === 'propertyType'
          ? e.target.value
          : this.state.propertyType
      );
      const marketValue =
        e.target.name === 'marketValue'
          ? e.target.value
          : this.state.marketValue;
      var price = marketValue;
      if (price) {
        price = parseInt(price.replace('$', '').replaceAll(',', ''));
      }
      var feeAmount = 0;
      try {
        const propType = parseInt(propertyType);
        // next find fees
        switch (Number(requestType)) {
          case 1: // New Order
            if (occupancyType < 3) {
              // first or second home
              if (propType < 4) {
                // SFR, Condo, PUD
                if (price <= 1000000) feeAmount += 550;
                else if (price <= 2000000) feeAmount += 700;
                else if (price <= 3000000) feeAmount += 1000;
                else if (price <= 4000000) feeAmount += 1300;
                else feeAmount = 'Not Available';
              } else if (propType === 5) {
                // 2 Unit
                if (price <= 1000000) feeAmount += 750;
                else if (price <= 2000000) feeAmount += 900;
                else if (price <= 3000000) feeAmount += 1050;
                else if (price <= 4000000) feeAmount += 1400;
                else feeAmount = 'Not Available';
              } else if (propType > 5) {
                // 3-4 Unit
                if (price <= 1000000) feeAmount += 800;
                else if (price <= 2000000) feeAmount += 950;
                else if (price <= 3000000) feeAmount += 1100;
                else if (price <= 4000000) feeAmount += 1400;
                else feeAmount = 'Not Available';
              }
            } else {
              // investment property
              if (propType < 4) {
                // SFR, Condo, PUD
                if (price <= 1000000) feeAmount += 650;
                else if (price <= 2000000) feeAmount += 850;
                else if (price <= 3000000) feeAmount += 1200;
                else if (price <= 4000000) feeAmount += 1400;
                else feeAmount = 'Not Available';
              } else if (propType === 5) {
                // 2 Unit
                if (price <= 1000000) feeAmount += 750;
                else if (price <= 2000000) feeAmount += 900;
                else if (price <= 3000000) feeAmount += 1300;
                else if (price <= 4000000) feeAmount += 1500;
                else feeAmount = 'Not Available';
              } else if (propType > 5) {
                // 3-4 Unit
                if (price <= 1000000) feeAmount += 800;
                else if (price <= 2000000) feeAmount += 950;
                else if (price <= 3000000) feeAmount += 1300;
                else if (price <= 4000000) feeAmount += 1500;
                else feeAmount = 'Not Available';
              }
            }
            break;
          case 2: // Form 1004D/442 Re-Inspection
            feeAmount += 150;
            break;
          case 3: // Form 1004D/442 Re-Certification
            feeAmount += 150;
            break;
          case 4: // Form 2055 Exterior Only
            feeAmount += 350;
            break;
          case 5: // Desk Review
            propertyType < 4 ? (feeAmount += 275) : (feeAmount += 425);
            break;
          case 6: // Field Review
            propertyType < 4 ? (feeAmount += 350) : (feeAmount += 450);
            break;
          default:
            break;
        }
      } catch {
        feeAmount = '';
      }

      this.setState({
        feeAmount:
          feeAmount === ''
            ? ''
            : feeAmount === 'Not Available'
            ? 'Not Available'
            : '$' +
              feeAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
      });
    }
  };

  resetFields() {
    this.setState({
      address1: '',
      address2: '',
      city: '',
      state: 'CA',
      requestType: '1',
      loanPurpose: '1',
      propertyType: '1',
      occupancyType: '1',
      zip: '',
      marketValue: '',
      contactName: '',
      contactRole: '',
      contactPhone: '',
      contactEmail: '',
      orderNotes: '',
      name: '',
      email: '',
      feeAmount: '',
      errors: {},
    });
  }

  async submitRequest() {
    const {
      contactEmail,
      address1,
      address2,
      city,
      state,
      zip,
      email,
      marketValue,
      newConstruction
    } = this.state;
    const errors = {};
    const USPS_USER = '237OUROI6841';
    const uspsUrl = `https://production.shippingapis.com/ShippingAPI.dll?API=Verify&XML=<AddressValidateRequest USERID="${USPS_USER}"><Address><Address1>${address1}</Address1><Address2>${address2}</Address2><City>${city}</City><State>${state}</State><Zip5>${zip}</Zip5><Zip4></Zip4></Address></AddressValidateRequest>`;
    const requiredFields = [
      'requestType',
      'propertyType',
      'occupancyType',
      'contactName',
      'contactRole',
      'contactPhone',
      'contactEmail',
      'address1',
      'city',
      'state',
      'zip',
      'marketValue',
      'name',
      'email',
    ];
    const emailValidate =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const zipValidate = /^[0-9]{5}(?:-[0-9]{4})?$/;

    this.setState({ submitting: true });

    // required fields validation
    requiredFields.forEach((field) => {
      if (!this.state[field] || this.state[field].length < 1) {
        if (field === 'marketValue') {
          errors.marketValueError = 'Required field';
        } else if (field === 'address1') {
          errors.address1Error = 'Required field';
        } else {
          errors[`${field}Error`] = 'Required field';
        }
      }
    });
    // zipcode format validation
    if (zip && !zipValidate.test(Number(zip))) {
      errors.zipError = 'Invalid zipcode';
    }
    // email format validation
    if (email && !emailValidate.test(String(email).toLowerCase())) {
      errors.emailError = 'Invalid email address';
    }
    if (
      contactEmail &&
      !emailValidate.test(String(contactEmail).toLowerCase())
    ) {
      errors.contactEmailError = 'Invalid email address';
    }
    // address validation with USPS api
    if (address1 && city && state && zip && !newConstruction) {
      await axios
        .get(uspsUrl, { headers: { 'Content-Type': 'text/xml' } })
        .then((res) => {
          if (res.data) {
            const response = new window.DOMParser().parseFromString(
              res.data,
              'text/xml'
            );
            if (response.getElementsByTagName('Error')[0]) {
              errors.address1Error = 'Address not valid';
            }
            if (
              response.getElementsByTagName('ReturnText')[0] &&
              response
                .getElementsByTagName('ReturnText')[0]
                .innerHTML.includes('more information is needed')
            ) {
              errors.address2Error = 'this field is required for this address, it must mach USPS records';
            }
          }
        })
        .catch((e) => {
          this.setState({ submitting: false });
          console.log(e, '*** Error validating address ***');
        });
      // if any errors render them in page
      if (Object.values(errors).length > 0) {
        this.setState({ errors, submitting: false });
      } else {
        axios
          .get('../submitAppraisalOrder', {
            params: {
              pUserInfo: this.state.name + ' (' + this.state.email + ')',
              pUserName: LoginState.userName,
              pOrderType: this.state.requestType,
              pAddress:
                address1 +
                (address2 ? '\n' + address2 : '') +
                '\n' +
                city +
                ', ' +
                state +
                ' ' +
                zip,
              pFee: this.state.feeAmount,
              pName: this.state.contactName,
              pPhone: this.state.contactPhone,
              pEmail: this.state.contactEmail,
              pRole: this.state.contactRole,
              pOrderNotes: this.state.orderNotes,
              pLoanPurpose: this.state.loanPurpose,
              pPropertyType: this.state.propertyType,
              pOccupancyType: this.state.occupancyType,
              pMarketValue: marketValue,
              pBorrowerName: this.state.name,
            },
          })
          .then((res) => {
            this.setState({ submitted: true, submitting: false });
            this.resetFields();
          })
          .catch((e) => {
            this.setState({ submitting: false });
            console.log(e, '*** Error sending request ***');
          });
      }
    }
    this.setState({ errors, submitting: false });
  }

  render() {
    const { errors = {}} = this.state;
    return (
      <Container>
        <Box>
          <Typography
            variant="h5"
            sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}
          >
            Order New Appraisal
          </Typography>
          <Grid spacing={2} sx={{ maxWidth: '640px', mx: 'auto' }}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" mb={1}>
                Order Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', mb: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.nameError ? true : false}
                  helperText={errors.nameError}
                  sx={{ mr: 2, flex: 1 }}
                  variant="outlined"
                  label="Requestor's Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.captureInput}
                />
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.emailError ? true : false}
                  helperText={errors.emailError}
                  sx={{ flex: 1 }}
                  variant="outlined"
                  label="Requestor's Email"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.captureInput}
                />
              </Box>
              <Box sx={{ display: 'flex', mb: 2 }}>
                <FormControl fullWidth size="small" sx={{ mr: 2 }}>
                  <InputLabel id="requestType-select-label">
                    Request Type
                  </InputLabel>
                  <Select
                    required
                    labelId="requestType-select-label"
                    name="requestType"
                    value={this.state.requestType}
                    label="Request Type"
                    onChange={this.captureInput}
                    MenuProps={{ sx: { maxHeight: '500px' } }}
                    defaultValue={'1'}
                  >
                    {/* {Object.entries(PRICING_OPTIONS.requestType).map(
                      ([short, long]) => (
                        <MenuItem key={long} value={short}>
                          {long}
                        </MenuItem>
                      )
                    )} */}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                  <InputLabel id="requestType-select-label">
                    Loan Purpose
                  </InputLabel>
                  <Select
                    required
                    labelId="loanPurpose-select-label"
                    name="loanPurpose"
                    value={this.state.loanPurpose}
                    label="Loan Purpose"
                    onChange={this.captureInput}
                    defaultValue={'1'}
                  >
                    {/* {Object.entries(PRICING_OPTIONS.loanPurpose).map(
                      ([short, long]) => (
                        <MenuItem key={long} value={short}>
                          {long}
                        </MenuItem>
                      )
                    )} */}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', mb: 2 }}>
                <FormControl fullWidth size="small" sx={{ mr: 2 }}>
                  <InputLabel id="propertyType-select-label">
                    Property Type
                  </InputLabel>
                  <Select
                    required
                    labelId="propertyType-select-label"
                    name="propertyType"
                    value={this.state.propertyType}
                    label="Property Type"
                    onChange={this.captureInput}
                    MenuProps={{ sx: { maxHeight: '500px' } }}
                    defaultValue={'1'}
                  >
                    {/* {Object.entries(PRICING_OPTIONS.propertyType).map(
                      ([short, long]) => (
                        <MenuItem key={long} value={short}>
                          {long}
                        </MenuItem>
                      )
                    )} */}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                  <InputLabel id="occupancyType-select-label">
                    Occupancy Type
                  </InputLabel>
                  <Select
                    required
                    labelId="occupancyType-select-label"
                    name="occupancyType"
                    value={this.state.occupancyType}
                    label="Occupancy Type"
                    onChange={this.captureInput}
                    MenuProps={{ sx: { maxHeight: '500px' } }}
                    defaultValue={'1'}
                  >
                    {/* {Object.entries(PRICING_OPTIONS.occupancyType).map(
                      ([short, long]) => (
                        <MenuItem key={long} value={short}>
                          {long}
                        </MenuItem>
                      )
                    )} */}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mr: 2, mb: 2 }}>
              <TextField
                size="small"
                required
                error={errors.marketValueError ? true : false}
                helperText={errors.marketValueError}
                sx={{ width: '50%', flex: 1 }}
                variant="outlined"
                label="Market Value"
                name="marketValue"
                value={this.state.marketValue}
                onChange={this.captureInput}
              />
            </Grid>
            <Grid item xs={12}>
              {this.state.feeAmount && (
                <Alert severity="info" sx={{ mb: 2, width: '100%' }}>
                  Estimated Appraisal Fee: {this.state.feeAmount}
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" mb={1}>
                Contact details
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.contactNameError ? true : false}
                  helperText={errors.contactNameError}
                  sx={{ mb: 2, mr: 2 }}
                  variant="outlined"
                  label="Contact Name"
                  name="contactName"
                  value={this.state.contactName}
                  onChange={this.captureInput}
                />
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.contactRoleError ? true : false}
                  helperText={errors.contactRoleError}
                  sx={{ mb: 2 }}
                  variant="outlined"
                  label="Contact Role"
                  name="contactRole"
                  value={this.state.contactRole}
                  onChange={this.captureInput}
                />
              </Box>
              <Box sx={{ display: 'flex', mb: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.contactPhoneError ? true : false}
                  helperText={errors.contactPhoneError}
                  sx={{ mr: 2 }}
                  variant="outlined"
                  label="Contact Phone"
                  name="contactPhone"
                  value={this.state.contactPhone}
                  onChange={this.captureInput}
                />
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.contactEmailError ? true : false}
                  helperText={errors.contactEmailError}
                  variant="outlined"
                  label="Contact Email"
                  name="contactEmail"
                  value={this.state.contactEmail}
                  onChange={this.captureInput}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h6" mb={1}>
                Address details
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                required
                error={errors.address1Error ? true : false}
                helperText={errors.address1Error}
                sx={{ mb: 2 }}
                variant="outlined"
                name="address1"
                label="Address Line 1"
                value={this.state.address1}
                onChange={this.captureInput}
              />
              <TextField
                fullWidth
                size="small"
                error={errors.address2Error ? true : false}
                helperText={errors.address2Error}
                variant="outlined"
                name="address2"
                label="Address Line 2"
                value={this.state.address2}
                onChange={this.captureInput}
              />
              <FormControlLabel 
                control={<Checkbox />}
                label="New Construction"
                name="newConstruction"
                value={this.state.newConstruction}
                onChange={this.captureInput} 
                sx={{ my: 1 }}
              />
              <Box sx={{ display: 'flex', mb: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.cityError ? true : false}
                  helperText={errors.cityError}
                  sx={{ mr: 2, flex: 2 }}
                  variant="outlined"
                  name="city"
                  label="City"
                  value={this.state.city}
                  onChange={this.captureInput}
                />
                <FormControl fullWidth size="small" sx={{ flex: 1, mr: 2 }}>
                  <InputLabel id="state-select-label">State</InputLabel>
                  <Select
                    required
                    error={errors.stateError ? true : false}
                    labelId="state-select-label"
                    id="state-select"
                    name="state"
                    value={this.state.state}
                    label="State"
                    onChange={this.captureInput}
                    MenuProps={{ sx: { maxHeight: '500px' } }}
                    defaultValue={'CA'}
                  >
                    {/* {Object.entries(states).map(([short, long]) => (
                      <MenuItem key={long} value={short}>
                        {long}
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.zipError ? true : false}
                  helperText={errors.zipError}
                  sx={{ flex: 1 }}
                  variant="outlined"
                  label="Zip Code"
                  name="zip"
                  value={this.state.zip}
                  onChange={this.captureInput}
                />
              </Box>
              <TextField
                fullWidth
                size="small"
                multiline
                maxRow={4}
                variant="outlined"
                name="orderNotes"
                label="Order Notes (Optional)"
                value={this.state.orderNotes}
                onChange={this.captureInput}
              />
            </Grid>
            <Grid item xs={12} sm={10} sx={{ textAlign: 'right', mt: 2 }}>
              <Button
                size="large"
                variant="outlined"
                sx={{ mr: 2, flex: 1 }}
                disabled={this.state.submitting}
                onClick={() => this.resetFields()}
              >
                Reset
              </Button>
              <Button
                size="large"
                variant="contained"
                sx={{ flex: 1 }}
                disabled={this.state.submitting}
                onClick={() => this.submitRequest()}
              >
                submit
                {/* {this.state.submitting ? 'Submitting...' : 'Submit'} */}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}
