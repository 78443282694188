import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { base64ArrayBuffer } from '../helpers/utils';
import CustomLoader from './CustomLoader';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import LoginState from './LoginState';
import CollapsibleCard from './CollapsibleCard';
import CircularProgress from '@mui/material/CircularProgress';
import ReportList from './ReportList';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

const MAX_FILE_SIZE = 25000000;

export default class OrderReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listItems: [],
      newFiles: [],
      isSubmitting: false,
      alert: '',
      comment: '',
    };

    this._getUploadData();
  }

  async _getUploadData() {
    let response = await axios.get('/getfiles', {
      params: { pOrderID: this.props.order.orderID },
    });
    if (response.data) {
      const all = response.data.comments.concat(response.data.files);
      const sorted = all.sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );
      this.setState({listItems: sorted});
    }
  }

  _InputContent() {
    if (this.state.isSubmitting) return <CustomLoader>Submitting File(s)...</CustomLoader>
    return (
      <Box sx={{ textAlign: 'center' }}>
        <CloudUploadIcon color="disabled" sx={{ fontSize: 40 }} />
        <Typography variant="subtitle1" sx={{ fontWeight: 'medium', textAlign: 'center' }}>
          Drag &amp; Drop or <u>browse.</u> (PDF and XML Files)
        </Typography>
      </Box>
    );
  }

  _combineFiles(file1, file2) {
    return file1 + '$$$$$' + file2;
  };

  async _uploadToServer() {
    const { newFiles, comment, listItems } = this.state;
    const { order } = this.props;

    if(comment.length === 0 && newFiles.length === 0) { return; }
    // skip if no files uploaded
    if (newFiles.length === 0 && comment.length > 0) {
      // submit comment
      this.setState({isSubmitting: true});
      let response = await axios.get('/submitAppraiserComment', {
        params: {
          pOrderID: order.orderID,
          pUserName: LoginState.userName,
          pComment: comment,
        },
      });
      if (response.data) {
        let list = listItems;
        list.push(response.data);
        this.setState({listItems: list, comment: ''});
      }
      this.setState({isSubmitting: false});
      return;
    }

    // check to see that combined file size is not over limit
    let totalSize = 0;
    for (let i = 0; i < newFiles.length; i++) {
      totalSize += newFiles[i].size;
    }
    if (totalSize >= MAX_FILE_SIZE) {
      this.setState({alert: 'Combined File Size Exceeds 25Mb!'})
      return;
    }
    this.setState({isSubmitting: true});

    // first combine all the files into one.
    let combinedFiles = null;
    for (let j = 0; j < newFiles.length; j++) {
      if (j === 0) combinedFiles = newFiles[j].fileUIntArr;
      else
        combinedFiles = this._combineFiles(combinedFiles, newFiles[j].fileUIntArr);
    }
    // also combine the file names
    let combinedFileNames = null;
    for (let k = 0; k < newFiles.length; k++) {
      if (k === 0) combinedFileNames = newFiles[k].fileName;
      else
        combinedFileNames = this._combineFiles(
          combinedFileNames,
          newFiles[k].fileName
        );
    }

    // then submit to server
    var params = {
      orderID: order.orderID,
      fileNames: combinedFileNames,
      fileContent: combinedFiles,
      userName: LoginState.userName,
      comments: comment,
    };

    fetch('/fileupload', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.text())
    .then((data) => {
      // error check
      if (!data.startsWith('Success')) {
        // show error message
        this.setState({isSubmitting: false, alert: 'Error Uploading File(s)!'});
      } else {
        // notify for successful upload
        this.setState({isSubmitting: false, comment: '', newFiles: [], alert: 'File(s) Successfully Uploaded!'});
        this._getUploadData();
      }
    });
  };

  async _downloadFile(orderID, fileName) {
    var response = axios.get('/getfiledata', {
      params: { pOrderID: orderID, pFileName: fileName },
    });
    var resultData = null;
    try { resultData = (await response).data; } 
    catch { this.setState({alert: 'Error Downloading File!'}); }

    var dlFN = fileName;
    if (fileName[fileName.length - 1] === ')') {
      dlFN = '';
      var splitFN = fileName.split(' ');
      for (var i = 0; i < splitFN.length - 1; i++) {
        dlFN = dlFN + splitFN[i];
      }
    }

    var binary = atob(resultData);
    var array = [];
    for (var j = 0; j < binary.length; j++) {
      array.push(binary.charCodeAt(j));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = dlFN;
    a.click();
    URL.revokeObjectURL(url);
  }

  handleChangeStatus({ file, remove }, status) {
    if (file && file.size > MAX_FILE_SIZE) {
      this.setState({ errorMessage: 'File Size Cannot Exceed 25 Mb!' });
    }
    if (status === 'preparing') {
      this.setState({ isSubmitting: true });
    } else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;
      reader.readAsArrayBuffer(file);
      reader.onload = e => {
        fileToUpload = e.target.result;
      };
      reader.onloadend = () => {
        var uintarr = base64ArrayBuffer(fileToUpload);
        this.state.newFiles.push({
          fileUIntArr: uintarr,
          fileName: file.name,
          conditionID: this.state.conditionID,
          size: file.size,
        });
        remove();
        setTimeout(() => {
          this.setState({ isSubmitting: false });
        }, 2000);
      };
    }
  }

  _removeFile(fileName) {
    this.setState({newFiles: this.state.newFiles.filter((file) => file.fileName !== fileName)});
  };

  async _deleteFile(orderID, fileName) {
    const { listItems } = this.state;
    let response = await axios.get('/deleteReportFile', {
      params: { pOrderID: orderID, pFileName: fileName },
    });
    if (response.data) {
      this.setState({alert: `Successfully Deleted File ${fileName}!`});
      for(var i=0; i<listItems.length; i++) {
        if(listItems[i] && listItems[i].name && listItems[i].name === fileName) {
          listItems.splice(i, 1);
          break;
        }
      }
      this.setState({listItems: listItems}); // refresh list display
    }
    else {
      this.setState({alert: `Error Deleting File ${fileName}!`}); 
    }
  }

  _handleInputChange = (e) => {
    e.preventDefault();
    this.setState({comment: e.target.value});
  }

  render() {
    const { alert, isSubmitting, newFiles, listItems, comment } = this.state;

    return (
      <CollapsibleCard title="Report">
        {alert && (
          <Alert severity="info" sx={{ mb: 2 }}>
            {alert}
          </Alert>
        )}
        <Typography variant="subtitle1" mb={2}>Upload</Typography>
        <Dropzone
            maxFiles="10"
            maxSizeBytes="25000000" // 25 Mb
            accept=".pdf,.xml"
            onChangeStatus={this.handleChangeStatus.bind(this)}
            inputContent={this._InputContent.bind(this)}
            styles={{
              dropzone: {
                overflow: 'hidden',
                border: '2px dashed lightgray',
                borderRadius: '0.25rem',
              },
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (_, extra) => extra.reject ? { color: 'red' } : {}
            }}
        />
        
        {newFiles.length > 0 && (
          newFiles.map(file => (
            <Box sx={{ backgroundColor: 'grey.100', p:1, pl: 2 , borderRadius: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Typography variant="body1">
                {file.fileName} <Chip size="small" variant="outlined" label="New" color="success" />
              </Typography>
              <IconButton sx={{ m: 0}}>
                <DeleteIcon onClick={() => this._removeFile(file.fileName)} />
              </IconButton>
            </Box>
          ))
        )}
        <Typography variant="subtitle1" mt={2}>History</Typography>
        <ReportList orderID={this.props.order.orderID} items={listItems} download={this._downloadFile} remove={this._deleteFile} />
        <Box>
          <TextField
              sx={{ mb: 2 }}
              fullWidth
              id="comment"
              name="comment"
              label="Add Comment"
              multiline
              rows={2}
              placeholder="Enter..."
              value={comment}
              onChange={this._handleInputChange}
              disabled={isSubmitting}
          />
          {isSubmitting ? (
            <Stack justifyContent="center" alignItems="center" p={4}>
              <CircularProgress />
            </Stack>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  variant="contained"
                  onClick={() => this._uploadToServer()}
                >
                  {newFiles.length > 0 ? 'Submit Report' : 'Add Comment'}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </CollapsibleCard>
    );
  }
}
