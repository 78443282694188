import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export default function CustomLoader(props){
    return (
        <Container sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4 }}>{props.children}</Typography>
          <CircularProgress sx={{ mt: '25%' }} />
          <Typography variant="body1" sx={{ mt: 2, color: 'grey.500' }}>Loading...</Typography>
        </Container>
    )
}