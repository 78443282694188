
import React, { Component } from 'react'
import axios from 'axios';
import CustomDialog from '../components/CustomDialog';
import CustomLoader from './CustomLoader';
import Alert from '@mui/material/Alert';
import LoginState from './LoginState';
import Container from '@mui/material/Container';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { lightBlue } from '@mui/material/colors';
import { getLabel } from '../utils';

export default class AssignOrder extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      appraisers: [],
      assignedAppraiser: '',
      selectedAppraiser: '',
      previousAppraiser: '',
      orderData: [],
      pageReady: false,
      confirmed: false,
      submitting: false,
      confirmationMsg: '',
      msgType: 'info',
      statusMsg: ''
    };
    this.getOrderDetailData();
  }

  async getOrderDetailData() {
    var response = axios.get('/getorderdetaildata', { 
      params: { pOrderID: this.props.match.params.id } 
    });
    var resultData = (await response).data;
    if(resultData) {
      this.setState({ 
        orderData: resultData,
        assignedAppraiser: resultData.assignee
      });
    }
    else {
      this.setState({ errorMsg: 'Error: No Data Found!' });
    }

    // get appraiser info
    response = axios.get('/getappraisers');
    resultData = (await response).data;
    if(resultData) {
      var selected = '';
      var activeAprs = [];
      for(var i=0; i<resultData.length; i++) {
        if(resultData[i].userName === this.state.assignedAppraiser) {
          selected = resultData[i];
          activeAprs.push(resultData[i]);
        }
        else if (resultData[i].active === "True")
          activeAprs.push(resultData[i]);
      }
      this.setState({ 
        appraisers: activeAprs, 
        selectedAppraiser: selected, 
        previousAppraiser: selected,
        pageReady: true 
      })
    }
  }

  async assignAppraiser() {
    if(this.state.previousAppraiser && !this.state.confirmed) {
      this.setState({ 
        confirmationMsg: '[' + this.state.previousAppraiser.name + 
          ']\nHas Already Been Assigned\n\nChange Appraiser To:\n[' +
          this.state.selectedAppraiser.name + ']?',
          submitting: true
        });
      return;
    }

    this.setState({ 
      statusMsg: 'Sending Request... Please Wait!', 
      msgType: 'info',
      submitting: true
    });

    const response = axios.get('/assignappraiser', { 
      params: { 
        pRequester: LoginState.userName,
        pOrderID: this.props.match.params.id,
        pAppraiser: this.state.selectedAppraiser.userName,
        pPrevious: this.state.previousAppraiser ? this.state.previousAppraiser.userName : ''
      } 
    });
    var resultData = (await response).data;
    if(resultData) {
      this.state.orderData.status = "Assigned";
      this.setState({ 
        assignedAppraiser: this.state.selectedAppraiser, 
        previousAppraiser: this.state.selectedAppraiser,
        orderData: this.state.orderData,
        confirmed: false,
        submitting: false,
        statusMsg: 'Appraiser Has Been Assigned!',
        msgType: 'success'
      });
    }
    else {
      this.setState({ 
        confirmed: false,
        submitting: false,
        statusMsg: 'Error: Failed to Assign Appraiser!',
        msgType: 'error'
      });
    }
  }

  async confirmChange() {
    await this.setState({ confirmationMsg: '', confirmed: true });
    this.assignAppraiser();
  }

  render() {
    if(!this.state.pageReady || !this.state.orderData) return <CustomLoader >Assign Order</CustomLoader>

    var data = this.state.orderData;
    // todo: check if order status is past a certain stage, where we don't allow them to change / assign.
    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Assign Order</Typography>
        <Box sx={{ maxWidth: '450px', mx: 'auto' }}>
          <Typography variant="h6">Order ID#: <span style={{ color:lightBlue[600] }}>{this.props.match.params.id}</span> ({data.status})</Typography>
          <Typography variant="subtitle1"  sx={{ mb: 1 }} style={{whiteSpace: 'pre'}}>{data.address}</Typography>
          <Typography variant="subtitle1">Ordered By: <span style={{ color:lightBlue[600] }}>{data.orderedBy}</span></Typography>
          <Typography variant="subtitle1">Loan Purpose: {data.loanPurpose}</Typography>
          <Typography variant="subtitle1">Occupancy Type: {getLabel('occupancyType', data.occupancyType)}</Typography>
          <Typography variant="subtitle1">Property Type: {getLabel('propertyType', data.propertyType)}</Typography>
          <Typography variant="subtitle1">
            {data.loanPurpose === 'Purchase' ? 
              <>Purchase Price: {data.salePrice}</> : 
              <>Estimated Value: {data.salePrice}</>
            }
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 4 }}>Loan Amount: {data.loanAmount}</Typography>
        </Box>
        {this.state.orderData.valueChecks && this.state.orderData.valueChecks.length > 0 && (
          <Box sx={{ maxWidth: '600px', mx: 'auto' }}>
            <Typography variant="subtitle1">Value Checks</Typography>
            <TableContainer component={Paper} sx={{ width: 'auto', mb: 4 }}>
              <Table aria-label="value checks">
                <TableHead>
                  <TableRow>
                    <TableCell>Requested</TableCell>
                    <TableCell>Appraiser</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Min Range</TableCell>
                    <TableCell>Max Range</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.orderData.valueChecks.map((vc) => {
                    return (
                      <TableRow key={vc.responseDate}>
                        <TableCell>{vc.responseDate}</TableCell>
                        <TableCell>{vc.appraiserName}</TableCell>
                        <TableCell>{vc.value}</TableCell>
                        <TableCell>{vc.minRange}</TableCell>
                        <TableCell>{vc.maxRange}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        <Box sx={{ maxWidth: '450px', mx: 'auto', textAlign: 'center' }}>
          <TableContainer component={Paper} sx={{ width: 'auto', mb: 4 }}>
            <Table aria-label="appraiser table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Appraiser</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.appraisers.map((appraiser) => {
                  return (
                    <TableRow key={appraiser.name}
                      style={{backgroundColor: (this.state.previousAppraiser === appraiser ? 'aliceblue' : 'white')}}>
                      <TableCell>
                        <Radio
                          checked={this.state.selectedAppraiser === appraiser}
                          onChange={() => this.setState({selectedAppraiser: appraiser})}
                          value={appraiser}
                          name="radio-buttons"
                          disabled={ this.state.submitting } 
                          inputProps={{ 'aria-label': appraiser }}
                        />
                      </TableCell>
                      <TableCell>{appraiser.name}</TableCell>
                      <TableCell>{appraiser.email}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {this.state.statusMsg && (<>
            <Alert severity={this.state.msgType} onClose={() => this.setState({statusMsg: ''})}>{this.state.statusMsg}</Alert>
            <br/><br/>
          </>)}
          <Button variant="contained" size="large" sx={{ width: '200px'}} 
            disabled={
              !this.state.selectedAppraiser || this.state.submitting ||
              this.state.selectedAppraiser === this.state.previousAppraiser
            } 
            onClick={() => this.assignAppraiser()}
          >
            Assign
          </Button>
        </Box>
        <br/>
        <br/>
        {this.state.confirmationMsg && (
          <CustomDialog
            open={this.state.confirmationMsg}
            title='Confirm'
            showConfirm={true}
            confirmMsg='Accept'
            onConfirm={() => this.confirmChange()}
            showClose={true}
            closeMsg='Cancel'
            onClose={() => this.setState({ confirmationMsg: '', submitting: false, statusMsg: '' })}
          >{this.state.confirmationMsg}</CustomDialog>
        )}
        
      </Container>
    );
  }
}