export const JWT_SECRET_KEY = 'amcportal_secret_key';

export const PRICING_OPTIONS = {
  propertyType: {
    "1" : "SFR",
    "2" : "Condo",
    "3" : "PUD",
    "4" : "2 Unit",
    "5" : "3 Unit",
    "6" : "4 Unit",
  },
  occupancyType: {
    "1": "Primary",
    "2": "Second Home",
    "3": "Investment",
  },
  loanPurpose: {
    "1": "Purchase",
    "2": "Refinance",
  },
  loanProduct: [
    { label: '30 Yr Fixed', value: 1 },
    { label: '20 Yr Fixed', value: 2 },
    { label: '15 Yr Fixed', value: 3 },
    { label: '10 Yr Fixed', value: 4 },
    { label: '3/1 ARM', value: 5 },
    { label: '5/1 ARM', value: 6 },
    { label: '7/1 ARM', value: 7 },
    { label: '10/1 ARM', value: 8 },
    { label: 'Portfolio', value: 9 },
  ],
  loanProgram: [
    { label: 'DU Conforming 30 Year Fixed', value: 1 },
    { label: 'DU Conforming 20 Year Fixed', value: 2 },
    { label: 'DU Conforming 15 Year Fixed', value: 3 },
    { label: 'DU Conforming 10 Year Fixed', value: 4 },
    { label: 'LP Conforming 30 Year Fixed', value: 5 },
    { label: 'LP Conforming 20 Year Fixed', value: 6 },
    { label: 'LP Conforming 15 Year Fixed', value: 7 },
    { label: 'LP Conforming 10 Year Fixed', value: 8 },
    { label: 'DU Conforming 10/1 ARM', value: 9 },
    { label: 'DU Conforming 7/1 ARM', value: 10 },
    { label: 'DU Conforming 5/1 ARM', value: 11 },
    { label: 'DU Conforming 3/1 ARM', value: 12 },
    { label: 'LP Conforming 10/1 ARM', value: 13 },
    { label: 'LP Conforming 7/1 ARM', value: 14 },
    { label: 'LP Conforming 5/1 ARM', value: 15 },
    { label: 'LP Conforming 3/1 ARM', value: 16 },
    { label: 'DU High Balance 30 Year Fixed', value: 17 },
    { label: 'DU High Balance 15 Year Fixed', value: 18 },
    { label: 'LP Super Conforming 30 Year Fixed', value: 19 },
    { label: 'LP Super Conforming 15 Year Fixed', value: 20 },
    { label: 'DU High Balance 10/1 ARM', value: 21 },
    { label: 'DU High Balance 7/1 ARM', value: 22 },
    { label: 'DU High Balance 5/1 ARM', value: 23 },
    { label: 'LP Super Conforming 10/1 ARM', value: 24 },
    { label: 'LP Super Conforming 7/1 ARM', value: 25 },
    { label: 'LP Super Conforming 5/1 ARM', value: 26 },
    { label: 'Portfolio 1 7/6M ARM', value: 27 },
  ],
  requestType: [
    { label: 'New Appraisal Inspection', value: 1 },
    { label: 'Form 2006/1033 Desk Review', value: 5 },
    { label: 'Form 1004D/442 Re-Inspection', value: 2 },
    { label: 'Form 1004D/442 Re-Certification', value: 3 },
    { label: 'Form 2000/1032 Field Review', value: 6 },
    { label: 'Form 2055/2055 Exterior Only', value: 4 },
    { label: 'Form 1007 Rent Schedule', value: 7 },
    { label: 'Form 216 Operating Income Statement', value: 8 },
    { label: 'Form 1007 Rent Schedule + Form 216 Operating Income Statement', value: 9 },
  ],
};