import React, { Component } from 'react'
import axios from 'axios';
import CustomDialog from './CustomDialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { red, grey, lightGreen } from '@mui/material/colors';
import CustomLoader from './CustomLoader';

export default class ValueCheckPipeline extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      valueCheckData: [],
      pageReady: false
    };

    this.getValueCheckData();
  }

  async getValueCheckData() {
    const response = axios.get('../getvaluecheckdata');

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      let top = [];
      let middle = [];
      let bottom = [];
      resultData.forEach(el => {
        if(el.status === 'Requested') {
          top.push(el)
        }
        else if(el.status === 'Assigned') {
          middle.push(el)
        }
        else {
          bottom.push(el)
        }
      });
      const sorted = top.sort((a, b) => a.requestDate > b.requestDate)
        .concat(middle.sort((a, b) => a.requestDate > b.requestDate))
        .concat(bottom.sort((a, b) => a.requestDate > b.requestDate))
        
      this.setState({ valueCheckData: sorted, pageReady: true });
    }
    else {
      this.setState({ errorMsg: 'Error: No Data Found!', pageReady: true });
    }
  }

  render() {
    if(!this.state.pageReady) return <CustomLoader>Value Check Pipeline</CustomLoader>
    return (
      <Container>
        <Box sx={{ mx: 'auto' }}>
          <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Value Check Pipeline</Typography>
          
          <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Address</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Requested By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.valueCheckData.map((check) => {
                  const color = check.status === 'Assigned' 
                    ? lightGreen[500]
                    : check.status === 'Requested' 
                      ? red[500]
                      : grey[500];
                  return (
                    <TableRow hover onClick={() => this.props.history.push("/value-status/" + check.requestID)} key={check.requestID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{check.addressLine1}</TableCell>
                      <TableCell style={{ color }}>{check.status}</TableCell>
                      <TableCell>{check.requestDate}</TableCell>
                      <TableCell style={{ textTransform: 'capitalize' }}>{check.requestedByDisplay} ({check.company.toLowerCase()})</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomDialog
            open={this.state.errorMsg}
            title='Error'
            onClose={() => this.setState({ errorMsg: '' })}
          >{this.state.errorMsg}</CustomDialog>
        </Box>
      </Container>
    );
  }
}
