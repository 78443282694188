import { useState } from 'react';
import { PRICING_OPTIONS } from './constants';

export const useFormFields = initialState => {
  const [fields, setValues] = useState(initialState);
  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
};

export const getLabel = (tableName, fieldValue) => {
  try {
    let userTable = PRICING_OPTIONS[tableName];
    let intVal = parseInt(fieldValue);
    if(!userTable)
      return null;
    for(var i=0; i<userTable.length; i++) {
      if(parseInt(userTable[i].value) === intVal)
        return userTable[i].label;
    }
  }
  catch { return null; }
};

// RESOURCES METHODS:

export const saveRates = sheet => {
  const MORTGAGE_TYPE_OBJECT = {
    ML30DU: {},
    ML20DU: {},
    ML15DU: {},
    ML10DU: {},
    ML10ADU: {},
    ML7ADU: {},
    ML5ADU: {},
    ML3ADU: {},
    ML30HDU: {},
    ML15HDU: {},
    ML10HADU: {},
    ML7HADU: {},
    ML5HADU: {},
  };
  // 263 relates to the number of rows on the
  // RSDF sheet dated 10/22/2019
  for (let i = 2; i < 263; i++) {
    const type = sheet['G' + i].v;
    const key = sheet['H' + i].v;
    const value = sheet['I' + i].v;
    if (type === 'ML30DU') {
      MORTGAGE_TYPE_OBJECT.ML30DU[key] = value;
    }
    if (type === 'ML20DU') {
      MORTGAGE_TYPE_OBJECT.ML20DU[key] = value;
    }
    if (type === 'ML15DU') {
      MORTGAGE_TYPE_OBJECT.ML15DU[key] = value;
    }
    if (type === 'ML10DU') {
      MORTGAGE_TYPE_OBJECT.ML10DU[key] = value;
    }
    if (type === 'ML10ADU') {
      MORTGAGE_TYPE_OBJECT.ML10ADU[key] = value;
    }
    if (type === 'ML7ADU') {
      MORTGAGE_TYPE_OBJECT.ML7ADU[key] = value;
    }
    if (type === 'ML5ADU') {
      MORTGAGE_TYPE_OBJECT.ML5ADU[key] = value;
    }
    if (type === 'ML3ADU') {
      MORTGAGE_TYPE_OBJECT.ML3ADU[key] = value;
    }
    if (type === 'ML30HDU') {
      MORTGAGE_TYPE_OBJECT.ML30HDU[key] = value;
    }
    if (type === 'ML15HDU') {
      MORTGAGE_TYPE_OBJECT.ML15HDU[key] = value;
    }
    if (type === 'ML10HADU') {
      MORTGAGE_TYPE_OBJECT.ML10HADU[key] = value;
    }
    if (type === 'ML7HADU') {
      MORTGAGE_TYPE_OBJECT.ML7HADU[key] = value;
    }
    if (type === 'ML5HADU') {
      MORTGAGE_TYPE_OBJECT.ML5HADU[key] = value;
    }
  }
  console.log(MORTGAGE_TYPE_OBJECT);
};

//
export const capitalize = str => {
  if (!str) return;
  const regex = /(\b[a-z](?!\s))/g;
  return str.toLowerCase().replace(regex, x => x.toUpperCase());
};

// FORMAT MONEY
/**
 * Format number to currency
 */
export const formatCurrency = (amount, edit) => {
  // check to see that it's a valid number
  if (Number(amount) === Number.Nan) {
    return edit ? '' : '-';
  }
  const number = typeof amount === 'number' ? amount : Number(amount);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(number);
};

// removes middle name from full name
export const getFormattedName = name => {
  if (!name) return 'loading...';
  const temp = name
    .toLowerCase()
    .split(' ')
    .filter(el => el !== '');
  return temp.length > 2
    ? `${temp[0]} ${temp[temp.length - 1]}`
    : name.toLowerCase();
};

export const parseFICOadj = (condition, creditScore) => {
  var conditionPassed = true;
  var condBefore = condition.indexOf('FICO') > 0;
  var condAfter = condition.indexOf('FICO') < condition.length - 4;
  var splitStr = condition.split('FICO');

  if (condBefore) {
    // handle before condition
    let containsEquals = splitStr[0].includes('=');
    let containsGreater = splitStr[0].includes('>');
    let containsLesser = splitStr[0].includes('<');

    if (containsEquals) {
      if (containsGreater)
        conditionPassed = creditScore <= parseFloat(splitStr[0].split('>=')[0]);
      else if (containsLesser)
        conditionPassed = creditScore >= parseFloat(splitStr[0].split('<=')[0]);
    } else {
      if (containsGreater)
        conditionPassed = creditScore < parseFloat(splitStr[0].split('>')[0]);
      else if (containsLesser)
        conditionPassed = creditScore > parseFloat(splitStr[0].split('<')[0]);
    }

    if (!conditionPassed) return false;
  }

  if (condAfter) {
    // handle after condition
    let containsEquals = splitStr[1].includes('=');
    let containsGreater = splitStr[1].includes('>');
    let containsLesser = splitStr[1].includes('<');

    if (containsEquals) {
      if (containsGreater)
        conditionPassed = creditScore >= parseFloat(splitStr[1].split('>=')[1]);
      else if (containsLesser)
        conditionPassed = creditScore <= parseFloat(splitStr[1].split('<=')[1]);
    } else {
      if (containsGreater)
        conditionPassed = creditScore > parseFloat(splitStr[1].split('>')[1]);
      else if (containsLesser)
        conditionPassed = creditScore < parseFloat(splitStr[1].split('<')[1]);
    }

    if (!conditionPassed) return false;
  }

  return true;
};

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '---';
};

export const checkRouteForString = (term, pathname) => pathname.includes(term);

export const shorten = str => {
  if (!str) return "-";
  let short = str.replace(/\D/g,'')/100;
  if(short > 1000000){
    return `$${short/1000000}M`; 
  } else {
    return `$${short/1000}K`; 
  }
}
