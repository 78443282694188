import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Table, TableRow, TableHead, TableBody, TableCell } from '@mui/material';
import { grey } from '@mui/material/colors';
import LoginState from './LoginState';
import CollapsibleCard from './CollapsibleCard';
import { shorten } from '../utils';

export default function OrderBidders({ order, afterCompleted }){
  const [edit, setEdit] = useState(false);
  const [selected, setSelected] = useState(null);
  const [appraisers,setAppraisers] = useState(null);
  
  const [emailList, setEmailList] = useState('');

  const [error,setError] = useState(null);
  const [loading,setLoading] = useState(false);

  const sendBidRequests = async () => {
    setLoading(true);
    let response = await axios.get('/sendBidEmails', {
      params: {
        pOrderID: order.orderID,
        pEmails: emailList
      }
    });
    setLoading(false);
    if(response.data) {
      afterCompleted();
    } else {
      setError('Something went wrong, please try again');
    }
  }

  const skipBidding = async () => {
    const response = axios.get("../SkipBidding", {
      params: { pOrderID: order.orderID }
    });
    let status = (await response).data;
    if(status === "Success") {
      afterCompleted();
    }
    else {
      setError('Something went wrong, please try again');
    }
  }

  const updateEmailList = (email) => {
    var split = emailList.split(email + ";");
    if(emailList.includes(email))
      setEmailList(split[0] + split[1]);
    else
      setEmailList(emailList + email + ";");
  }

  useEffect(() => {
    const _getAppraisersAndValueCheck = async () => {
      const response = axios.get("../getappraisers", {
        params: {
          pOrderID: order.orderID
        }
      });
      let appraisers = (await response).data;
      if(appraisers) {
        setAppraisers(appraisers);
        if(order.assignee) {
          const assignee = appraisers.find(app => app.userName === order.assignee);
          setSelected(assignee);
        }
      } else {
        setError('Something went wrong fetching appraisers and Value Check');
      }
    }
    if(!appraisers && !error && order){
      _getAppraisersAndValueCheck();
    }
  },[appraisers,error,order])

  if(!order) {
    return (
      <Box sx={{ border: 1, borderRadius: 1, borderColor: 'grey.300', padding: 2, mb: 2 }}>
        <Skeleton vartiant="rectangular" width="100%" height={58} />
      </Box>
    )
  }

  return (
    <CollapsibleCard title="Order Bid Requests">
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {(selected && !edit) ? (
        <Box textAlign="center" color={grey[800]}>
          <Typography variant="h6" mr={2}>{selected.name} - {selected.email}</Typography>
          {(selected && !edit && LoginState.userRole === 'Admin') && <Button type="text" size="small" sx={{ px: 2 }} onClick={() => setEdit(true)}>Change</Button>}
        </Box>
      ) : (
        <>
          {order.orderNotes !== "---" && (
            <Box px={1}>
              <Typography variant="body2" mb={1}>Notes from Broker</Typography>
              <Typography paragraph style={{whiteSpace: 'pre-wrap', fontSize: '13px', color: 'darkblue', marginLeft: '15px'}}>
                {order.orderNotes}
              </Typography>
            </Box>
          )}
          {appraisers ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appraisers.map(appraiser => {
                  const selected = order.valueChecks && order.valueChecks.find(el => el.appraiserName.includes(appraiser.name));
                  const value = selected
                    ? (selected.value && selected.value !== "---")
                      ? shorten(selected.value)
                      : `${shorten(selected.minRange)} - ${shorten(selected.maxRange)}`
                    : '-';
                  if(appraiser.active === "True")
                    return (
                      <TableRow key={appraiser.userName}>
                        <TableCell component="th" scope="row" align="center" padding="checkbox">
                          <Checkbox
                            checked={emailList.includes(appraiser.email)}
                            onChange={() => updateEmailList(appraiser.email)}
                            value={appraiser}
                            name={"appraiser-" + appraiser.userName}
                            inputProps={{ "aria-label": appraiser }}
                          />
                        </TableCell>
                        <TableCell>{appraiser.name}</TableCell>
                        <TableCell>{appraiser.email}</TableCell>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          ): (
            <Stack justifyContent="center" alignItems="center" p={4}>
              <CircularProgress />
            </Stack>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, gap: 2 }}>
            <Button variant="text" disabled={loading} onClick={() => skipBidding()}>Skip Bidding</Button>
            <LoadingButton variant="contained" disabled={!emailList} loading={loading} onClick={sendBidRequests}>Send Requests</LoadingButton>
          </Box>
        </>
      )}
    </CollapsibleCard>
  )
}