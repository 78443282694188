import React, { Component } from 'react'
import axios from 'axios';
import CustomDialog from './CustomDialog';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

export default class AssignValueCheck extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      requestSent: false,
      valueCheckDetail: [],
      appraisers: [], 
      submitRequest: false
    };

    this.getValueCheckDetails();
    this.getAppraisers();
  }

  async getValueCheckDetails() {
    const response = axios.get('../getvaluecheckdata');

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      for(var i=0; i<resultData.length; i++) {
        if(resultData[i].requestID  === this.props.match.params.id) {
          this.setState({ 
            valueCheckDetail: resultData[i], 
            requestSent: (resultData[i].status !== 'Requested')
          });
          break;
        }
      }
    }
  }

  async getAppraisers() {
    const response = axios.get('../getappraisers');

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      var activeAprs = [];
      for(var i=0; i<resultData.length; i++) {
        if (resultData[i].active === "True")
          activeAprs.push(resultData[i]);
      }
      this.setState({ appraisers: activeAprs })
    }
  }

  async sendRequest() {
    // do some validation first
    var selectedEmails = 0;
    var appraiserList = '';
    for(var i=0; i<this.state.appraisers.length; i++) {
      if(this.state['appraiser' + (i+1)]) {
        selectedEmails++;
        appraiserList = appraiserList + this.state.appraisers[i].userName + ",";
      }
    }
    if(selectedEmails === 0) {
      this.setState({ errorMsg: 'Please Select At Least One Appraiser' });
      return;
    }

    // to disable buttons while submitting
    this.setState({submitRequest: true});

    const response = axios.get('../requestValueCheck', { 
      params: { 
        pValueCheckID: this.props.match.params.id, 
        pAppraisers: appraiserList 
      } 
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData.startsWith("Success")) {
      this.setState({ requestSent: true, errorMsg: '', submitRequest: false })
    }
    else {
      this.setState({ errorMsg: resultData, submitRequest: false });
    }
  }

  checkBoxClicked(targetName) {
    var checkedTest = this.state[targetName] ? this.state[targetName] : false;
    this.setState({ [targetName]: !checkedTest });
  }

  render() {
    if(this.state.requestSent) {
      this.props.history.push("/value-status/" + this.props.match.params.id);
    }

    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Assign Value Check</Typography>
        {this.state.valueCheckDetail.requestID && (
          <>
          <Box sx={{ maxWidth: '450px', mx: 'auto' }}>
            <Typography variant="h6">Request Date:</Typography>
            <Typography variant="body1">{this.state.valueCheckDetail.requestDate}</Typography>
            <Typography variant="h6">Request By (Email):</Typography>
            <Typography variant="body1">{this.state.valueCheckDetail.email}</Typography>
            <Typography variant="h6">Address:</Typography>
            <Typography variant="body1">{this.state.valueCheckDetail.addressLine1}</Typography>
            {this.state.valueCheckDetail.addressLine2 && (
              <Typography variant="body1">{this.state.valueCheckDetail.addressLine2}</Typography>
            )}
            <Typography variant="body1">{this.state.valueCheckDetail.addressLine3}</Typography>
            <Typography variant="h6">Market Value:</Typography>
            <Typography variant="body1">{this.state.valueCheckDetail.marketValue}</Typography>
          </Box>
          <br/>
          <br/>

          {(this.state.appraisers.length > 0) && (
            <>
              <TableContainer component={Paper} elevation={0} sx={{ width: 'auto', mb: 4 }}>
                <Table aria-label="appraiser table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Appraiser</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.appraisers.map((apr) => (
                      <TableRow key={apr.userName}>
                        <TableCell>
                        <Checkbox
                          name={'appraiser' + apr.index}
                          checked={this.state['appraiser' + apr.index]}
                          onChange={() => this.checkBoxClicked('appraiser' + apr.index)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          style={{padding: '0', margin: '0'}}
                        />
                        </TableCell>
                        <TableCell>{apr.name}</TableCell>
                        <TableCell>{apr.company}</TableCell>
                        <TableCell>{apr.email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button variant="contained" size="large" 
                disabled={this.state.submitRequest}
                onClick={() => this.sendRequest()}>Send Request</Button>
            </>
          )}
          </>
        )}
        <CustomDialog
          open={this.state.errorMsg}
          title='Error'
          showClose={true}
          onClose={() => this.setState({ errorMsg: '' })}
        >{this.state.errorMsg}</CustomDialog>
      </Container>

    );
  }
}

