import { CssBaseline } from '@mui/material';
import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import PageRoutes from './helpers/PageRoutes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#21568a',
    },
    secondary: {
      main: '#1b164e',
    },
  }
});

export default class App extends Component {
  static displayName = App.name;
  render () {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div id="canvas">
            <CssBaseline />
            <Route component={PageRoutes} />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
