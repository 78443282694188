import { Route, Switch, useHistory } from 'react-router-dom';
import Header from '../components/Header';
import AppraisalPipeline from '../components/AppraisalPipeline';
import AssignOrder from '../components/AssignOrder';
import AppraisalForm from '../components/AppraisalForm';
import ValueCheckPipeline from '../components/ValueCheckPipeline';
import AssignValueCheck from '../components/AssignValueCheck';
import ValueCheckStatus from '../components/ValueCheckStatus';
import ValueCheckForm from '../components/ValueCheckForm';
import ValueCheckResponse from '../components/ValueCheckResponse';
import LoginState from '../helpers/LoginState';
import React from 'react';
import Login from '../components/Login';
import AppraiserDashboard from '../components/AppraiserDashboard';
import OrderUpdate from '../components/OrderUpdate';
import Blank from '../helpers/Blank';
import UploadFiles from '../components/UploadFiles';
import ViewFiles from '../components/ViewFiles';
import PaymentSuccess from '../components/PaymentSuccess';
import ManageAppraisers from '../components/ManageAppraisers';
import Order from '../components/Order';
import ValueCheck from '../components/ValueCheck';
import Settings from '../components/Settings';
import OrderBid from '../components/OrderBid';
import Register from '../components/Register';
import AppraiserFiles from '../components/AppraiserFiles';
import RequestAppraisal from '../components/RequestAppraisal';
import OrderSubmitted from '../components/OrderSubmitted';

const PageRoutes = () => {
  const history = useHistory();
  const lastTS = localStorage.getItem('lastTimeStamp');

  var user = localStorage.getItem('user');
  if(user) {
    LoginState.loggedIn = JSON.parse(user).loggedIn;
    LoginState.userRole = JSON.parse(user).userRole;
  }

  if (!lastTS) {
    // no timestamp found (logout)
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('lastTimeStamp');
    LoginState.loggedIn = false;
    LoginState.displayName = '';
  } else {
    const elapsedSeconds = (Date.now() - lastTS) / 1000;
    if (elapsedSeconds > 60 * 60 * 1) { // 1 hour
      // time exceeded (logout)
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('lastTimeStamp');
      LoginState.loggedIn = false;
      LoginState.displayName = '';

      // for certain pages, dont redirect
      if (!history.location.pathname.includes("register") &&
        !history.location.pathname.includes("vc-response") &&
        !history.location.pathname.includes("order-bid") &&
        !history.location.pathname.includes("payment-success") &&
        !history.location.pathname.includes("wait")) {
        history.push("/");
      }
    } else {
      // update elapsed time
      localStorage.removeItem('lastTimeStamp');
      localStorage.setItem('lastTimeStamp', Date.now());
    }
  }

  return (
    <>
      <Header history={history} />
      <Switch>
        {/* Routes we need to enable for any login state or role */}
        <Route exact path='/order-bid/:id' component={OrderBid} />
        <Route exact path='/register/:id' component={Register} />
        <Route exact path='/vc-response/:id' component={ValueCheckResponse} />
        <Route exact path='/payment-success/:id' component={PaymentSuccess} />
        <Route exact path='/appraiser-files/:id' component={AppraiserFiles} />
        <Route exact path='/request/:id' component={RequestAppraisal} />
        <Route exact path='/submitted/:id' component={OrderSubmitted} />
        <Route exact path='/wait' component={Blank} />

        {(LoginState.loggedIn && LoginState.userRole === 'Admin') && (<>
          <Route exact path='/order/:id' component={Order} />
          <Route exact path='/value-check/:id' component={ValueCheck} />
          <Route exact path='/value-check' component={ValueCheckPipeline} />
          <Route exact path='/value-status/:id' component={ValueCheck} />
          <Route exact path='/assign-order/:id' component={AssignOrder} />
          <Route exact path='/value-check-form' component={ValueCheckForm} />
          <Route exact path='/view-files/:id' component={ViewFiles} />
          <Route exact path='/pipeline' component={AppraisalPipeline} />
          <Route exact path='/appraisal-form' component={AppraisalForm} />
          <Route exact path='/manage-users' component={ManageAppraisers} />
          <Route exact path='/settings' component={Settings} />
          <Route exact path='/order-bid/:id' component={OrderBid} />
          <Route exact path='/' component={AppraisalPipeline} />
        </>)}
        {(LoginState.loggedIn && LoginState.userRole === 'Appraiser') && (<>
          <Route exact path='/order/:id' component={Order} />
          <Route exact path='/value-check/:id' component={AssignValueCheck} />
          <Route exact path='/value-status/:id' component={ValueCheck} />
          <Route exact path='/assign-order/:id' component={AssignOrder} />
          <Route exact path='/value-check-form' component={ValueCheckForm} />
          <Route exact path='/order-update/:id' component={OrderUpdate} />
          <Route exact path='/upload-files/:id' component={UploadFiles} />
          <Route exact path='/view-files/:id' component={ViewFiles} />
          <Route exact path='/appraiser-dashboard' component={AppraiserDashboard} />
          <Route exact path='/settings' component={Settings} />
          <Route exact path='/' component={AppraiserDashboard} />
        </>)
        }
        {(LoginState.loggedIn && LoginState.userRole === 'Portal') && (<>
          <Route exact path='/order/:id' component={Order} />
          <Route exact path='/pipeline' component={AppraisalPipeline} />
          <Route exact path='/appraisal-form' component={AppraisalForm} />
          <Route exact path='/value-check-form' component={ValueCheckForm} />
          <Route exact path='/value-status/:id' component={ValueCheckStatus} />
          <Route exact path='/view-files/:id' component={ViewFiles} />
          <Route exact path='/' component={AppraisalPipeline} />
        </>)
        }
        {!LoginState.loggedIn && (<>
          <Route exact path='/login' component={Login} />
          <Route exact path='/' component={Login} />
        </>)}
      </Switch>
    </>
  );
};

export default PageRoutes;