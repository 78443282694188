import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

export default function AlertDialog(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div style={{whiteSpace: 'pre'}}>{props.children}</div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Box sx={{ maxWidth: '400px', mx: 'auto', border: 0, borderRadius: 2, borderColor: 'grey.400', p: 2 }}>
                  {props.showConfirm && (
                      <Button variant="contained" size="small" style={{marginRight:'5px'}}
                          onClick={props.onConfirm}>
                          {props.confirmMsg ? props.confirmMsg : 'Confirm'}
                      </Button>
                  )}
                  {props.showClose && (
                      <Button variant="outlined" size="small" autoFocus onClick={props.onClose}>
                          {props.closeMsg ? props.closeMsg : 'Close'}
                      </Button>
                  )}
                </Box>
            </DialogActions>
        </Dialog>
    );
}
