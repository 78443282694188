import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function SimpleDialog({ appraisers, selected = [], open, onClose, onSubmit }) {
    const [selection, setSelection] = useState([]);
    
    const _handleAppraiserCheck = e => {
        let newSelection = [...selection];
        const appraiser = JSON.parse(e.target.value)
        if(e.target.checked){
            newSelection.push(appraiser);
        } else {
            newSelection = newSelection.filter(el => el.userName !== e.target.name);
        }
        setSelection(newSelection);
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Select appraisers</DialogTitle>
            <DialogContent>
                {selection.length > 0 && <DialogContentText>
                    {selection.length} selected
                </DialogContentText>}
                <List sx={{ pt: 0 }}>
                    {appraisers && appraisers.map((appraiser) => (
                        <ListItem key={`${appraiser.userName}-${appraiser.email}`}>
                            <ListItemIcon>
                                <Checkbox
                                    onClick={_handleAppraiserCheck}
                                    edge="start"
                                    checked={(selected && selected.some(el => el.name === appraiser.name)) || selection.some(el => el.name === appraiser.name)}
                                    disabled={selected && selected.some(el => el.name === appraiser.name)}
                                    tabIndex={-1}
                                    disableRipple
                                    name={appraiser.userName}
                                    value={JSON.stringify(appraiser)}
                                />
                            </ListItemIcon>
                            <ListItemText 
                                id={appraiser.name}
                                primary={`${appraiser.name} - ${appraiser.email}`}
                                secondary={appraiser.company}
                            />
                        </ListItem>
                    ))}
                </List>
                <Stack direction="row" justifyContent="end" mt={2} spacing={4}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="contained" disabled={selection.length === 0} onClick={() => onSubmit(selection)}>Submit</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}