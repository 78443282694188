import React, { Component } from 'react'
import { PRICING_OPTIONS } from '../helpers/constants';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import states from '../states.json';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Divider from '@mui/material/Divider';
import { lightGreen } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import Chip from '@mui/material/Chip';
import { base64ArrayBuffer } from '../helpers/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { getPropertyType } from '../helpers/utils';

const MAX_FILE_SIZE = 25000000;

export default class RequestAppraisal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      cname: '',
      phone: '',
      email: '',
      cemail: '',
      borrowerName: '',
      role: '',
      errorMsg: '',
      loanID: '',
      occupancyType: '1',
      propertyType: '1',
      loanPurpose: '1',
      retrievedData: false,
      showConfirmModal: false,
      isUploading: false,
      requestType: '',
      showFees: false,
      salesEstValue: '',
      state: 'CA',
      zip: '',
      loanProgram: 1,
      feeAmount: null,
      errors: {},
      contacts: [],
      loan: {},
      newFiles: [],
      fileArr: [],
      paymentMethod: 'credit',
      occupancyStatus: null,
      rentAmount: '',
    };
  }

  componentDidMount() {
    var splitStr = this.props.match.params.id.split('-');
    this.PageLoad(splitStr.length > 1 ? splitStr[1] : this.props.match.params.id);
  }


  PageLoad(fileID) {
    this.setState({ loan: [], retrievedData: false, loanID: fileID });
    this.getLoanData(fileID);
  }

  async getLoanData(fileID) {
    if (fileID === "new") {
      this.setState({ loan: [], retrievedData: true });
    }
    else {
      axios.get('/getloansummary', {
        params: {
          pFileID: fileID
        }
      }).then(res => {
        if (res && res.data) {
          this.setState({ loan: res.data, retrievedData: true });
        }
      }).catch(e => console.log(e, 'Error fetching loan details'));
    }
  }

  GetOccupancyType() {
    try { return PRICING_OPTIONS.occupancyType[parseInt(this.state.loan.occupancyType)]; }
    catch { return "---"; }
  }

  GetPropertyType() {
    var unitCount = parseInt(this.state.loan.numberOfUnits);
    if (unitCount > 1) { return unitCount + " Unit"; }
    return getPropertyType(this.state.loan.propertyType);
  }

  async validateAppraisal() {
    // first handle basic validation
    const requiredFields = this.props.match.params.id !== "new"
      ? ['cname', 'phone', 'cemail', 'requestType']
      : ['name', 'cname', 'phone', 'email', 'cemail', 'borrowerName', 'requestType', 'marketValue', 'address1', 'city', 'state', 'zip'];
    const errors = {};
    requiredFields.forEach(field => {
      if (!this.state[field] || this.state[field].length < 1) {
        errors[`${field}Error`] = `Please Provide An Input`;
      }
    });

    // payment method validation
    if (this.state.paymentMethod === "credit" && (!this.state.fileArr || !this.state.fileArr.fileName)) {
      errors.nofileError = 'CC Auth Form Not Submitted!';
    }
    if (this.state.paymentMethod === "credit" && !this.state.pname) {
      errors.pnameError = 'Payer name is required!';
    }
    if (this.state.paymentMethod === "credit" && !this.state.pphone) {
      errors.pphoneError = 'Payer phone is required!';
    }
    // pphone is valid phone number
    if (this.state.paymentMethod === "credit" && this.state.pphone && this.state.pphone.length < 10) {
      errors.pphoneError = 'Phone Number Needs 10 Digits';
    }
    if (this.state.paymentMethod === "credit" && !Number.isInteger(Number(this.state.pphone))) {
      errors.pphoneError = 'Phone can only contain numbers';
    }

    // validate notes to see if it's too long
    if(this.state.orderNotes && this.state.orderNotes.length > 800) {
      errors.orderNotes = 'Order Notes Maximum Length = 800';
    }

    if (!this.state.contact || this.state.contact === "custom") {
      // email format validation
      const emailValidate = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.state.email && !emailValidate.test(String(this.state.email).toLowerCase())) {
        errors.emailError = 'Invalid Email Address';
      }
      if (this.state.cemail && !emailValidate.test(String(this.state.cemail).toLowerCase())) {
        errors.cemailError = 'Invalid Email Address';
      }

      // phone validation
      if (!errors.phoneError && this.state.phone.length < 10) {
        errors.phoneError = 'Phone Number Needs 10 Digits';
      }
      if (!errors.phoneError && !Number.isInteger(Number(this.state.phone))) {
        errors.phoneError = 'Phone can only contain numbers';
      }
    }

    // if any errors render them in page
    if (Object.values(errors).length > 0) {
      this.setState({ errors, errorMsg: 'Please Fix Validation Errors to Continue Submission!' });
      return;
    }

    this.setState({ showConfirmModal: true, errorMsg: '' });
  }

  async downloadTemplate() {
    var response = axios.get('/getPdfTemplate', { params: { pTemplateName: 'Credit Card Auth Form.pdf' } });
    var resultData;
    try {
      resultData = (await response).data;
      var binary = atob(resultData);
      var array = [];
      for (var j = 0; j < binary.length; j++) {
        array.push(binary.charCodeAt(j));
      }
      var file = new Blob([new Uint8Array(array)]);

      // this code below will download the file we selected
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Credit Card Auth Form.pdf';
      a.click();
      URL.revokeObjectURL(url);
    }
    catch {
      this.setState({ errorMsg: 'Failed to retrieve PDF template from server.' });
    }
  }

  async submitOrder() {
    // hide modal
    this.setState({ showConfirmModal: false, isUploading: true });
    var splitStr = this.props.match.params.id.split('-');

    // then submit to server
    const params = {
      UserName: splitStr.length > 1 ? splitStr[0] : this.state.name + ' (' + this.state.email + ')',
      FileID: this.state.loanID,
      OrderType: this.state.requestType.toString(),
      Address: this.props.match.params.id !== "new" ? (this.state.loan.street + "\n" + this.state.loan.city + ", " + this.state.loan.state + " " + this.state.loan.zip)
        : (this.state.address1 + "\n" + this.state.city + ", " + this.state.state + " " + this.state.zip),
      Fee: this.state.feeAmount.toString(),
      Name: this.state.cname,
      Phone: this.state.phone,
      Email: this.state.cemail,
      Role: this.state.role,
      OrderNotes: this.state.orderNotes,
      PaymentMethod: this.state.paymentMethod.toString(),
      PayerName: this.state.pname ? this.state.pname.toString() : '',
      PayerPhone: this.state.pphone ? this.state.pphone.toString() : '',
      LoanPurpose: this.state.loanPurpose.toString(),
      PropertyType: this.state.propertyType.toString(),
      BorrowerName: this.state.borrowerName,
      MarketValue: this.state.marketValue ? this.state.marketValue.toString() : '',
      OccupancyType: this.state.occupancyType.toString(),
      FileContent: this.state.fileArr.fileUIntArr,
      OccupancyStatus: this.state.occupancyStatus ? this.state.occupancyStatus.toString() : "",
      RentAmount: this.state.rentAmount ? this.state.rentAmount.toString() : "",
    };

    fetch('/SubmitAppraisalOrder', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.text())
      .then((data) => {
        if (data.toString().startsWith("Success"))
          this.props.history.push("/submitted/" + data.toString().split("-")[1]);
        else
          this.setState({ errorMsg: 'Error Submitting Order', isUploading: false });
      });
  }

  handleInput = e => {
    if (e.target.name === "contact") {
      var selected = e.target.value;
      var contact = e.target.value !== "custom" ?
        this.state.loan.contacts[parseInt(selected)] : null;
      this.setState({
        errors: {},
        contact: selected,
        cname: contact ? contact.name : "",
        phone: contact ? contact.phone : "",
        cemail: contact ? contact.email : "",
        role: contact ? contact.role : "",
      });
    }
    else
      this.setState({
        [e.target.name]: e.target.value
      });
  }

  captureInput = (e) => {
    if (e.target.name === 'rentAmount' && isNaN(e.target.value)) return;
    const { loan, errors } = this.state;
    let newErrors = { ...errors };
    delete newErrors[`${e.target.name}Error`];
    delete newErrors['nofileError'];
    this.setState({
      [e.target.name]: (e.target.type === 'checkbox') ? e.target.checked : e.target.value,
      errors: newErrors
    });

    // update fee if certain fields were updated
    if (
      e.target.name === 'requestType' ||
      e.target.name === 'occupancyType' ||
      e.target.name === 'propertyType' ||
      e.target.name === 'marketValue'
    ) {
      const requestType = parseInt(
        e.target.name === 'requestType'
          ? e.target.value
          : this.state.requestType
      );
      const occupancyType = parseInt(
        e.target.name === 'occupancyType'
          ? e.target.value
          : loan.occupancyType
      );
      const propertyType = parseInt(
        e.target.name === 'propertyType'
          ? e.target.value
          : loan.propertyType
      );
      const marketValue =
        e.target.name === 'marketValue'
          ? e.target.value
          : (loan.loanPurpose === "Purchase" ? loan.salePrice : loan.propertyValue);
      var price = marketValue;
      if (price) {
        price = parseInt(price.replace('$', '').replaceAll(',', ''));
      }
      var feeAmount = 0;
      try {
        const propType = this.GetPropertyType();
        // next find fees
        switch (Number(requestType)) {
          case 1: // New Order
            if (occupancyType < 3) {
              // first or second home
              if (!propType.includes("Unit")) {
                // SFR, Condo, PUD
                if (price <= 1000000) feeAmount += 550;
                else if (price <= 2000000) feeAmount += 700;
                else if (price <= 3000000) feeAmount += 1000;
                else if (price <= 4000000) feeAmount += 1300;
                else feeAmount = 'Not Available';
              } else if (propType.includes("2 Unit")) {
                // 2 Unit
                if (price <= 1000000) feeAmount += 750;
                else if (price <= 2000000) feeAmount += 900;
                else if (price <= 3000000) feeAmount += 1050;
                else if (price <= 4000000) feeAmount += 1400;
                else feeAmount = 'Not Available';
              } else if (propType.includes("Unit")) {
                // 3-4 Unit
                if (price <= 1000000) feeAmount += 800;
                else if (price <= 2000000) feeAmount += 950;
                else if (price <= 3000000) feeAmount += 1100;
                else if (price <= 4000000) feeAmount += 1400;
                else feeAmount = 'Not Available';
              }
            } else {
              // investment property
              if (!propType.includes("Unit")) {
                // SFR, Condo, PUD
                if (price <= 1000000) feeAmount += 650;
                else if (price <= 2000000) feeAmount += 850;
                else if (price <= 3000000) feeAmount += 1200;
                else if (price <= 4000000) feeAmount += 1400;
                else feeAmount = 'Not Available';
              } else if (propType.includes("2 Unit")) {
                // 2 Unit
                if (price <= 1000000) feeAmount += 750;
                else if (price <= 2000000) feeAmount += 900;
                else if (price <= 3000000) feeAmount += 1300;
                else if (price <= 4000000) feeAmount += 1500;
                else feeAmount = 'Not Available';
              } else if (propType.includes("Unit")) {
                // 3-4 Unit
                if (price <= 1000000) feeAmount += 800;
                else if (price <= 2000000) feeAmount += 950;
                else if (price <= 3000000) feeAmount += 1300;
                else if (price <= 4000000) feeAmount += 1500;
                else feeAmount = 'Not Available';
              }
            }
            break;
          case 2: // Form 1004D/442 Re-Inspection
            feeAmount += 150;
            break;
          case 3: // Form 1004D/442 Re-Certification
            feeAmount += 150;
            break;
          case 4: // Form 2055 Exterior Only
            feeAmount += 350;
            break;
          case 5: // Desk Review
            !propType.includes("Unit") ? (feeAmount += 275) : (feeAmount += 425);
            break;
          case 6: // Field Review
            !propType.includes("Unit") ? (feeAmount += 350) : (feeAmount += 450);
            break;
          case 7: // Form 1007
            feeAmount += 100;
            break;
          case 8: // Form 216
            feeAmount += 100;
            break;
          case 9: // Form 1007/216 Combo
            feeAmount += 150;
            break;
          default:
            break;
        }
      } catch {
        feeAmount = 'Not Available';
      }

      this.setState({
        feeAmount:
          feeAmount === 'Not Available'
            ? 'Not Available'
            : '$' + feeAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
      });
    }
  };

  InputContent() {
    if (this.state.isSubmitting === true) {
      return <div className="UploadFile">Loading File(s)...</div>;
    } else {
      return (
        <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 2, mt: 1, textAlign: 'center' }}>
          <CloudUploadIcon color="disabled" sx={{ fontSize: 32 }} /><br />
          <div style={{ fontSize: '12px' }}>
            <b>Drag &amp; Drop</b> or <u>browse.</u><br />
            (Filled Out Credit Card Authorization Form)
          </div>
        </Typography>
      );
    }
  }

  handleChangeStatus({ file, remove }, status) {
    if (file && file.size > MAX_FILE_SIZE) {
      this.setState({ errorMessage: 'File Size Cannot Exceed 25 Mb!' });
    }
    if (status === 'preparing') {
      this.setState({ isSubmitting: true });
    } else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;
      reader.readAsArrayBuffer(file);
      reader.onload = e => {
        fileToUpload = e.target.result;
      };
      reader.onloadend = () => {
        var uintarr = base64ArrayBuffer(fileToUpload);
        this.setState({
          fileArr: {
            fileUIntArr: uintarr,
            fileName: file.name,
            conditionID: this.state.conditionID,
            size: file.size,
          }
        });
        remove();
        setTimeout(() => {
          this.setState({ isSubmitting: false });
        }, 2000);
      };
    }
  }

  deleteFile() {
    this.setState({ fileArr: [] });
  }

  render() {
    const { errors, loan, showConfirmModal, paymentMethod } = this.state;
    const defaultAddress = loan.street ? `${loan.street}\n${loan.city}, ${loan.state} ${loan.zip}` : "";
    let contacts = [];
    if (this.state.loan && this.state.loan.contacts) {
      for (let i = 0; i < this.state.loan.contacts.length; i++) {
        contacts.push({ label: this.state.loan.contacts[i].name + ' (' + this.state.loan.contacts[i].role + ')', value: i });
      }
    }

    if (!this.state.retrievedData) {
      return (
        <Container>
          <Typography variant="h5" component="h1" mb={2} mt={4} textAlign="center">
            Appraisal Order
          </Typography>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
          </div>
        </Container>
      );
    }

    return (
      <Container>
        <Typography variant="h5" component="h1" mb={2} mt={4} textAlign="center">
          Appraisal Order
        </Typography>
        <Grid sx={{ maxWidth: '640px', mx: 'auto' }}>
          {this.props.match.params.id === "new" ? (
            <>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 0 }} component="h2">
                  Requestor Details
                </Typography>
              </Grid>
              <Divider sx={{ mb: 2 }} />
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <TextField
                    fullWidth
                    size="small"
                    required
                    error={errors.nameError ? true : false}
                    helperText={errors.nameError}
                    sx={{ mr: 2, flex: 1 }}
                    variant="outlined"
                    label="Requestor's Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.captureInput}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    required
                    error={errors.emailError ? true : false}
                    helperText={errors.emailError}
                    sx={{ flex: 1 }}
                    variant="outlined"
                    label="Requestor's Email"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.captureInput}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 0 }} component="h2" mb={1}>
                  Property Details
                </Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <TextField
                    size="small"
                    required
                    error={errors.borrowerNameError ? true : false}
                    helperText={errors.borrowerNameError}
                    variant="outlined"
                    label="Borrower Name"
                    sx={{ mr: 2, flex: 1 }}
                    name="borrowerName"
                    value={this.state.borrowerName}
                    onChange={this.captureInput}
                  />
                  <FormControl size="small" sx={{ flex: 1 }}>
                    {/* Just Using This For Spacing */}
                  </FormControl>
                </Box>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <FormControl fullWidth size="small" sx={{ mr: 2 }}>
                    <InputLabel id="propertyType-select-label">
                      Property Type
                    </InputLabel>
                    <Select
                      required
                      labelId="propertyType-select-label"
                      name="propertyType"
                      value={this.state.propertyType}
                      label="Property Type"
                      onChange={this.captureInput}
                      MenuProps={{ sx: { maxHeight: '500px' } }}
                      defaultValue={'1'}
                    >
                      {Object.entries(PRICING_OPTIONS.propertyType).map(
                        ([short, long]) => (
                          <MenuItem key={long} value={short}>
                            {long}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth size="small">
                    <InputLabel id="occupancyType-select-label">
                      Occupancy Type
                    </InputLabel>
                    <Select
                      required
                      labelId="occupancyType-select-label"
                      name="occupancyType"
                      value={this.state.occupancyType}
                      label="Occupancy Type"
                      onChange={this.captureInput}
                      MenuProps={{ sx: { maxHeight: '500px' } }}
                      defaultValue={'1'}
                    >
                      {Object.entries(PRICING_OPTIONS.occupancyType).map(
                        ([short, long]) => (
                          <MenuItem key={long} value={short}>
                            {long}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <TextField
                    size="small"
                    required
                    error={errors.marketValueError ? true : false}
                    helperText={errors.marketValueError}
                    variant="outlined"
                    label="Market Value"
                    sx={{ mr: 2, flex: 1 }}
                    name="marketValue"
                    value={this.state.marketValue}
                    onChange={this.captureInput}
                  />
                  <FormControl size="small" sx={{ flex: 1 }}>
                    <InputLabel id="loanPurpose-select-label">
                      Loan Purpose
                    </InputLabel>
                    <Select
                      required
                      labelId="loanPurpose-select-label"
                      name="loanPurpose"
                      value={this.state.loanPurpose}
                      label="Loan Purpose"
                      onChange={this.captureInput}
                      defaultValue={'1'}
                    >
                      {Object.entries(PRICING_OPTIONS.loanPurpose).map(
                        ([short, long]) => (
                          <MenuItem key={long} value={short}>
                            {long}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 0 }} component="h2">
                  Address Details
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="New Construction"
                  name="newConstruction"
                  value={this.state.newConstruction}
                  onChange={this.captureInput}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.address1Error ? true : false}
                  helperText={errors.address1Error}
                  sx={{ mb: 2 }}
                  variant="outlined"
                  name="address1"
                  label="Address Line 1"
                  value={this.state.address1}
                  onChange={this.captureInput}
                />
                <TextField
                  fullWidth
                  size="small"
                  error={errors.address2Error ? true : false}
                  helperText={errors.address2Error}
                  sx={{ mb: 2 }}
                  variant="outlined"
                  name="address2"
                  label="Address Line 2"
                  value={this.state.address2}
                  onChange={this.captureInput}
                />
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <TextField
                    fullWidth
                    size="small"
                    required
                    error={errors.cityError ? true : false}
                    helperText={errors.cityError}
                    sx={{ mr: 2, flex: 2 }}
                    variant="outlined"
                    name="city"
                    label="City"
                    value={this.state.city}
                    onChange={this.captureInput}
                  />
                  <FormControl fullWidth size="small" sx={{ flex: 1, mr: 2 }}>
                    <InputLabel id="state-select-label">State</InputLabel>
                    <Select
                      required
                      error={errors.stateError ? true : false}
                      labelId="state-select-label"
                      id="state-select"
                      name="state"
                      value={this.state.state}
                      label="State"
                      onChange={this.captureInput}
                      MenuProps={{ sx: { maxHeight: '500px' } }}
                      defaultValue={'CA'}
                    >
                      {Object.entries(states).map(([short, long]) => (
                        <MenuItem key={long} value={short}>
                          {long}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.stateError}</FormHelperText>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    required
                    error={errors.zipError ? true : false}
                    helperText={errors.zipError}
                    sx={{ flex: 1 }}
                    variant="outlined"
                    label="Zip Code"
                    name="zip"
                    value={this.state.zip}
                    onChange={this.captureInput}
                  />
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 0 }} component="h2" >File Details</Typography>
              <Divider sx={{ mb: 1 }} />
              <Grid item container mb={2}>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Loan Number</Typography>
                  <Typography variant="body1">{this.state.loanID}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Primary Borrower</Typography>
                  <Typography variant="body1">{loan.borrower}</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="subtitle2">Zip Code</Typography>
                  <Typography variant="body1">{loan.zip}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2">Property Address</Typography>
                  <Typography variant="body1">{defaultAddress}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Loan Program</Typography>
                  <Typography variant="body1">{loan.programCode}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Occupancy Type</Typography>
                  <Typography variant="body1">{this.GetOccupancyType()}</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="subtitle2">Property Type</Typography>
                  <Typography variant="body1">{this.GetPropertyType()}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2">Sales/Est. Value</Typography>
                  <Typography variant="body1">{loan.loanPurpose === "Purchase" ? loan.salePrice : loan.propertyValue}</Typography>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 0 }} component="h2" mb={1}>
              Order Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
          </Grid>
          <Stack spacing={2} mb={2}>
            {/* REQUEST TYPE */}
            <FormControl fullWidth size="small" >
              <InputLabel id="requestType-select-label">
                Request Type
              </InputLabel>
              <Select
                required
                labelId="requestType-select-label"
                name="requestType"
                value={this.state.requestType}
                label="Request Type"
                onChange={this.captureInput}
                MenuProps={{ sx: { maxHeight: '500px' } }}
                defaultValue={'1'}
                error={errors.requestTypeError ? true : false}
              >
                {PRICING_OPTIONS.requestType.map(el => <MenuItem key={el.value} value={el.value}>{el.label}</MenuItem>)}
              </Select>
              <FormHelperText>{errors.requestTypeError}</FormHelperText>
            </FormControl>
            {/* OCCUPANCY STATUS */}
            {[7, 8, 9].includes(this.state.requestType) && <FormControl fullWidth size="small" >
              <InputLabel id="occupancyStatus-select-label">
                Occupancy Status
              </InputLabel>
              <Select
                required
                labelId="occupancyStatus-select-label"
                name="occupancyStatus"
                value={this.state.occupancyStatus}
                label="Occupancy Status"
                onChange={this.captureInput}
                MenuProps={{ sx: { maxHeight: '500px' } }}
                error={errors.occupancyStatusError ? true : false}
              >
                <MenuItem value="Rented">Rented</MenuItem>
                <MenuItem value="Vacant">Vacant</MenuItem>
              </Select>
              <FormHelperText>{errors.occupancyStatusError}</FormHelperText>
            </FormControl>}
            {/* RENT AMOUNT */}
            {this.state.occupancyStatus === 'Rented' && (
              <TextField
                label="Rent Amount"
                variant="outlined"
                size="small"
                name="rentAmount"
                value={this.state.rentAmount}
                onChange={this.captureInput}
              />
            )}
            {this.state.feeAmount && <Box sx={{ bgcolor: lightGreen[100], py: 2, textAlign: 'center', borderRadius: 2 }}>
              <Typography sx={{ color: lightGreen[900] }} variant="subtitle1">Estimated Appraisal Fee:</Typography>
              <Typography sx={{ color: lightGreen[900] }} variant="h5" component="h3">{this.state.feeAmount}</Typography>
            </Box>}
            <TextField
              fullWidth
              size="small"
              multiline
              rows={2}
              variant="outlined"
              name="orderNotes"
              label="Order Notes (Optional) i.e. rush order (Max 800 Characters)"
              error={errors.orderNotes ? true : false}
              value={this.state.orderNotes}
              onChange={this.captureInput}
            />
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 0 }} component="h2" mb={1}>
                Payment Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <FormControl>
              <RadioGroup row value={paymentMethod} onChange={this.captureInput}
                name="paymentMethod"
              >
                <FormControlLabel value="credit" control={<Radio />} label="Credit Card" />
                <FormControlLabel value="at-closing" control={<Radio />} label="At Closing" />
              </RadioGroup>
            </FormControl>
            {paymentMethod === "credit" && (<>
              <Button variant="outlined" startIcon={<SimCardDownloadIcon />} sx={{ width: '100%' }} onClick={() => this.downloadTemplate()}>
                Download Payment Authorization Template
              </Button>
              <Box sx={{ display: 'flex', mt: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.pnameError ? true : false}
                  helperText={errors.pnameError}
                  sx={{ mb: 2, mr: 2 }}
                  variant="outlined"
                  label="Payer Name"
                  name="pname"
                  value={this.state.pname}
                  onChange={this.captureInput}
                />
                <TextField
                  fullWidth
                  size="small"
                  required
                  error={errors.pphoneError ? true : false}
                  helperText={errors.pphoneError}
                  sx={{ mb: 2 }}
                  variant="outlined"
                  label="Payer Phone"
                  name="pphone"
                  value={this.state.pphone}
                  inputProps={{ maxLength: 11 }}
                  onChange={this.captureInput}
                />
              </Box>
              <Box>
                <Dropzone
                  maxFiles="1"
                  maxSizeBytes="25000000" // 25 Mb
                  accept=".pdf"
                  onChangeStatus={this.handleChangeStatus.bind(this)}
                  inputContent={this.InputContent.bind(this)}
                  styles={{
                    dropzone: {
                      overflow: 'hidden',
                      border: '2px dashed lightgray',
                      borderRadius: '0.25rem',
                    },
                    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                    inputLabel: (_, extra) => extra.reject ? { color: 'red' } : {}
                  }}
                />
                {(this.state.fileArr && this.state.fileArr.fileName) && (
                  <Box sx={{ backgroundColor: 'grey.100', p: 1, pl: 2, borderRadius: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Typography variant="body1">
                      {this.state.fileArr.fileName} <Chip size="small" variant="outlined" label="New" color="success" />
                    </Typography>
                    <IconButton sx={{ m: 0 }}>
                      <DeleteIcon onClick={() => this.deleteFile()} />
                    </IconButton>
                  </Box>
                )}
                {this.state.errors.nofileError && (
                  <div style={{ marginBottom: '10px', marginTop: '5px', color: 'red', fontSize: '12px' }}>Upload CC Authorization File</div>
                )}
              </Box>
            </>)}
          </Stack>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 0 }} component="h2" mb={1}>
              Inspection Contact
            </Typography>
            <Divider sx={{ mb: 2 }} />
            {contacts.length > 0 && (
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="contact"
                >
                  {contacts.map((el, i) => (
                    <FormControlLabel key={`${el.name}-${el.i}`} control={<Radio />} label={el.label} id={`${el.label}-${el.value}`} name="contact" onChange={this.handleInput} value={el.value} />
                  ))}
                  <FormControlLabel control={<Radio />} label="Other (Fill-In Below)" name="contact" onChange={this.handleInput} value="custom" />
                </RadioGroup>
              </FormControl>
            )}
            <Box sx={{ display: 'flex', mt: 1 }}>
              <TextField
                fullWidth
                size="small"
                required
                error={errors.cnameError ? true : false}
                helperText={errors.cnameError}
                sx={{ mb: 2, mr: 2 }}
                variant="outlined"
                label="Contact Name"
                name="cname"
                value={this.state.cname}
                onChange={this.captureInput}
                disabled={this.state.contact !== 'custom' && this.props.match.params.id !== "new"}
              />
              <TextField
                fullWidth
                size="small"
                required
                error={errors.roleError ? true : false}
                helperText={errors.roleError}
                sx={{ mb: 2 }}
                variant="outlined"
                label="Contact Role"
                name="role"
                value={this.state.role}
                onChange={this.captureInput}
                disabled={this.state.contact !== 'custom' && this.props.match.params.id !== "new"}
              />
            </Box>
            <Box sx={{ display: 'flex', mb: 2 }}>
              <TextField
                fullWidth
                size="small"
                required
                error={errors.phoneError ? true : false}
                helperText={errors.phoneError}
                sx={{ mr: 2 }}
                variant="outlined"
                label="Contact Phone"
                name="phone"
                value={this.state.phone}
                onChange={this.captureInput}
                disabled={this.state.contact !== 'custom' && this.props.match.params.id !== "new"}
              />
              <TextField
                fullWidth
                size="small"
                required
                error={errors.cemailError ? true : false}
                helperText={errors.cemailError}
                variant="outlined"
                label="Contact Email"
                name="cemail"
                value={this.state.cemail}
                onChange={this.captureInput}
                disabled={this.state.contact !== 'custom' && this.props.match.params.id !== "new"}
              />
            </Box>
          </Grid>
          <Button
            sx={{ mb: 2 }}
            variant="contained"
            onClick={() => this.validateAppraisal()}
            disabled={Object.values(this.state.errors).length > 0}
          >Submit Order</Button>
          {this.state.errorMsg && <Alert severity="error">{this.state.errorMsg}</Alert>}
        </Grid>

        {/* CONFIRMATION MODAL */}
        <Dialog
          open={showConfirmModal}
          onClose={() => this.setState({ showConfirmModal: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            The appraisal order will be submitted to 1StopAMC.
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm that you intend to go forward with setting up an inspection before submitting.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showConfirmModal: false })} >Cancel</Button>
            <Button onClick={() => this.submitOrder()} disabled={this.state.isUploading} autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* UPLOADING MODAL */}
        <Dialog
          open={this.state.isUploading}
          onClose={() => this.setState({ showConfirmModal: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{textAlign:'center'}}>
            Submitting Appraisal Order
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{textAlign:'center'}}>
              Please Wait! Order is Being Submitted.
              <div style={{ width: '100%', textAlign: 'center' }}>
                <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Container>
    );
  }
}