import React, { Component } from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import states from '../states.json';
import LoginState from './LoginState';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
/* import zillowLogo from '../icons/zillow.svg';
import truliaLogo from '../icons/trulia.svg';
import redfinLogo from '../icons/redfin.svg';
import { formatCurrency } from '../utils'; */

import celebration from '../icons/celebration.png';

export default class ValueCheckForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newConstruction: false,
      state: 'CA',
      email: '',
      company: '',
      errors: {},
      submitted: false,
      submitting: false,
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  async getUserData() {
    const response = axios.get('../getuserdata', {
      params: {
        pUserName: LoginState.userName,
        pUserRole: LoginState.userRole,
      },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if (resultData) {
      this.setState({ company: LoginState.company, email: resultData.email });
    }
  }

  captureInput = (e) => {
    this.setState({
      [e.target.name]: (e.target.type === 'checkbox') ? e.target.checked : e.target.value,
      errors: { ...this.state.errors, [`${e.target.name}Error`]: null },
    });
  };

  resetFields() {
    this.setState({
      address1: '',
      address2: '',
      city: '',
      state: 'CA',
      zip: '',
      marketValue: '',
      errors: {},
    });
  }

  async submitRequest() {
    const {
      address1,
      address2,
      city,
      state,
      zip,
      email,
      marketValue,
      company,
      newConstruction,
    } = this.state;
    const errors = {};
    const USPS_USER = '237OUROI6841';
    const uspsUrl = `https://production.shippingapis.com/ShippingAPI.dll?API=Verify&XML=<AddressValidateRequest USERID="${USPS_USER}"><Address><Address1>${address1}</Address1><Address2>${address2}</Address2><City>${city}</City><State>${state}</State><Zip5>${zip}</Zip5><Zip4></Zip4></Address></AddressValidateRequest>`;
    const requiredFields = [
      'address1',
      'city',
      'state',
      'zip',
      'marketValue',
      'company',
      'email',
    ];
    const emailValidate =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const zipValidate = /^[0-9]{5}(?:-[0-9]{4})?$/;

    this.setState({ submitting: true });

    // required fields validation
    requiredFields.forEach((field) => {
      if (!this.state[field] || this.state[field].length < 1) {
        if (field === 'marketValue') {
          errors.marketValueError = 'Target value not specified';
        } else if (field === 'company') {
          errors.companyError = 'Requesting company not specified';
        } else if (field === 'address1') {
          errors.address1Error = 'Address not specified';
        } else {
          errors[`${field}Error`] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } not specified`;
        }
      }
    });
    // zipcode format validation
    if (zip && !zipValidate.test(Number(zip))) {
      errors.zipError = 'Invalid zipcode';
    }
    // email format validation
    if (email && !emailValidate.test(String(email).toLowerCase())) {
      errors.emailError = 'Invalid email address';
    }
    // address validation with USPS api
    if (address1 && city && state && zip && !newConstruction) {
      await axios
        .get(uspsUrl, { headers: { 'Content-Type': 'text/xml' } })
        .then((res) => {
          if (res.data) {
            const response = new window.DOMParser().parseFromString(
              res.data,
              'text/xml'
            );
            if (response.getElementsByTagName('Error')[0]) {
              errors.address1Error = 'Address not valid';
            }
            if (
              response.getElementsByTagName('ReturnText')[0] &&
              response
                .getElementsByTagName('ReturnText')[0]
                .innerHTML.includes('more information is needed')
            ) {
              errors.address2Error =
                'this field is required for this address, it must mach USPS records';
            }
          }
        })
        .catch((e) => {
          this.setState({ submitting: false });
          console.log(e, '*** Error validating address ***');
        });
      // if any errors render them in page
      if (Object.values(errors).length > 0) {
        this.setState({ errors, submitting: false });
      } else {
        axios
          .get('../valuecheck', {
            params: {
              pAddress1: address1,
              pAddress2: address2 || '',
              pCity: city,
              pState: state,
              pZip: zip,
              pValue: marketValue,
              pCompany: company,
              pEmail: email,
              pUserName: LoginState.userName,
              pDisplayName: LoginState.displayName,
            },
          })
          .then((res) => {
            this.setState({ submitted: true, submitting: false });
            this.resetFields();
          })
          .catch((e) => {
            this.setState({ submitting: false });
            console.log(e, '*** Error sending request ***');
          });
      }
    }
    this.setState({ errors, submitting: false });
  }

  render() {
    const { errors /* , zillowEstimate, truliaEstimate, redfinEstimate  */ } =
      this.state;
    if (this.state.submitted) {
      return (
        <Container>
          <Box sx={{ mx: 'auto', textAlign: 'center', mt: 8 }}>
            <img src={celebration} alt="" width="100" />
            <Typography
              variant="h4"
              color="primary"
              sx={{ fontWeight: 'medium', mb: 2, mt: 4 }}
            >
              Request Sent!
            </Typography>
            <Typography variant="h5">
              You will receive an email shortly.
            </Typography>
            {/* UNCOMMENT ONCE SCRAPERS ARE SET UP  */}
            {/* <Typography variant="body1" sx={{ color: 'gray', mt: 4, mb: 2}}>Here are some estimates from industry leaders:</Typography>
            <Box sx={{ maxWidth: 500, mx: 'auto' }}>
              {zillowEstimate ? <Button href="#text-buttons" sx={{ display: 'flex', justfiyContent: 'center', px: 4, py: 2 }}>
                <img src={zillowLogo} style={{ height: 'auto', width: '100px' }} alt="zillow logo" />
                &nbsp;<ArrowRightIcon /><Typography variant="h5">{formatCurrency(zillowEstimate)}</Typography>
              </Button> : <p>Loading...</p>}
              {truliaEstimate ? <Button href="#text-buttons" sx={{ display: 'flex', justfiyContent: 'center', px: 4, py: 2 }}>
                <img src={truliaLogo} style={{ height: 'auto', width: '100px' }} alt="trulia logo" />
                &nbsp;<ArrowRightIcon /><Typography variant="h5">{formatCurrency(truliaEstimate)}</Typography>
              </Button> : <p>Loading...</p>}
              {redfinEstimate ? <Button href="#text-buttons" sx={{ display: 'flex', justfiyContent: 'center', px: 4, py: 2 }}>
                <img src={redfinLogo} style={{ height: 'auto', width: '100px' }} alt="redfin logo" />
                &nbsp;<ArrowRightIcon /><Typography variant="h5">{formatCurrency(redfinEstimate)}</Typography>
              </Button> : <p>Loading...</p>}
            </Box> */}
            <Button
              size="large"
              variant="text"
              sx={{ mt: 2, flex: 1 }}
              onClick={() => this.setState({ submitted: false })}
            >
              Send another request
            </Button>
          </Box>
        </Container>
      );
    }
    return (
      <Container>
        <Box sx={{ mx: 'auto' }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}
          >
            Value Check Request
          </Typography>
          <Box component="form">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '450px',
                mx: 'auto',
              }}
            >
              <TextField
                required
                error={errors.address1Error ? true : false}
                helperText={errors.address1Error}
                sx={{ mb: 2 }}
                variant="outlined"
                name="address1"
                label="Address Line 1"
                value={this.state.address1}
                onChange={this.captureInput}
              />
              <TextField
                error={errors.address2Error ? true : false}
                helperText={errors.address2Error}
                variant="outlined"
                name="address2"
                label="Address Line 2"
                value={this.state.address2}
                onChange={this.captureInput}
              />
              <FormControlLabel 
                control={<Checkbox />}
                label="New Construction"
                name="newConstruction"
                value={this.state.newConstruction}
                onChange={this.captureInput} 
                sx={{ my: 1 }}
              />
              <TextField
                required
                error={errors.cityError ? true : false}
                helperText={errors.cityError}
                sx={{ mb: 2 }}
                variant="outlined"
                name="city"
                label="City"
                value={this.state.city}
                onChange={this.captureInput}
              />
              <Box sx={{ display: 'flex' }}>
                <FormControl fullWidth sx={{ flex: 2, mr: 2 }}>
                  <InputLabel id="state-select-label">State</InputLabel>
                  <Select
                    required
                    error={errors.stateError ? true : false}
                    labelId="state-select-label"
                    id="state-select"
                    name="state"
                    value={this.state.state}
                    label="State"
                    onChange={this.captureInput}
                    MenuProps={{ sx: { maxHeight: '500px' } }}
                    defaultValue={'CA'}
                  >
                    {Object.entries(states).map(([short, long]) => (
                      <MenuItem key={long} value={short}>
                        {long}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  required
                  error={errors.zipError ? true : false}
                  helperText={errors.zipError}
                  sx={{ flex: 1, mb:2 }}
                  variant="outlined"
                  label="Zip Code"
                  name="zip"
                  value={this.state.zip}
                  onChange={this.captureInput}
                />
              </Box>
              <TextField
                required
                error={errors.marketValueError ? true : false}
                helperText={errors.marketValueError}
                sx={{ mb: 2 }}
                variant="outlined"
                label="Target Value"
                name="marketValue"
                value={this.state.marketValue}
                onChange={this.captureInput}
              />
              <TextField
                required
                error={errors.companyError ? true : false}
                helperText={errors.companyError}
                sx={{ mb: 2 }}
                variant="outlined"
                label="Requesting Company"
                name="company"
                value={this.state.company}
                disabled
              />
              <TextField
                required
                error={errors.emailError ? true : false}
                helperText={errors.emailError}
                sx={{ mb: 2 }}
                variant="outlined"
                label="Email"
                name="email"
                type="email"
                value={this.state.email}
                disabled
              />
              <Box sx={{ display: 'flex' }}>
                <Button
                  size="large"
                  variant="outlined"
                  sx={{ mr: 2, flex: 1 }}
                  disabled={this.state.submitting}
                  onClick={() => this.resetFields()}
                >
                  Reset
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ flex: 1 }}
                  disabled={this.state.submitting}
                  onClick={() => this.submitRequest()}
                >
                  {this.state.submitting ? 'Submitting...' : 'Submit'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}
