import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { lightBlue } from '@mui/material/colors';

export default function CollapsibleCard(props) {
  return (
    <Accordion defaultExpanded={!props.closed} elevation={0}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography variant="h6" color={lightBlue[800]}>{props.title.toUpperCase()}</Typography>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
}
