import React, { Component } from 'react'
import axios from 'axios';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import CustomLoader from './CustomLoader';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { CircularProgress } from '@mui/material';

export default class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appraisers: [],
      errors: {},
      submitting: false,
      pageReady: false,
      drawer: false
    };
    this.GetUserData();
  }

  async GetUserData() {
    var response = axios.get('/getappraisers');
    var resultData = (await response).data;
    if (resultData) {
      this.setState({ appraisers: resultData, pageReady: true });
    }
    else {
      this.setState({ errorMsg: 'Could Not Retrieve Appraiser Data!', pageReady: true })
    }
  }

  async AddAppraiser() {
    const errors = {};
    const requiredFields = ['fullName', 'userName', 'company', 'email', 'phone'];
    const emailValidate = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // required fields validation
    requiredFields.forEach(field => {
      if (!this.state[field] || this.state[field].length < 1) {
        errors[`${field}Error`] = `${field.charAt(0).toUpperCase() + field.slice(1)} Not Specified`;
      }
    });
    if (this.state.email && !emailValidate.test(String(this.state.email).toLowerCase())) {
      errors.emailError = 'Invalid Email Address';
    }
    if (this.state.phone && this.state.phone.length < 10) {
      errors.phoneError = 'Phone must be at least 10 digits long';
    }
    if (this.state.phone && !/^\d+$/.test(this.state.phone)) {
      errors.phoneError = 'Phone must be digits only';
    }

    // if any errors render them in page
    if (Object.values(errors).length > 0) {
      this.setState({ errors, submitting: false });
    }
    else {
      this.setState({ errors, submitting: true });
      var response = axios.get('../addappraiser', {
        params: {
          pFullName: this.state.fullName,
          pUserName: this.state.userName,
          pCompany: this.state.company,
          pEmail: this.state.email,
          pPhone: this.state.phone
        }
      });
      var resultData = (await response).data;
      if (resultData === "Success") {
        this.setState({
          successMsg: 'New Appraiser Successfully Added!',
          errorMsg: '',
          submitting: false,
          fullName: '',
          userName: '',
          company: '',
          email: '',
          phone: '',
          drawer: false
        });
        this.GetUserData();
      }
      else {
        this.setState({ successMsg: '', errorMsg: resultData, submitting: false })
      }
    }
  }

  _toggleDrawer = (e) => {
    if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
      return;
    }
    this.setState(prevState => ({ drawer: !prevState.drawer || false }));
  };

  handleChange = async (event) => {
    for (var i = 0; i < this.state.appraisers.length; i++) {
      if (this.state.appraisers[i].userName === event.target.name) {
        var newValue = this.state.appraisers[i].active === "True" ? "False" : "True";
        this.state.appraisers[i].active = newValue;
        this.setState({ appraisers: this.state.appraisers });
        var response = axios.get('../toggleappraiser', {
          params: { pUserName: event.target.name, pValue: newValue }
        });
        var resultData = (await response).data;
        if (resultData === "Error")
          this.setState({ errorMsg: 'Error Updating Active Status!' });
        break;
      }
    }
  };

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [`${e.target.name}Error`]: null } });
  };

  render() {
    const { errors } = this.state;
    if (!this.state.pageReady) return <CustomLoader>Manage Appraisers</CustomLoader>
    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', m: 4, textAlign: 'center' }}>Manage Appraisers</Typography>
        {this.state.successMsg && <Alert severity="success" sx={{ my: 2 }}>{this.state.successMsg}</Alert>}
        {this.state.errorMsg && <Alert severity="error" sx={{ mb: 2 }}>{this.state.errorMsg}</Alert>}
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mb: 2 }}>
          <Button variant="outlined" color="primary" onClick={this._toggleDrawer}>Add Appraiser +</Button>
        </Box>
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Active</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Files</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.appraisers.map((row) => (
                <TableRow
                  hover
                  key={row.userName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {row.approved === "True" ? (
                      <Switch
                        name={row.userName}
                        checked={row.active === "True"}
                        onChange={this.handleChange}
                      />
                    ) : (
                      <Tooltip placement="bottom" title={<>Appraiser Not Approved Yet</>}>
                        <QueryBuilderIcon style={{ color: 'gray' }} />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.userName}</TableCell>
                  <TableCell>{row.company}</TableCell>
                  <TableCell>
                    <Button 
                      variant={row.fileCount < 1 ? 'text' : 'outlined'}
                      disabled={row.fileCount === "0"}
                      href={"/appraiser-files/" + row.userName}
                    >
                      {row.fileCount < 1 ? 'No Files' : `View Files (${row.fileCount})`}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Drawer anchor="right" open={this.state.drawer} onClose={e => {
          this._toggleDrawer(e)
          this.setState({ errors: {} })
        }}>
          <Box sx={{ width: 320, p: 2 }} role="presentation">
            <Typography variant="h6" mb={2}>Add New Appraiser</Typography>
            <TextField
              value={this.state.fullName}
              name="fullName"
              label="Full Name"
              error={errors.fullNameError ? true : false}
              helperText={errors.fullNameError}
              onChange={this.captureInput}
              size="small"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              value={this.state.userName}
              name="userName"
              label="User Name"
              error={errors.userNameError ? true : false}
              helperText={errors.userNameError}
              onChange={this.captureInput}
              size="small"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              value={this.state.company}
              name="company"
              label="Company"
              error={errors.companyError ? true : false}
              helperText={errors.companyError}
              onChange={this.captureInput}
              size="small"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              value={this.state.email}
              name="email"
              label="Email"
              error={errors.emailError ? true : false}
              helperText={errors.emailError}
              onChange={this.captureInput}
              size="small"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              value={this.state.phone}
              name="phone"
              label="Phone"
              error={errors.phoneError ? true : false}
              helperText={errors.phoneError}
              onChange={this.captureInput}
              size="small"
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              onClick={this.AddAppraiser.bind(this)}
              color="primary"
              disabled={this.state.submitting}
              size="small"
              fullWidth
              sx={{ mb: 2 }}
            >
              Add Appraiser
            </Button>

            {this.state.submitting && (
              <Stack justifyContent="center" alignItems="center" p={4}>
                <CircularProgress />
              </Stack>
            )}
          </Box>
        </Drawer>
      </Container>
    )
  }
}