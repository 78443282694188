export const PRICING_OPTIONS = {
  lienPosition: [
    { label: '1st Lien', value: 1 },
    { label: '2nd Lien', value: 2 },
    { label: '1st and 2nd Lien', value: 3 },
  ],
  loanPurpose: [
    { label: 'PUR', value: 1 },
    { label: 'R/T', value: 2 },
    { label: 'C/O (Debt Consolidation)', value: 3 },
    { label: 'C/O (Home Improvement)', value: 4 },
    { label: 'C/O (Other)', value: 5 },
    { label: 'Limited C/O', value: 6 },
  ],
  propertyType: [
    { label: 'SFR', value: 1 },
    { label: 'Condo', value: 2 },
    { label: 'PUD', value: 3 },
    { label: '2 Unit', value: 4 },
    { label: '3 Unit', value: 5 },
    { label: '4 Unit', value: 6 },
  ],
  occupancyType: [
    { label: 'Primary', value: 1 },
    { label: 'Second Home', value: 2 },
    { label: 'Investment', value: 3 },
  ],
  documentType: [
    { label: 'Full Doc', value: 1 },
    { label: 'Stated Income', value: 2 },
    { label: 'VOE Only', value: 3 },
  ],
  states: [{ label: 'California', value: 1 }],
  county: [
    { label: 'Alameda', value: 1 },
    { label: 'Alpine', value: 2 },
    { label: 'Amador', value: 3 },
    { label: 'Butte', value: 4 },
    { label: 'Calaveras', value: 5 },
    { label: 'Colusa', value: 6 },
    { label: 'Contra Costa', value: 7 },
    { label: 'Del Norte', value: 8 },
    { label: 'El Dorado', value: 9 },
    { label: 'Fresno', value: 10 },
    { label: 'Glenn', value: 11 },
    { label: 'Humboldt', value: 12 },
    { label: 'Imperial', value: 13 },
    { label: 'Inyo', value: 14 },
    { label: 'Kern', value: 15 },
    { label: 'Kings', value: 16 },
    { label: 'Lake', value: 17 },
    { label: 'Lassen', value: 18 },
    { label: 'Los Angeles', value: 19 },
    { label: 'Madera', value: 20 },
    { label: 'Marin', value: 21 },
    { label: 'Mariposa', value: 22 },
    { label: 'Mendocino', value: 23 },
    { label: 'Merced', value: 24 },
    { label: 'Modoc', value: 25 },
    { label: 'Mono', value: 26 },
    { label: 'Monterey', value: 27 },
    { label: 'Napa', value: 28 },
    { label: 'Nevada', value: 29 },
    { label: 'Orange', value: 30 },
    { label: 'Placer', value: 31 },
    { label: 'Plumas', value: 32 },
    { label: 'Riverside', value: 33 },
    { label: 'Sacramento', value: 34 },
    { label: 'San Benito', value: 35 },
    { label: 'San Bernardino', value: 36 },
    { label: 'San Diego', value: 37 },
    { label: 'San Francisco', value: 38 },
    { label: 'San Joaquin', value: 39 },
    { label: 'San Luis Obispo', value: 40 },
    { label: 'San Mateo', value: 41 },
    { label: 'Santa Barbara', value: 42 },
    { label: 'Santa Clara', value: 43 },
    { label: 'Santa Cruz', value: 44 },
    { label: 'Shasta', value: 45 },
    { label: 'Sierra', value: 46 },
    { label: 'Siskiyou', value: 47 },
    { label: 'Solano', value: 48 },
    { label: 'Sonoma', value: 49 },
    { label: 'Stanislaus', value: 50 },
    { label: 'Sutter', value: 51 },
    { label: 'Tehama', value: 52 },
    { label: 'Trinity', value: 53 },
    { label: 'Tulare', value: 54 },
    { label: 'Tuolumne', value: 55 },
    { label: 'Ventura', value: 56 },
    { label: 'Yolo', value: 57 },
    { label: 'Yuba', value: 58 },
  ],
  loanProduct: [
    { label: '30 Yr Fixed', value: 1 },
    { label: '20 Yr Fixed', value: 2 },
    { label: '15 Yr Fixed', value: 3 },
    { label: '10 Yr Fixed', value: 4 },
    { label: '3/1 ARM', value: 5 },
    { label: '5/1 ARM', value: 6 },
    { label: '7/1 ARM', value: 7 },
    { label: '10/1 ARM', value: 8 },
  ],
  loanProgram: [
    { label: 'DU Conforming 30 Year Fixed', value: 1 },
    { label: 'DU Conforming 20 Year Fixed', value: 2 },
    { label: 'DU Conforming 15 Year Fixed', value: 3 },
    { label: 'DU Conforming 10 Year Fixed', value: 4 },
    { label: 'LP Conforming 30 Year Fixed', value: 5 },
    { label: 'LP Conforming 20 Year Fixed', value: 6 },
    { label: 'LP Conforming 15 Year Fixed', value: 7 },
    { label: 'LP Conforming 10 Year Fixed', value: 8 },
    { label: 'DU Conforming 10/1 ARM', value: 9 },
    { label: 'DU Conforming 7/1 ARM', value: 10 },
    { label: 'DU Conforming 5/1 ARM', value: 11 },
    { label: 'DU Conforming 3/1 ARM', value: 12 },
    { label: 'LP Conforming 10/1 ARM', value: 13 },
    { label: 'LP Conforming 7/1 ARM', value: 14 },
    { label: 'LP Conforming 5/1 ARM', value: 15 },
    { label: 'LP Conforming 3/1 ARM', value: 16 },
    { label: 'DU High Balance 30 Year Fixed', value: 17 },
    { label: 'DU High Balance 15 Year Fixed', value: 18 },
    { label: 'LP Super Conforming 30 Year Fixed', value: 19 },
    { label: 'LP Super Conforming 15 Year Fixed', value: 20 },
    { label: 'DU High Balance 10/1 ARM', value: 21 },
    { label: 'DU High Balance 7/1 ARM', value: 22 },
    { label: 'DU High Balance 5/1 ARM', value: 23 },
    { label: 'LP Super Conforming 10/1 ARM', value: 24 },
    { label: 'LP Super Conforming 7/1 ARM', value: 25 },
    { label: 'LP Super Conforming 5/1 ARM', value: 26 },
  ],
  impound: [
    { label: 'No Impound', value: 1 },
    { label: 'Tax', value: 2 },
    { label: 'Insurance', value: 3 },
    { label: 'Tax and Insurance', value: 4 },
  ],
  pmi: [
    { label: 'No PMI', value: 1 },
    { label: 'Borrower Paid', value: 2 },
    { label: 'Lender Paid', value: 3 },
  ],
  eDisclosure: [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 2 },
  ],
  lockPeriod: [
    { label: '15 Days', value: 1 },
    { label: '30 Days', value: 2 },
  ],
  lockExtension: [
    { label: '2 Days (0.125% Fee)', value: 1 },
    { label: '7 Days (0.250% Fee)', value: 2 },
  ],
  compPlan: [
    { label: 'Borrower Paid', value: 1 },
    { label: 'Tier 1: 1.000%', value: 2 },
    { label: 'Tier 2: 1.125%', value: 3 },
    { label: 'Tier 3: 1.250%', value: 4 },
    { label: 'Tier 4: 1.375%', value: 5 },
    { label: 'Tier 5: 1.500%', value: 6 },
    { label: 'Tier 6: 1.625%', value: 7 },
    { label: 'Tier 7: 1.750%', value: 8 },
    { label: 'Tier 8: 1.875%', value: 9 },
    { label: 'Tier 9: 2.000%', value: 10 },
    { label: 'Tier 10: 2.125%', value: 11 },
    { label: 'Tier 11: 2.250%', value: 12 },
    { label: 'Tier 12: 2.375%', value: 13 },
    { label: 'Tier 13: 2.500%', value: 14 },
  ],
  requestType: [
    { label: 'New Appraisal Inspection', value: 1 },
    { label: 'Form 1004D/442 Re-Inspection', value: 2 },
    { label: 'Form 1004D/442 Re-Certification', value: 3 },
    { label: 'Form 2055 Exterior-Only', value: 4 },
    { label: 'Form 2006/1033 Desk Review', value: 5 },
    { label: 'Form 2000/1032 Field Review', value: 6 },
  ],
  businessChannel: [
    { label: 'Retail', value: 1 },
    { label: 'Wholesale', value: 2 },
  ],
  marital: [
    { label: 'Married', value: 1 },
    { label: 'Separated', value: 2 },
    { label: 'Unmarried', value: 3 },
  ],
  ownership: [
    { label: 'Borrower Owns This Property', value: 1 },
    { label: 'Borrower Does Not Own This Property', value: 2 },
  ],
};