import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CustomDialog from './CustomDialog';
import Alert from '@mui/material/Alert';
import CollapsibleCard from './CollapsibleCard';
import LoginState from './LoginState';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import ReportList from './ReportList';
import { getPropertyType } from '../helpers/utils';
import { PDFDocument, utf16Encode } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import ModalConfirm from './ModalConfirm';

export default function OrderReview({ order, afterCompleted }) {
    const [confirm, setConfirm] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState(false);
    const [comment, setComment] = useState('');
    const [toast, setToast] = useState([]);
    const [alert, setAlert] = useState();
    const [message, setMessage] = useState();
    const [paymentDialog, setPaymentDialog] = useState(false);
    const [listItems, setListItems] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [borrowerName, setBorrowerName] = useState('');
    const [borrowerAddress, setBorrowerAddress] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [appraisalFee, setAppraisalFee] = useState('');
    const [inspectionType, setInspectionType] = useState('');
    const [propertyAddress, setPropertyAddress] = useState('');
    const [payAtClosing, setPayAtClosing] = useState(false);

    useEffect(() => {
        if(!listItems) {
            const _getFiles = async () => {
                let response = await axios.get('/getfiles', { params: { pOrderID: order.orderID } });
                if(response.data){
                    const all = response.data.comments.concat(response.data.files);
                    const sorted = all.sort((a,b) => new Date(a.timestamp) - new Date(b.timestamp));
                    setListItems(sorted);
                    return true;
                }
                return false;
            }
            _getFiles();
        }
    }, [listItems,order]);

    useEffect(() => {
      if(borrowerName === '') {
        const getFileData = async () => {
          let response = axios.get('/getReceiptData', { params: { pFileID: order.orderID } });
          try {
            var resultData = (await response).data;
            setBorrowerName(resultData.borrowerName);
            setBorrowerAddress(resultData.borrowerAddress);
            setInvoiceDate(resultData.invoiceDate);
            setPaymentDate(resultData.paymentDate);
            setAppraisalFee(resultData.appraisalFee);
            setInspectionType(resultData.inspectionType);
            setPropertyAddress(resultData.propertyAddress);
            setPayAtClosing(resultData.payAtClosing === "True");
          }
          catch { 
            setAlert('Failed to Retreive Receipt Data'); 
            setMessage('Failed to Retreive Receipt Data');
          }
        }
        getFileData();
      }
    });

    const GetPropertyType = () => {
      var unitCount = parseInt(order.numberOfUnits);
      if(unitCount > 1) { return unitCount + " Unit"; }
      return getPropertyType(order.propertyType);
    };

    const _submitComment = async (approved) => {
        setIsSubmitting(true);
        let response = await axios.get('/submitComment', {
          params: {
            pOrderID: order.orderID,
            pUserName: LoginState.userName,
            pComment: comment,
            pApproved: approved
          }
        });
        if(response.data) {
            let list = listItems;
            list.push(response.data);
            setListItems(list);
            setComment('');
        }
    }

    const createPDF = async () => {
      var fileData;
      var arialNarrowFont;
      var response = axios.get('/getPdfTemplate', { params: { pTemplateName: paymentDate === "At Closing" ? 'Invoice.pdf' : 'Receipt.pdf' } });
      var resultData;
      try {
        resultData = (await response).data;
        fileData = resultData;
      }
      catch {
        setAlert('Failed to retrieve PDF template from server.');
        setMessage('Failed to retrieve PDF template from server.');
      }
      const pdfDoc = await PDFDocument.load(fileData);
      const pages = pdfDoc.getPages();
  
      // setup custom font
      response = axios.get('/getFont', { params: { pFontName: 'ARIALN.TTF' } });
      try {
        resultData = (await response).data;
        arialNarrowFont = resultData;
      }
      catch {
        setAlert('Failed to retrieve font from server.');
        setMessage('Failed to retrieve font from server.');
      }
      pdfDoc.registerFontkit(fontkit);
      const ArialNarrow = await pdfDoc.embedFont(arialNarrowFont);
  
      //---------------------------------------------------------------------------------
      // fill out custom text and content here
      //---------------------------------------------------------------------------------
      var i;
      // const timesRoman = await pdfDoc.embedFont(StandardFonts.TimesRoman);
      // const timesRomanBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
  
      // Borrower Name
      pages[0].drawText(borrowerName, {
        x: 110,
        y: 644,
        size: 12,
        font: ArialNarrow
      });
  
      // Borrower Address
      var addr = borrowerAddress.split('\n');
      for(i=0; i<addr.length; i++)
      {
        pages[0].drawText(addr[i], {
          x: 110,
          y: 629 - (i * 15),
          size: 12,
          font: ArialNarrow
        });
      }
  
      // Invoice #
      pages[0].drawText(order.orderID, {
        x: 490,
        y: 643,
        size: 12,
        font: ArialNarrow
      });
  
      // Invoice Date
      pages[0].drawText(invoiceDate, {
        x: 490,
        y: 625,
        size: 12,
        font: ArialNarrow
      });
  
      // Payment Date
      pages[0].drawText(paymentDate, {
        x: 490,
        y: 607,
        size: 12,
        font: ArialNarrow
      });
  
      // Inspection Type
      pages[0].drawText(inspectionType, {
        x: 80,
        y: 545,
        size: 12,
        font: ArialNarrow
      });
  
      // Property Address
      addr = propertyAddress.split('\n');
      for(i=0; i<addr.length; i++)
      {
        pages[0].drawText(addr[i], {
          x: 80,
          y: 526 - (i * 15),
          size: 11,
          font: ArialNarrow
        });
      }
  
      // Appraisal Price
      pages[0].drawText(appraisalFee, {
        x: 430,
        y: 545,
        size: 12,
        font: ArialNarrow
      });
  
      // Total
      pages[0].drawText(appraisalFee, {
        x: 430,
        y: 458,
        size: 16,
        font: ArialNarrow
      });
  
      const pdfBytes = await pdfDoc.save();
      return btoa(Uint8ToString(pdfBytes));
    }

    const Uint8ToString = (u8a) => {
      var CHUNK_SZ = 0x8000;
      var c = [];
      for (var i=0; i < u8a.length; i+=CHUNK_SZ) {
        c.push(String.fromCharCode.apply(null, u8a.subarray(i, i+CHUNK_SZ)));
      }
      return c.join("");
    }

    const combinePDFBuffers = (file1, file2) => {
      return file1 + '$$$$$' + file2;
    }

    const delay = (milliseconds) => {
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
      });
    }

    const _submitReview = async (approved) => {
        if(!approved && !comment){
            setToast(['error','A comment is required when changes are requested']);
            return;
        }
        setToast([]);
        setIsSubmitting(true);
        setConfirm(false);

        var response = null;
        var resultData = null;

        // check if previously already approved by amc
        var approvedIndex = -1;
        for(var a=listItems.length-1; a>=0; a--) {
          if(!listItems[a].name && listItems[a].commentText.includes('[Approved By AMC')) {
            approvedIndex = a;
            break;
          }
        }
        // if previously approved, check if any new files uploaded since
        var newFiles = false;
        if(approvedIndex !== -1) {
          for(var b=approvedIndex; b<listItems.length; b++) {
            if(listItems[b].name) {
              newFiles = true;
              break;
            }            
          }
        }

        // submit files to byte if approved by amc (if not previously approved, or if there are new files)
        if(approved && (approvedIndex === -1 || newFiles)) {
          var params = null;
          var fileDocName = "";
          var fileDocType = "";
          const orderPropertyType = GetPropertyType();
          switch(order.orderType) {
            case "New Appraisal":
            case "New Appraisal Inspection":
              if(orderPropertyType === "SFR" || orderPropertyType === "PUD" || orderPropertyType === "1 Unit") {
                fileDocName = "Appraisal Report (1004)";
                fileDocType = "FNMA1004";
                break;
              }
              else if(orderPropertyType === "Condo (Attached)" || orderPropertyType === "Condo (Detached)") {
                fileDocName = "Appraisal Condominium Report (1073)";
                fileDocType = "FNMA1073";
                break;
              }
              else if (orderPropertyType === "2 Unit" || orderPropertyType === "3 Unit" || orderPropertyType === "4 Unit") {
                fileDocName = "Appraisal Income Property Report (1025)";
                fileDocType = "FNMA1025";
                break;
              }
              else
                break;
            case "Re-Inspection":
            case "Re-Certification":
            case "Form 1004D/442 Re-Inspection":
            case "Form 1004D/442 Re-Certification":
              fileDocName = "Appraisal Final Report (1004D)";
              fileDocType = "FNMA1004D";
              break;
            case "Desk Review":
            case "Form 2006/1033 Desk Review":
              fileDocName = "Appraisal Desk Review";
              fileDocType = "DeskReview";
              break;
            case "Field Review":
            case "Form 2000/1032 Field Review":
              fileDocName = "Appraisal Field Review";
              fileDocType = "DeskReview";
              break;
            case "Rent Schedule":
            case "Form 1007 Rent Schedule":
              fileDocName = "Single Family Comparable Rent Schedule (1007)";
              fileDocType = "FNMA1007";
              break;
            case "Operating Income Statement":
            case "Form 216 Operating Income Statement":
              fileDocName = "Operating Income Statement (216)";
              fileDocType = "FNMA216";
              break;
            case "Rent Schedule + Operating Income Statement":
            case "Form 1007 Rent Schedule + Form 216 Operating Income Statement":
              fileDocName = "Comparable Rent and Operating Income";
              fileDocType = "FNMA1007+216";
              break;
            default:
              break;
          }

          var fileContent = (await createPDF());
          var xmlFile = null;
          
          // get latest pdf and submit
          for(var i=listItems.length-1; i>=0; i--) {
            if(listItems[i].name && ("" + listItems[i].name).toLowerCase().includes(".pdf")) {
              // download this file and submit to byte
              
              response = axios.get('/getamcfiledata', { params: { pOrderID: order.orderID, pFileName: listItems[i].name } });
              try {
                resultData = (await response).data;
                fileContent = combinePDFBuffers(fileContent, resultData);
              }
              catch {
                setIsSubmitting(false);
                setAlert('Error Retreiving PDF File!');
                setMessage('Error Retreiving PDF File!');
                return;
              }
              
              // upload pdf
              params = {
                docType: fileDocType,
                docName: fileDocName,
                fileID: order.lenderFileID,
                orderID: order.orderID,
                fileName: fileDocName + ".pdf",
                fileContent: fileContent,
              };
      
              if(fileDocName) {
                fetch('/bytefileupload', {
                  method: 'POST',
                  body: JSON.stringify(params),
                  headers: { 'Content-Type': 'application/json', },
                })
                .then(response => response.text())
                .then(data => {
                  // error check
                  if (!data.startsWith('Success')) {
                    setAlert('Error Uploading PDF File!');
                    setMessage('Error Uploading PDF File!');
                    return;
                  }
                });
              }
              break;
            }
          }
          
          // this prevents a timing issue bug with byte uploads
          await delay(1500);

          // get latest xml and submit
          for(var j=listItems.length-1; j>=0; j--) {
            if(listItems[j].name && ("" + listItems[j].name).toLowerCase().includes(".xml")) {
              // download this file and submit to byte
              response = axios.get('/getamcfiledata', { params: { pOrderID: order.orderID, pFileName: listItems[j].name } });
              try {
                resultData = (await response).data;
                xmlFile = resultData;
              }
              catch {
                setIsSubmitting(false);
                setAlert('Error Retreiving XML File!');
                setMessage('Error Retreiving XML File!');
                return;
              }

              // upload xml
              if(xmlFile) {
                params = {
                  docType: fileDocType,
                  docName: fileDocName + " XML",
                  fileID: order.lenderFileID,
                  orderID: order.orderID,
                  fileName: fileDocName + ".xml",
                  fileContent: xmlFile,
                };
        
                if(fileDocName) {
                  fetch('/bytefileupload', {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: { 'Content-Type': 'application/json', },
                  })
                  .then(response => response.text())
                  .then(data => {
                    // error check
                    if (!data.startsWith('Success')) {
                      setAlert('Error Uploading XML File!');
                      setMessage('Error Uploading XML File!');
                      return;
                    }
                  });
                }
              }
              break;
            }
          }
        }

        response = await axios.get('/changestatus', {
          params: {
            pOrderID: order.orderID,
            pStatus: approved ? 'UW Review' : 'AMC Review'
          }
        });
        if(response.data && (approvedIndex === -1 || newFiles)) {
            await _submitComment(approved ? '[Approved By AMC]' : '');
            setIsSubmitting(false);
            afterCompleted();
        }
    }

    const _handleInputChange = e => {
        e.preventDefault();
        setComment(e.target.value);
    }

    const _handleApproval = () => {
        // if not paid, and have not selected - pay at closing.
        if((order.paid === "Pending" || order.paid === "---") && order.payOption !== "Pay At Closing") {
          setPaymentDialog(true);
        } else {
            setConfirm(true);
            setConfirmMsg('Please confirm you want to approve this report.');
        }
    }

    const _deleteFile = async (orderID, fileName) => {
      if(fileName.startsWith("comment-")) {
        var timestamp = fileName.substring(8);
        let response = await axios.get('/deleteComment', {
          params: { pOrderID: orderID, pTimestamp: timestamp },
        });
        if (response.data) {
          setAlert(`Successfully Deleted Comment!`);
          setMessage(`Successfully Deleted Comment!`);
          for(var i=0; i<listItems.length; i++) {
            if(listItems[i] && listItems[i].timestamp && listItems[i].timestamp.toString() === timestamp.toString()) {
              listItems.splice(i, 1);
              break;
            }
          }
          setListItems(null); // if I don't do this, it won't refresh the display
          setListItems(listItems); // refresh list display
        }
        else {
          setAlert(`Error Deleting Comment!`); 
          setMessage(`Error Deleting Comment!`); 
        }
      }
      else {
        let response = await axios.get('/deleteReportFile', {
          params: { pOrderID: orderID, pFileName: fileName },
        });
        if (response.data) {
          setAlert(`Successfully Deleted File ${fileName}!`);
          setMessage(`Successfully Deleted File ${fileName}!`);
          for(var i=0; i<listItems.length; i++) {
            if(listItems[i] && listItems[i].name && listItems[i].name === fileName) {
              listItems.splice(i, 1);
              break;
            }
          }
          setListItems(null); // if I don't do this, it won't refresh the display
          setListItems(listItems); // refresh list display
        }
        else {
          setAlert(`Error Deleting File ${fileName}!`); 
          setMessage(`Error Deleting File ${fileName}!`); 
        }
      }
    }

    const _downloadFile = async (orderID, fileName) => {
        var response = axios.get('/getfiledata', { params: { pOrderID: orderID, pFileName: fileName } });
        try {
          var resultData = (await response).data;
          var dlFN = fileName;
          if(fileName[fileName.length-1] === ')') {
            dlFN = "";
            var splitFN = fileName.split(' ');
            for(var i=0; i<splitFN.length-1; i++) {
              dlFN = dlFN + splitFN[i];
            }
          }
          var binary = atob(resultData);
          var array = [];
          for (var j=0; j<binary.length; j++) {
            array.push(binary.charCodeAt(j));
          }
          var file = new Blob([new Uint8Array(array)]);

          // this code below will download the file we selected
          const url = URL.createObjectURL(file);
          const a = document.createElement('a');
          a.href = url;
          a.download = dlFN;
          a.click();
          URL.revokeObjectURL(url);
        }
        catch {}
    }

    return (
        <CollapsibleCard title="review">
          {alert && (<Alert severity="info" sx={{ mb: 2 }}>{alert}</Alert>)}
          <Typography variant="subtitle1">History</Typography>
          {listItems && (<ReportList orderID={order.orderID} items={listItems} download={_downloadFile} remove={_deleteFile} />)}
          <Typography variant="subtitle1" mb={2}>Review</Typography>
          <Box>
              <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  id="order-review-comment"
                  label={order.status !== 'Completed' ? 'Review Comment' : 'Re-open Order With Comment...'}
                  multiline
                  rows={2}
                  placeholder="Enter..."
                  value={comment}
                  onChange={_handleInputChange}
                  disabled={isSubmitting}
              />

              {toast[0] && <Alert severity={toast[0]}>{toast[1]}</Alert>}
              {isSubmitting ? (
                <Stack justifyContent="center" alignItems="center" p={4}>
                  <CircularProgress />
                </Stack>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box>
                    <Button disableElevation disabled={isSubmitting} variant="outlined" color="error" sx={{ mt:2, mr: 2 }} onClick={() => _submitReview(false)}>Request Changes</Button>
                    {order.status !== 'Completed' && <Button disableElevation disabled={isSubmitting} variant="contained" sx={{ mt:2 }} onClick={_handleApproval}>Send to Lender</Button>}
                  </Box>
                </Box>
              )}
          </Box>
          <CustomDialog
              open={confirm}
              title='Confirm'
              showConfirm={true}
              confirmMsg='Accept'
              onConfirm={() => _submitReview(true)}
              showClose={true}
              closeMsg='Cancel'
              onClose={() => setConfirm(false)}
          >{confirmMsg}</CustomDialog>
          <Dialog
              open={paymentDialog}
              onClose={() => setPaymentDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title" color="error">
                  {"This order hasn't been paid yet"}
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      You need to make sure the payment is processed before you approve it.
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button color="error" onClick={() => setPaymentDialog(false)} autoFocus>Close</Button>
              </DialogActions>
          </Dialog>
          <ModalConfirm
            show={message}
            onAccept={() => setMessage('')}
            onDecline={() => setMessage('')}
          >
            <div style={{ fontSize: '16px' }}>
              {message}
            </div>
          </ModalConfirm>
        </CollapsibleCard>
    )
}