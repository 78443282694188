import axios from 'axios';
import React, { Component } from 'react'
import CollapsibleCard from './CollapsibleCard';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import { PDFDocument, utf16Encode } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

export default class OrderDeliver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      submitted: false,
      alert: null,
      pageReady: false,
      errorMsg: '',
      borrowerName: '',
      borrowerAddress: '',
      invoiceNumber: this.props.order.orderID,
      invoiceDate: '',
      paymentDate: '',
      inspectionType: '',
      propertyAddress: '',
      appraisalFee: '',
      payAtClosing: false,
      docType: 'Select...'
    };

    this.getFileData();
  }

  async getFileData() {
    let response = axios.get('/getReceiptData', { params: { pFileID: this.props.order.orderID } });
    try {
      var resultData = (await response).data;
      this.setState({
        pageReady: true,
        errorMsg: '',
        borrowerName: resultData.borrowerName,
        borrowerAddress: resultData.borrowerAddress,
        invoiceDate: resultData.invoiceDate,
        paymentDate: resultData.paymentDate,
        appraisalFee: resultData.appraisalFee,
        inspectionType: resultData.inspectionType,
        propertyAddress: resultData.propertyAddress,
        payAtClosing: (resultData.payAtClosing === "True")
      })}
    catch {
      this.setState({pageReady: true, errorMsg: 'Failed to retrieve receipt data.'});
    }
  }

  async _deliverReport() {
    try {
      // send completed report
      var response = axios.get('/sendcompletedreport', { params: { pOrderID: this.props.order.orderID }});
      var resultData = (await response).data;
      if(!resultData.startsWith('Success'))
        this.setState({alert: 'Error Updating Order Status to Complete!'});
      this.setState({submitting: false});
      this.props.afterCompleted();
      this.getFileData();
    }
    catch { this.setState({alert: 'Error Completing Report!', submitting: false}); }
  }

  captureInput = e => {
    this.setState({[e.target.name]: e.target.value});
  }

  render() {
    const { alert, submitted, pageReady, submitting } = this.state;
    return (
      <CollapsibleCard title="Complete Order">
        {alert && <Alert severity="info" sx={{ mb: 2 }}>{alert}</Alert>}
        {submitted ? <Box>
          <Typography paragraph textAlign="center">Report Delivered to Lender! ({submitted})</Typography>
        </Box> : (<>
          <List sx={{ width: '100%' }}>
            <Stack pt={2} alignItems="end">
              <LoadingButton
                variant="contained"
                loading={!pageReady || submitting}
                onClick={() => this._deliverReport()}
                disabled={this.state.payAtClosing}
              >Mark Completed</LoadingButton>
            </Stack>
          </List>
        </>)}
      </CollapsibleCard>
    );
  }
}