import React, { Component } from "react"
import axios from "axios";
import Container from '@mui/material/Container';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CustomLoader from "./CustomLoader";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs
 from "dayjs";
export default class OrderBid extends Component {
  state = {
    requestSent: false,
    loading: true,
    bidData: {},
    estValue: null,
    estComplete: "",
    estVal: null,
    notes: "",
    errorMsg: "",
    isSubmitting: false
  };

  componentDidMount() {
    this.getOrderBidData();
  }

  async getOrderBidData() {
    var aprID = this.props.match.params.id.split("-")[0];
    var orderID = this.props.match.params.id.split("-")[1];

    const response = axios.get("../getOrderBidData", {
      params: { pAppraiser: aprID, pOrderID: orderID }
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      this.setState({
        bidData: resultData,
        loading: false
      });
    }
  }

  captureInput = e => {
    if(e.target.name === 'notes' && e.target.value.lenght > 750){
      this.setState({errorMsg: "your notes are getting long, you have a max of 1000 characters"});
    }
    this.setState({[e.target.name]: e.target.value });
  };

  async submitRequest() {
    const { estValue, estComplete, notes } = this.state;

    if(estComplete && dayjs(estComplete).isBefore(dayjs(), 'day')){
      this.setState({errorMsg: "Estimated date cannot be before today"});
      return;
    }

    if(notes.length > 1000) {
      this.setState({errorMsg: "Notes are too long (max 1000 characters)"});
      return;
    }

    this.setState({isSubmitting: true});
    const response = axios.get("../submitorderbid", {
      params: {
        pOrderID: this.props.match.params.id.split("-")[1],
        pAppraiser: this.props.match.params.id.split("-")[0],
        pValue: estValue,
        pNotes: notes,
        pEstDate: estComplete
      }
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      var bidData = this.state.bidData;
      bidData.responded = resultData;
      this.setState({ bidData });
    }
    this.setState({isSubmitting: false});
  }

  _handleCopyToClipboard = () => {
    const { valueCheckDetail } = this.state;
    const address = valueCheckDetail.addressLine1
    + " "
    + valueCheckDetail.addressLine2
    + " "
    + valueCheckDetail.addressLine3;
    navigator.clipboard.writeText(address);
  }

  render() {
    const { bidData, errorMsg, estValue, estComplete, notes } = this.state;
    const orderID = this.props.match.params.id.split("-")[1];

    if(!bidData) {
      return <CustomLoader >Appraiser Order Bid</CustomLoader>
    }

    if(!bidData.userExists) {
      return (
        <Container>
          <Paper sx={{ mt: 4, p: 2, width: '60ch', mx: 'auto', textAlign: "center" }}>
            <Typography variant="h5">Appraiser Order Bid</Typography>
            <Typography variant="body1" mb={2}>
              {orderID}
            </Typography>
            <Typography variant="body1" mb={2}>
              {this.state.loading ? (
                <Stack justifyContent="center" alignItems="center" p={4}>
                  <CircularProgress />
                </Stack>
              ) : (
                <>Appraiser Data Not Found</>
              )}
            </Typography>
          </Paper>
        </Container>
      );
    }

    if(bidData.responded) {
      return (
        <Container>
          <Paper sx={{ mt: 4, p: 2, width: '60ch', mx: 'auto', textAlign: "center" }}>
            <Typography variant="h5">Appraiser Order Bid</Typography>
            <Typography variant="body1" mb={2}>
              {orderID}
            </Typography>
            <Typography variant="body1" mb={2}>
              Appraiser Bid Submitted ({bidData.responded})
            </Typography>
          </Paper>
        </Container>
      );
    }

    return (
      <Container>
        <Paper sx={{ mt: 4, p: 2, width: '60ch', mx: 'auto', textAlign: "center" }}>
          <Typography variant="h5">Appraiser Order Bid</Typography>
          <Typography variant="body1" mb={2}>
            {orderID}
          </Typography>

          <Typography variant="caption">Property Address:</Typography>
          <Stack  mb={2} direction="row" justifyContent="center" alignItems="center">
            <Typography variant="body1" mb={2}>
              {bidData.address}
            </Typography>
            <IconButton variant="primary" onClick={this._handleCopyToClipboard}>
              <ContentCopyIcon />
            </IconButton>
          </Stack>

          <Typography variant="caption">Appraisal Type:</Typography>
          <Typography variant="body1" mb={2}>
            {bidData.appraisalType}
          </Typography>

          <Stack spacing={2} sx={{ my: 4 }}>
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            <TextField
              label="Amount"
              placeholder="Bid Amount for Appraisal Work (Optional)"
              fullWidth
              // size="small"
              name="estValue"
              value={estValue}
              onChange={this.captureInput}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: <InputAdornment position="end">
              </InputAdornment>
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={'Estimated Competion Date'}
                fullWidth
                placeholder="When You Expect To Finish Appraisal Report (Optional)"
                value={estComplete ? dayjs(estComplete) : ''}
                onChange={val => {
                  this.setState({ estComplete: val ? val.format("MM/DD/YYYY") : '' });
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>,
                  endAdornment: <InputAdornment position="end"></InputAdornment>
                }}
                renderInput={(params) => <TextField {...params} placeholder="When You Expect To Finish Appraisal Report (Optional)" fullWidth sx={{ mb: 2 }} />}
              />
            </LocalizationProvider>
            <Divider />
            <Stack spacing={2} sx={{ my: 4 }}>
              <TextField
                label="Notes"
                placeholder="Notes for 1StopAMC Regarding this Order Bid (Optional)"
                fullWidth
                name="notes"
                value={notes}
                onChange={this.captureInput}
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>,
                  endAdornment: <InputAdornment position="end"></InputAdornment>
                }}
              />
            </Stack>
          </Stack>
          {!this.state.isSubmitting ? (
            <Button
              variant="contained"
              onClick={() => this.submitRequest()}
            >Submit Bid</Button>
          ) : (
            <Stack justifyContent="center" alignItems="center" p={4}>
              <CircularProgress />
            </Stack>
          )}
        </Paper>
      </Container>
    )}
}
