import React, { Component } from 'react'
import axios from 'axios';
import CustomLoader from './CustomLoader';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { PRICING_OPTIONS } from '../helpers/constants';
import Button from '@mui/material/Button';
import LoginState from './LoginState';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { deepOrange, amber, lightGreen } from '@mui/material/colors';
import { getPropertyType } from '../helpers/utils';

export default class OrderUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      comments: [],
      pageReady: false,
      dataFound: false,
      accessAllowed: false,
      msgType: 'info',
      submitting: false,
      showComments: false
    };

    if(!this.props.match.params.id || !LoginState.loggedIn) {
      this.props.history.push("/");
      return;
    }
    this.getOrderData(this.props.match.params.id);
  }

  async getOrderData(orderID) {
    axios.get('/getordersummary', {
      params: { pOrderID: orderID }
    }).then(res => {
      if(res && res.data) {
        var order = res.data;
        if(order.assignee !== LoginState.userName) {
          this.setState({ order: order, pageReady: true, accessAllowed: false });
          return;
        }

        this.setState({
          order: order,
          requestType: order.orderType, 
          requestLabel: this.GetRequestType(order.orderType),
          inspectionDate: order.inspectionDate,
          initialInspectionDate: order.inspectionDate,
          estReportDate: order.estDeliveryDate,
          initialEstReportDate: order.estDeliveryDate,
          comments: order.comments,
          showComments: order.comments && order.comments.length > 0,
          pageReady: true,
          accessAllowed: true,
          dataFound: true
        });
      }
    }).catch(this.setState({ pageReady: true, dataFound: false }));
  }

  GetRequestType(typeNum) {
    for(var i=0; i<PRICING_OPTIONS.requestType.length; i++) {
      if(PRICING_OPTIONS.requestType[i].value.toString() === typeNum.toString()) {
        return PRICING_OPTIONS.requestType[i].label;
      }
    }
    return null;
  }

  GetOccupancyType() {
    switch(this.state.loan.occupancyType) {
      case "1":
        return "Primary";
      case "2":
        return "Second Home";
      case "3":
        return "Investment";
      default:
        return "---";
    }
  }

  GetPropertyType() {
    var unitCount = parseInt(this.state.order.numberOfUnits);
    if(unitCount > 1) { return unitCount + " Unit"; }
    return getPropertyType(this.state.order.propertyType);
  }

  handleInput = e => {
    this.setState({ 
      [e.target.name]: e.target.value, 
      errors: { ...this.state.errors, [`${e.target.name}Error`]: null } 
    });
  }

  async SetAppraisalDate(dateType, dateValue, stateToUpdate) {
    this.setState({ submitting: true });
    axios.get('/setappraisaldate', {
      params: { 
        pOrderID: this.props.match.params.id,
        pDateType: dateType,
        pDateValue: dateValue
      }
    }).then(res => {
      if(res && res.data && res.data === "Success") {
        this.state.order.status = "Scheduled";
        this.setState({
          [stateToUpdate]: dateValue,
          order: this.state.order,
          msgType: "success",
          statusMsg: "Date Successfully Updated!",
          submitting: false
        });
      }
    });
  }

  render() {
    var order = this.state.order;
    if(!this.state.pageReady || !order) return <CustomLoader >Appraisal Order</CustomLoader>
    if(!this.state.accessAllowed) {
      return (
        <Container>
          <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Appraisal Order</Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>
            Restricted Access To Order #: {order.orderID}
            <br/>
            (Appraisers Can Only View Orders Assigned to Them)
            <br/><br/>
            <Button variant="contained" size="large" style={{ textAlign: 'center'}}
              onClick={() => this.props.history.push("/")}>To Dashboard</Button>
          </Typography>
        </Container>
      );
    }

    var color = lightGreen[500];
    if(order.status) {
      if(order.status === 'Assigned')
        color = amber[500];
      if(order.status === 'Creating') 
        color = deepOrange[500];
    }

    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', my:2, textAlign: 'center' }}>Appraisal Order</Typography>
        <Box sx={{ maxWidth: '800px', mx: 'auto', border: 1, borderRadius: 2, borderColor: 'grey.400', p: 2 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">Order ID#:</Typography>
              <Typography variant="body2">{order.orderID}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Order Date:</Typography>
              <Typography variant="body2">{order.orderDate}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Appraisal Ordered By:</Typography>
              <Typography variant="body2">{order.orderedBy}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Borrower / Home Owner:</Typography>
              <Typography variant="body2">{order.borrower}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Property Type:</Typography>
              <Typography variant="body2">{this.GetPropertyType()}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Loan Purpose:</Typography>
              <Typography variant="body2">{order.loanPurpose}</Typography>
            </Grid>
            <br/><br/><br/>
            <Grid item xs={12}>
              <Typography variant="body1">Property Address:</Typography>
              <Typography variant="body2">{order.address}</Typography>
            </Grid>
            <br/><br/><br/>
            <Grid item xs={6}>
              <Typography variant="body1">Appraisal Type:</Typography>
              <Typography variant="body2">{this.GetRequestType(order.orderType)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Sales/Est.Value:</Typography>
              <Typography variant="body2">{order.salePrice}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Order Status:</Typography>
              <Typography variant="body2" style={{ color }}>{order.status}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Last Updated:</Typography>
              <Typography variant="body2">{order.lastUpdated}</Typography>
            </Grid>
            <br/><br/><br/>
            <Grid item xs={12}>
              <Typography variant="body1">Contact To Schedule Appraisal:</Typography>
              <Typography variant="body2">{order.contact}</Typography>
            </Grid>
          </Grid>
        </Box>
        <br/><br/>
        {this.state.showComments && (<>
          <Box sx={{ maxWidth: '800px', mx: 'auto', border: 1, borderRadius: 2, borderColor: 'grey.400', p: 2 }}>
              <Table sx={{ width: '650px' }} aria-label="comments table">
                <TableBody>
                    {this.state.comments.map((comment) => (
                      <TableRow key={comment.timeOfDay} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                          <Typography variant="body1">[{comment.timestamp}]</Typography>
                        </TableCell>
                        <TableCell width="75px">{comment.userName}</TableCell>
                        <TableCell width="500px">{comment.commentText}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <TextField
                            variant="outlined"
                            name='newComment'
                            label='Add Comment'
                            value={this.state.newComment}
                            onKeyPress={this.checkEnter}
                            onChange={this.handleInput}
                        />
                        &nbsp;
                        <Button size="large" variant="contained" disabled={this.state.isUploading}
                          onClick={() => this.submitComment()}>Add</Button>
                        &nbsp;
                        <Button size="large" variant="contained" disabled={this.state.isUploading}
                          onClick={() => this.setState({ newComment: '' })}>Clear</Button>
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
          </Box>
          <br/>
          <br/>
        </>)}

        <Box sx={{ maxWidth: '800px', mx: 'auto', border: 0, borderRadius: 2, borderColor: 'grey.400', p: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              name="inspectionDate"
              label="Scheduled Inspection Date"
              value={this.state.inspectionDate}
              onChange={(date) => this.setState({inspectionDate: date})}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          &nbsp;
          <Button variant="contained" size="large" style={{ textAlign: 'center'}}
            disabled={!this.state.inspectionDate || this.state.inspectionDate === this.state.initialInspectionDate || this.state.submitting}
            onClick={() => this.SetAppraisalDate('InspectionDate', this.state.inspectionDate, 'initialInspectionDate')}>
            {this.state.initialInspectionDate ? 'Change' : 'Submit'}
          </Button>
        </Box>
        <Box sx={{ maxWidth: '800px', mx: 'auto', border: 0, borderRadius: 2, borderColor: 'grey.400', p: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              name="estReportDate"
              label="Est. Report Delivery Date"
              value={this.state.estReportDate}
              onChange={(date) => this.setState({estReportDate: date})}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          &nbsp;
          <Button variant="contained" size="large" style={{ textAlign: 'center'}}
            disabled={!this.state.estReportDate || this.state.estReportDate === this.state.initialEstReportDate || this.state.submitting}
            onClick={() => this.SetAppraisalDate('EstDeliveryDate', this.state.estReportDate, 'initialEstReportDate')}>
            {this.state.initialEstReportDate ? 'Change' : 'Submit'}
          </Button>
        </Box>
        <br/>
        {this.state.initialEstReportDate && (
          <Box sx={{ maxWidth: '800px', mx: 'auto', border: 0, borderRadius: 2, borderColor: 'grey.400', p: 2 }}>
            <Button variant="contained" size="large" style={{ textAlign: 'center'}}
              disabled={this.state.submitting}
              onClick={() => this.props.history.push('/upload-files/' + this.props.match.params.id)}>
              Upload Report Files
            </Button>
          </Box>
        )}

        {this.state.statusMsg && (
          <Alert severity={this.state.msgType} onClose={() => this.setState({statusMsg: ''}) } >{this.state.statusMsg}</Alert>
        )}
      </Container>
    )
  }
}
