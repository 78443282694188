import React, { Component } from 'react'
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import LoginState from './LoginState';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { deepOrange, amber, lightGreen } from '@mui/material/colors';
import CustomLoader from './CustomLoader';

export default class ViewFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      comments: [],
      pageReady: false,
      dataFound: false,
      showComments: false,
      msgType: 'info',
      uploadedFiles: [],
      retrievedFile: '',
      isUploading: false
    };

    if(!this.props.match.params.id || !LoginState.loggedIn) {
      this.props.history.push("/");
      return;
    }
    this.getUploadData(this.props.match.params.id);
  }

  async getUploadData(orderID) {
    axios.get('/getfiles', {
      params: { pOrderID: orderID }
    }).then(res => {
      if(res && res.data) {
        var order = res.data;
        this.setState({
          orderID: this.props.match.params.id,
          address: order.address,
          status: order.status,
          orderDate: order.orderDate,
          uploadedFiles: order.files,
          comments: order.comments,
          showComments: order.comments.length > 0,
          pageReady: true,
          dataFound: true,
        });
      }
    }).catch(this.setState({ pageReady: true, dataFound: false }));
  }

  async downloadFile(fileName) {
    var response = axios.get('/getfiledata', { params: { pOrderID: this.props.match.params.id, pFileName: fileName } });
    var resultData = null;
    try {
      resultData = (await response).data;
      this.setState({ retrievedFile: resultData });
    } 
    catch {
      this.setState({ retrievedFile: '' });
    }

    var dlFN = fileName;
    if(fileName[fileName.length-1] === ')') {
      dlFN = "";
      var splitFN = fileName.split(' ');
      for(var i=0; i<splitFN.length-1; i++) {
        dlFN = dlFN + splitFN[i];
      }
    }

    var binary = atob(resultData);
    var array = [];
    for (var j=0; j<binary.length; j++) {
      array.push(binary.charCodeAt(j));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = dlFN;
    a.click();
    URL.revokeObjectURL(url);
  }

  fileDataToUrl(uintarr) {
    var binary = atob(uintarr);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)]);
    const url = URL.createObjectURL(file);
    return url;
  }

  async updateStatus(statusText) {
    axios.get('/changestatus', {
      params: { 
        pOrderID: this.props.match.params.id, 
        pStatus: statusText
      }
    }).then(res => {
      if(res && res.data) {
        this.setState({ successMsg: "Status Changed to '" + statusText + "'", status: statusText });
      }
    }).catch(this.setState({ pageReady: true, dataFound: false }));
  }

  checkEnter = e => { if (e.charCode === 13) { this.submitComment(); } }
  handleInput = e => { this.setState({ [e.target.name]: e.target.value }); }

  async submitComment() {
    this.setState({ successMsg: 'Uploading Comment! Please Wait', isUploading: true });
    axios.get('/submitComment', {
      params: { 
        pOrderID: this.props.match.params.id, 
        pUserName: LoginState.userName,
        pComment: this.state.newComment
      }
    }).then(res => {
      if(res && res.data) {
        // refresh data to show new comment added
        this.state.comments.push(res.data);
        this.setState({ newComment: '', successMsg: '', isUploading: false})
      }
    }).catch(this.setState({ successMsg: '', isUploading: false }));
  }

  render() {
    if(!this.state.pageReady || !this.state.orderID) return <CustomLoader>View Report Files</CustomLoader>

    var color = lightGreen[500];
    if(this.state.status) {
      if(this.state.status === 'Assigned')
        color = amber[500];
      if(this.state.status === 'Creating') 
        color = deepOrange[500];
    }

    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', my:2, textAlign: 'center' }}>View Report Files</Typography>
        <Box sx={{ maxWidth: '800px', mx: 'auto', border: 1, borderRadius: 2, borderColor: 'grey.400', p: 2 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">Order ID#:</Typography>
              <Typography variant="body2">{this.state.orderID}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Order Date:</Typography>
              <Typography variant="body2">{this.state.orderDate}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Order Status:</Typography>
              <Typography variant="body2" style={{ color }}>{this.state.status}</Typography>
            </Grid>
            <br/><br/><br/>
            <Grid item xs={12}>
              <Typography variant="body1">Property Address:</Typography>
              <Typography variant="body2">{this.state.address}</Typography>
            </Grid>
          </Grid>
        </Box>
        <br/>

        {this.state.uploadedFiles.length > 0 && (
          <Box sx={{ maxWidth: '800px', mx: 'auto', border: 0, p: 2 }}>
            <hr/>
            {this.state.uploadedFiles.map((file) => (
              <div key={file.name}>
                <ArrowRightIcon style={{position: 'relative', top:'6px'}} />
                &nbsp;
                {file.name}
                &nbsp;
                <Link style={{cursor:'pointer'}} onClick={() => this.downloadFile(file.name)} color="#bf1a0b">[Download]</Link>
              </div>
            ))}
            <hr/>
          </Box>
        )}

        {this.state.showComments && (
          <Box sx={{ maxWidth: '800px', mx: 'auto', border: 1, borderRadius: 2, borderColor: 'grey.400', p: 2 }}>
              <Table sx={{ width: '650px' }} aria-label="comments table">
                <TableBody>
                    {this.state.comments.map((comment) => (
                      <TableRow key={comment.timeOfDay} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                          <span>[{comment.timestamp}]</span>
                        </TableCell>
                        <TableCell width="75px">{comment.userName}</TableCell>
                        <TableCell width="500px">{comment.commentText}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <TextField
                            variant="outlined"
                            name='newComment'
                            label='Add Comment'
                            value={this.state.newComment}
                            onKeyPress={this.checkEnter}
                            onChange={this.handleInput}
                        />
                        &nbsp;
                        <Button size="large" variant="contained" disabled={this.state.isUploading}
                          onClick={() => this.submitComment()}>Add</Button>
                        &nbsp;
                        <Button size="large" variant="contained" disabled={this.state.isUploading}
                          onClick={() => this.setState({ newComment: '' })}>Clear</Button>
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
          </Box>
        )}

        {this.state.successMsg && (
          <Alert severity="success">{this.state.successMsg}</Alert>
        )}

        {/* Mark "Complete" or Leave Feedback */}
        {(LoginState.userRole === 'Admin' && this.state.status === 'AMC Review') && (<>
          <Box sx={{ maxWidth: '800px', mx: 'auto', border: 0, p: 2, textAlign: 'center' }}>
            {!this.state.showComments && (<>
              <Tooltip title="Write a Note / Comment to the Appraiser" arrow>
                <Button size="large" variant="contained"
                  onClick={() => this.setState({showComments: true})}>Add Message</Button>
              </Tooltip>
              &nbsp;
            </>)}
            <Tooltip title="Set Order Staus to 'Completed'" arrow>
              <Button size="large" variant="contained"
                onClick={() => this.updateStatus("Completed")}>Mark Done</Button>
            </Tooltip>
          </Box>
        </>)}
      </Container>
    )
  }
}
