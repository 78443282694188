import React, { Component } from "react"
import axios from "axios";
import Container from '@mui/material/Container';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CustomLoader from "./CustomLoader";
import Link from '@mui/material/Link';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { base64ArrayBuffer } from '../helpers/utils';

const MAX_FILE_SIZE = 25000000;

export default class Register extends Component {
  state = {
    pageReady: false,
    requestSent: false,
    appData: [],
    submitting: false,
    uploadedFiles: [],
    fileArr: [],
    retrievedFile: '',
    password1: '',
    password2: '',
  };

  componentDidMount() {
    this.getAppraiserData();
    this.getUploadData(this.props.match.params.id);
  }

  async getUploadData(user) {
    axios.get('/getAppraiserRegistrationFiles', {
      params: { pAppraiser: user }
    }).then(res => {
      if(res && res.data) {
        this.setState({
          uploadedFiles: res.data.files,
          pageReady: true,
          accessAllowed: true,
          dataFound: true
        });
      }
    }).catch(this.setState({ pageReady: true, dataFound: false }));
  }

  async getAppraiserData() {
    const response = axios.get("../getappraiserdata", { 
      params: { pAppraiser: this.props.match.params.id } 
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if(resultData) {
      this.setState({ appData: resultData });
    }
  }

  captureInput = e => {
    this.setState(prevState => ({[e.target.name]: e.target.value }));
  };

  combineFiles(file1, file2) {
    return file1 + '$$$$$' + file2;
  }

  async uploadToServer() {
    // skip if no files uploaded
    if (this.state.fileArr.length === 0) return;

    // check to see that combined file size is not over limit
    var totalSize = 0;
    for (var i = 0; i < this.state.fileArr.length; i++) {
      totalSize += this.state.fileArr[i].size;
    }
    if(totalSize >= MAX_FILE_SIZE) {
      this.setState({ errorMsg: 'Combined File Size Exceeds 25Mb!' });
      return;
    }

    this.setState({
      isUploading: true,
      errorMsg: '',
      statusMsg: 'Uploading Files - Please Wait!',
    });
    
    // first combine all the files into one.
    var combinedFiles = null;
    for (var j = 0; j < this.state.fileArr.length; j++) {
      if (j === 0) combinedFiles = this.state.fileArr[j].fileUIntArr;
      else
        combinedFiles = this.combineFiles(
          combinedFiles,
          this.state.fileArr[j].fileUIntArr
        );
    }
    // also combine the file names
    var combinedFileNames = null;
    for (var k = 0; k < this.state.fileArr.length; k++) {
      if (k === 0) combinedFileNames = this.state.fileArr[k].fileName;
      else
        combinedFileNames = this.combineFiles(
          combinedFileNames,
          this.state.fileArr[k].fileName
        );
    }

    // then submit to server
    const params = {
      appraiser: this.props.match.params.id,
      fileNames: combinedFileNames,
      fileContent: combinedFiles,
      password: this.state.password1
    };

    fetch('/appraiserFileUpload', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.text()) 
    .then(data => {
      // error check
      if (!data.startsWith('Success')) {
        // show error message
        this.setState({
          isUploading: false,
          statusMsg: '',
          errorMsg: 'Error Uploading File(s)!',
        });
      } else {
        // notify for successful upload
        this.setState({
          isUploading: false,
          statusMsg: 'Files Successfully Uploaded!',
        });
      }
    })
  }

  async downloadFile(fileName) {
    var response = axios.get('/getfiledata', { params: { pOrderID: this.props.match.params.id, pFileName: fileName } });
    var resultData = null;
    try {
      resultData = (await response).data;
      this.setState({ retrievedFile: resultData });
    } 
    catch {
      this.setState({ retrievedFile: '' });
    }

    var dlFN = fileName;
    if(fileName[fileName.length-1] === ')') {
      dlFN = "";
      var splitFN = fileName.split(' ');
      for(var i=0; i<splitFN.length-1; i++) {
        dlFN = dlFN + splitFN[i];
      }
    }

    var binary = atob(resultData);
    var array = [];
    for (var j=0; j<binary.length; j++) {
      array.push(binary.charCodeAt(j));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = dlFN;
    a.click();
    URL.revokeObjectURL(url);
  }

  fileDataToUrl(uintarr) {
    var binary = atob(uintarr);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)]);
    const url = URL.createObjectURL(file);
    return url;
  }

  InputContent() {
    if (this.state.isSubmitting === true) {
      return <div className="UploadFile">Loading File(s)...</div>;
    } else {
      return (
        <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>
          <CloudUploadIcon color="disabled" sx={{ fontSize: 40 }} /><br/>
          <b>Drag &amp; Drop</b> or <u>browse.</u>
        </Typography>
      );
    }
  }

  handleChangeStatus({ file, remove }, status) {
    if(file && file.size > MAX_FILE_SIZE) {
      this.setState({errorMessage: 'File Size Cannot Exceed 25 Mb!'});
    }
    if (status === 'preparing') {
      this.setState({ isSubmitting: true });
    } else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;
      reader.readAsArrayBuffer(file);
      reader.onload = e => {
        fileToUpload = e.target.result;
      };
      reader.onloadend = () => {
        var uintarr = base64ArrayBuffer(fileToUpload);
        this.state.fileArr.push({
          fileUIntArr: uintarr,
          fileName: file.name,
          conditionID: this.state.conditionID,
          size: file.size,
        });
        remove();
        setTimeout(() => {
          this.setState({ isSubmitting: false });
        }, 2000);
      };
    }
  }

  deleteFile(fileName) {
    var array = this.state.fileArr;
    for(var i=0; i<array.length; i++) {
      if(array[i].fileName === fileName) {
        array.splice(i, 1);
        this.setState({fileArr: array});
        break;
      }
    }
  }

  render() {
    const { 
      appData,
      isUploading,
      pageReady,
      fileArr,
      password1,
      password2,
      errorMsg,
      statusMsg
    } = this.state;
    if(!appData || !pageReady) {
      return <CustomLoader>New Appraiser Registration</CustomLoader>
    }
    return (
      <Container>
        <Paper sx={{ mt: 4, p: 2, width: '60ch', mx: 'auto', textAlign: "center" }}>
          <Typography variant="h5" mb={2}>New Appraiser Registration</Typography>

          {(appData.passwordSet && appData.passwordSet !== "True") && (
            <Stack>
              <TextField
                sx={{ mb: 1 }}
                label="Set Login Password (min 8 characters)"
                size="small"
                type="password"
                name="password1"
                value={password1}
                onChange={this.captureInput}
                InputProps={{ 
                  startAdornment: <InputAdornment position="start"></InputAdornment>,
                  endAdornment: <InputAdornment position="end"></InputAdornment>
                }}
              />
              <TextField
                sx={{ mb: 3 }}
                label="Confirm Login Password"
                size="small"
                type="password"
                name="password2"
                value={password2}
                onChange={this.captureInput}
                InputProps={{ 
                  startAdornment: <InputAdornment position="start"></InputAdornment>,
                  endAdornment: <InputAdornment position="end"></InputAdornment>
                }}
              />
            </Stack>
          )}
          {this.state.uploadedFiles.length > 0 && (
            <Box sx={{ maxWidth: '500px', mx: 'auto', border: 0, p: 1, textAlign: 'left' }}>
              <hr/>
              {this.state.uploadedFiles.map((file) => (
                <div key={file.name}>
                  <ArrowRightIcon style={{position: 'relative', top:'6px'}} />
                  &nbsp;
                  {file.name}
                  &nbsp;
                  <Link style={{cursor:'pointer'}} onClick={() => this.downloadFile(file.name)} color="#bf1a0b">[Download]</Link>
                </div>
              ))}
              <hr/>
            </Box>
          )}

          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', textAlign: 'center' }}>Please Upload Appraiser Credentials</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'medium', textAlign: 'center' }}>(The System Will Only Accept Up to 25 Mb of Files at a Time)</Typography>
          <br />
          <Dropzone
            maxFiles="10"
            maxSizeBytes="25000000" // 25 Mb
            accept="*"
            onChangeStatus={this.handleChangeStatus.bind(this)}
            // LayoutComponent={Layout}
            inputContent={this.InputContent.bind(this)}
            styles={{
              dropzone: {
                overflow: 'hidden',
                border: '2px dashed lightgray',
                width: '500px',
                height: '200px',
                borderRadius: '0.25rem',
              },
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (_, extra) =>
                extra.reject ? { color: 'red' } : {},
            }}
          />
          <Box sx={{ maxWidth: '500px', mx: 'auto', border: 0, p: 2, textAlign: 'left' }}>
            <div>
              {this.state.fileArr.map(({ fileName }) => (
                <div key={fileName}>
                  <ArrowRightIcon style={{ position: 'relative', top: '7px' }} />
                  &nbsp;
                  {fileName}
                  &nbsp;
                  <Link style={{cursor:'pointer'}} onClick={() => this.deleteFile(fileName)} color="#bf1a0b">[Delete]</Link>
                </div>
              ))}
            </div>
          </Box>

          <Button
            sx={{ mb: 1 }}
            fullWidth
            variant="contained"
            disabled={(appData.passwordSet === "False" && (password1.length < 8 || password2.length < 8 || password1 !== password2)) 
              || fileArr.length === 0 || isUploading}
            onClick={() => this.uploadToServer()}
          >Submit</Button>

          {appData.comments && (
            <Box sx={{ mx: 'auto', textAlign: 'center', mt: 2 }}>
              <Typography variant="h5" color="primary" sx={{ fontWeight: 'medium', mb: 2, mt: 4 }}>Additional Documents Requested</Typography>
              <hr/>
              <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 'medium', mb: 2 }}>{appData.comments}</Typography>
            </Box>
          )}

          {errorMsg && (
            <Alert severity="error" onClose={() => this.setState({errorMsg: ''}) } >{errorMsg}</Alert>
          )}
          {statusMsg && (
            <Alert severity="info" onClose={() => this.setState({statusMsg: ''}) } >{statusMsg}</Alert>
          )}
        </Paper>
      </Container>
    )}
}
