import React, { Component } from 'react'
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoginState from './LoginState';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { base64ArrayBuffer } from '../helpers/utils';
import { deepOrange, amber, lightGreen } from '@mui/material/colors';
// import styled from 'styled-components';
import CustomLoader from './CustomLoader';

// const FilesContainer = styled.div`
//   display: flex;
//   align-items: flex-start;
//   margin-bottom: 2rem;
//   flex-direction: column;
// `;

// const DZInputContainer = styled.div`
//   .dzu-inputLabelWithFiles {
//     display: none;
//   }
// `;

// const Layout = ({ input, dropzoneProps }) => (
//   <div>
//     <div {...dropzoneProps}>{input}</div>
//   </div>
// );

const MAX_FILE_SIZE = 25000000;

export default class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      pageReady: false,
      dataFound: false,
      accessAllowed: false,
      msgType: 'info',
      submitting: false,
      uploadedFiles: [],
      fileArr: [],
      retrievedFile: ''
    };

    if(!this.props.match.params.id || !LoginState.loggedIn) {
      this.props.history.push("/");
      return;
    }
    this.getUploadData(this.props.match.params.id);
  }

  async getUploadData(orderID) {
    axios.get('/getfiles', {
      params: { pOrderID: orderID }
    }).then(res => {
      if(res && res.data) {
        var order = res.data;
        if(order.assignee !== LoginState.userName) {
          this.setState({ order: order, pageReady: true, accessAllowed: false });
          return;
        }

        this.setState({
          orderID: this.props.match.params.id,
          address: order.address,
          status: order.status,
          orderDate: order.orderDate,
          uploadedFiles: order.fileNames,
          pageReady: true,
          accessAllowed: true,
          dataFound: true
        });
      }
    }).catch(this.setState({ pageReady: true, dataFound: false }));
  }

  combineFiles(file1, file2) {
    return file1 + '$$$$$' + file2;
  }

  async uploadToServer() {
    // skip if no files uploaded
    if (this.state.fileArr.length === 0) return;

    // check to see that combined file size is not over limit
    var totalSize = 0;
    for (var i = 0; i < this.state.fileArr.length; i++) {
      totalSize += this.state.fileArr[i].size;
    }
    if(totalSize >= MAX_FILE_SIZE) {
      this.setState({
        msgType: 'error',
        statusMsg: 'Combined File Size Exceeds 25Mb!'
      });
      return;
    }

    this.setState({
      isUploading: true,
      msgType: 'success',
      statusMsg: 'Uploading Files - Please Wait!',
    });

    // first combine all the files into one.
    var combinedFiles = null;
    for (var j = 0; j < this.state.fileArr.length; j++) {
      if (j === 0) combinedFiles = this.state.fileArr[j].fileUIntArr;
      else
        combinedFiles = this.combineFiles(
          combinedFiles,
          this.state.fileArr[j].fileUIntArr
        );
    }
    // also combine the file names
    var combinedFileNames = null;
    for (var k = 0; k < this.state.fileArr.length; k++) {
      if (k === 0) combinedFileNames = this.state.fileArr[k].fileName;
      else
        combinedFileNames = this.combineFiles(
          combinedFileNames,
          this.state.fileArr[k].fileName
        );
    }

    // then submit to server
    const params = {
      orderID: this.props.match.params.id,
      fileNames: combinedFileNames,
      fileContent: combinedFiles,
    };

    fetch('/fileupload', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.text())
    .then(data => {
      // error check
      if (!data.startsWith('Success')) {
        // show error message
        this.setState({
          isUploading: false,
          msgType: 'error',
          statusMsg: 'Error Uploading File(s)!',
        });
      } else {
        // notify for successful upload
        this.setState({
          isUploading: false,
          fileArr: [],
          msgType: 'success',
          statusMsg: 'File(s) Successfully Uploaded!',
        });
        this.getUploadData(this.props.match.params.id);
      }
    })
  }

  async downloadFile(fileName) {
    var response = axios.get('/getfiledata', { params: { pOrderID: this.props.match.params.id, pFileName: fileName } });
    var resultData = null;
    try {
      resultData = (await response).data;
      this.setState({ retrievedFile: resultData });
    }
    catch {
      this.setState({ retrievedFile: '' });
    }

    var dlFN = fileName;
    if(fileName[fileName.length-1] === ')') {
      dlFN = "";
      var splitFN = fileName.split(' ');
      for(var i=0; i<splitFN.length-1; i++) {
        dlFN = dlFN + splitFN[i];
      }
    }

    var binary = atob(resultData);
    var array = [];
    for (var j=0; j<binary.length; j++) {
      array.push(binary.charCodeAt(j));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = dlFN;
    a.click();
    URL.revokeObjectURL(url);
  }

  fileDataToUrl(uintarr) {
    var binary = atob(uintarr);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)]);
    const url = URL.createObjectURL(file);
    return url;
  }

  InputContent() {
    if (this.state.isSubmitting === true) {
      return <div className="UploadFile">Loading File(s)...</div>;
    } else {
      return (
        <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>
          <CloudUploadIcon color="disabled" sx={{ fontSize: 40 }} />
          <b>Drag &amp; Drop</b> or <u>browse.</u>
        </Typography>
      );
    }
  }

  handleChangeStatus({ file, remove }, status) {
    if(file && file.size > MAX_FILE_SIZE) {
      this.setState({errorMessage: 'File Size Cannot Exceed 25 Mb!'});
    }
    if (status === 'preparing') {
      this.setState({ isSubmitting: true });
    } else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;
      reader.readAsArrayBuffer(file);
      reader.onload = e => {
        fileToUpload = e.target.result;
      };
      reader.onloadend = () => {
        var uintarr = base64ArrayBuffer(fileToUpload);
        this.state.fileArr.push({
          fileUIntArr: uintarr,
          fileName: file.name,
          conditionID: this.state.conditionID,
          size: file.size,
        });
        remove();
        setTimeout(() => {
          this.setState({ isSubmitting: false });
        }, 2000);
      };
    }
  }

  deleteFile(fileName) {
    var array = this.state.fileArr;
    for(var i=0; i<array.length; i++) {
      if(array[i].fileName === fileName) {
        array.splice(i, 1);
        this.setState({fileArr: array});
        break;
      }
    }
  }

  render() {
    if(!this.state.pageReady || !this.state.orderID) return <CustomLoader>Upload Report Files</CustomLoader>
    if(!this.state.accessAllowed) {
      return (
        <Container>
          <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Upload Report Files</Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>
            Restricted Access To Order #: {this.state.orderID}
            <br/>
            (Appraisers Can Only Access Orders Assigned to Them)
            <br/><br/>
            <Button variant="contained" size="large" style={{ textAlign: 'center'}}
              onClick={() => this.props.history.push("/")}>To Dashboard</Button>
          </Typography>
        </Container>
      );
    }

    var color = lightGreen[500];
    if(this.state.status) {
      if(this.state.status === 'Assigned')
        color = amber[500];
      if(this.state.status === 'Creating')
        color = deepOrange[500];
    }

    return (
      <Container>
        <Typography variant="h5" sx={{ fontWeight: 'medium', my:2, textAlign: 'center' }}>Upload Report Files</Typography>
        <Box sx={{ maxWidth: '800px', mx: 'auto', border: 1, borderRadius: 2, borderColor: 'grey.400', p: 2 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">Order ID#:</Typography>
              <Typography variant="body2">{this.state.orderID}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Order Date:</Typography>
              <Typography variant="body2">{this.state.orderDate}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Order Status:</Typography>
              <Typography variant="body2" style={{ color }}>{this.state.status}</Typography>
            </Grid>
            <br/><br/><br/>
            <Grid item xs={12}>
              <Typography variant="body1">Property Address:</Typography>
              <Typography variant="body2">{this.state.address}</Typography>
            </Grid>
          </Grid>
        </Box>
        <br/>

        {this.state.uploadedFiles.length > 0 && (
          <Box sx={{ maxWidth: '800px', mx: 'auto', border: 0, p: 2 }}>
            <hr/>
            {this.state.uploadedFiles.map((fileName) => (
              <div key={fileName}>
                <ArrowRightIcon style={{position: 'relative', top:'6px'}} />
                &nbsp;
                {fileName}
                &nbsp;
                <Link style={{cursor:'pointer'}} onClick={() => this.downloadFile(fileName)} color="#bf1a0b">[Download]</Link>
              </div>
            ))}
            <hr/>
          </Box>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: 'medium', textAlign: 'center' }}>Drag and Drop Files Or Click Below To Upload</Typography>
        <Typography variant="subtitle2" sx={{ fontWeight: 'medium', textAlign: 'center' }}>(The System Will Only Accept Up to 25 Mb of Files at a Time)</Typography>
        <br />
        <Dropzone
          maxFiles="10"
          maxSizeBytes="25000000" // 25 Mb
          accept=".pdf,.xml"
          onChangeStatus={this.handleChangeStatus.bind(this)}
          // LayoutComponent={Layout}
          inputContent={this.InputContent.bind(this)}
          styles={{
            dropzone: {
              overflow: 'hidden',
              border: '2px dashed lightgray',
              width: '800px',
              height: '200px',
              borderRadius: '0.25rem',
            },
            dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
            inputLabel: (_, extra) =>
              extra.reject ? { color: 'red' } : {},
          }}
        />
        <br />
        <Box sx={{ maxWidth: '800px', mx: 'auto', border: 0, p: 2 }}>
          <div>
            {this.state.fileArr.map(({ fileName }) => (
              <div key={fileName}>
                <ArrowRightIcon style={{marginTop:'-2px'}} />
                &nbsp;
                {fileName}
                &nbsp;
                <Link style={{cursor:'pointer'}} onClick={() => this.deleteFile(fileName)} color="#bf1a0b">[Delete]</Link>
              </div>
            ))}
          </div>
        </Box>

        <Box sx={{ maxWidth: '800px', mx: 'auto', border: 0, p: 2, textAlign: 'center' }}>
          <Button size="large" variant="contained" disabled={this.state.fileArr.length === 0 || this.state.isUploading}
            onClick={() => this.uploadToServer()}>Upload</Button>
          &nbsp;
          <Button size="large" variant="contained"
            onClick={() => this.props.history.push('/order-update/' + this.props.match.params.id)}>Cancel</Button>
        </Box>

        {this.state.statusMsg && (
          <Alert severity={this.state.msgType} onClose={() => this.setState({statusMsg: ''}) } >{this.state.statusMsg}</Alert>
        )}
      </Container>
    )
  }
}
